import { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ArrowRightOutlined } from '@ant-design/icons'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { getFormattedDuration } from '@Helpers/DateTime'
import { User } from '@Hooks/Types'
import useSponsor from '@Hooks/useSponsor'
import useSponsorStatistics, { SponsorMeeting } from '@Hooks/useSponsorStatistics/useSponsorStatistics'
import useVisitors from '@Hooks/useVisitors'
import Card from '@Reusable/Card/Card'
import LinkButton from '@Reusable/LinkButton'
import { Avatar, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import { includes } from 'ramda' // check out ramda's includes

import MeetingStatus from './MeetingStatus'

export type Attendee = User &{
  id: string,
  firstName: string,
  lastName: string,
  imageUrl: string,
  companyTitle: string,
  companyName: string,
  durationSeconds: number
}

const columns = (
  sponsorMeetings: SponsorMeeting[],
  isFetchingMeetings: boolean
) => [
  {
    key: 'avatar',
    dataIndex: 'attendee',
    width: 80,
    render: (attendee: Attendee) => (
      <Avatar
        src={attendee.imageUrl}
        size={48}
        shape={'square'}
        style={{ borderRadius: 10 }}
      >
        {attendee.firstName?.slice(0, 1)}
      </Avatar>
    )
  },
  {
    title: 'Name',
    dataIndex: ['attendee'],
    key: 'name',
    sorter: (a: {attendee: Attendee}, b: {attendee: Attendee}) => a.attendee?.firstName?.localeCompare(b.attendee?.firstName),
    ellipsis: true,
    render: (attendee: Attendee) => (
      <div data-test={'name'}>
        {attendee.firstName}
        {' '}
        {attendee.lastName}
      </div>
    )
  },
  {
    title: 'Title',
    dataIndex: ['attendee', 'companyTitle'],
    key: 'title',
    sorter: (a: {attendee: Attendee}, b: {attendee: Attendee}) => a.attendee?.companyTitle?.localeCompare(b.attendee?.companyTitle),
    ellipsis: true
  },
  {
    title: 'Company',
    dataIndex: ['attendee', 'companyName'],
    key: 'company',
    sorter: (a: {attendee: Attendee}, b: {attendee: Attendee}) => a.attendee?.companyName?.localeCompare(b.attendee?.companyName),
    ellipsis: true
  },
  {
    title: 'Total time spent',
    dataIndex: ['attendee', 'durationSeconds'],
    key: 'total_time_spent',
    sorter: (a: {attendee: Attendee}, b: {attendee: Attendee}) => a?.attendee?.durationSeconds - b?.attendee?.durationSeconds,
    ellipsis: true,
    render: (durationSeconds: string) => {
      return <span>{getFormattedDuration(durationSeconds)}</span>
    }
  },
  {
    title: 'Meetings with representatives',
    dataIndex: ['attendee'],
    width: '30%',
    key: 'status',
    sorter: (a: {attendee: Attendee}, b: {attendee: Attendee}) =>
      sponsorMeetings.filter((meeting: SponsorMeeting) => meeting.attendeeId === a.attendee.id)?.length -
      sponsorMeetings.filter((meeting: SponsorMeeting) => meeting.attendeeId === b.attendee.id)?.length,
    render: (attendee: Attendee) => (
      <MeetingStatus meetings={sponsorMeetings.filter((meeting) => meeting.attendeeId === attendee.id)} loading={isFetchingMeetings}/>
    )
  }
]

const ActiveVisitors = () => {
  const [pageSize, setPageSize] = useState(5)

  const { sponsorMeetings, isFetching: isFetchingMeetings } = useSponsorStatistics()
  const { sponsor } = useSponsor()
  const usesAnalytics = sponsor?.usesAnalytics ?? false

  const handleChange = (pagination: TablePaginationConfig) => {
    setPageSize(pagination?.pageSize || 5)
  }
  const match = useRouteMatch()
  const history = useHistory()

  const _redirectToTrafficAnalytics = () => {
    const { url } = match
    history.push(url.replace('overview', 'traffic-analytics'))
  }

  const { visitors, isFetching: isFetchingVisitors } = useVisitors({
    page: { number: 1, size: 1000 }
  })

  const { representativesIdsList, isFetching: isFetchingSponsorStatistics } = useSponsorStatistics()

  const filteredVisitors =
  (visitors.filter((i: {attendee: {id: string, firstName: string}}) => i.attendee.firstName && !includes(i.attendee.id, representativesIdsList)))
    .map((visitor: {id: string}) => { return ({ ...visitor, key: visitor.id }) }).slice(0, 20)

  const TooltipNode = (
    <span>
      {'Find potential prospects through the list of people who engage and spend time in your booth the most, excluding representatives. '}
      <br />
      <a href={'https://help.brella.io/en/sponsors/visitors-activity'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
    </span>
  )

  const isFetching = isFetchingVisitors || isFetchingSponsorStatistics
  if (!usesAnalytics) return null

  return (
    <Card
      title={'Most active visitors'} TooltipNode={TooltipNode} testId={'most-active-visitors'}
      loading={isFetching}
    >
      <StyledTableWrapper data-test={usesAnalytics ? 'data-access-enabled' : 'data-access-disabled'}>
        <Table
          data-test={'most-active-visitors-table'}
          dataSource={filteredVisitors}
          onChange={handleChange}
          pagination={{
            total: filteredVisitors.length,
            pageSize,
            showSizeChanger: filteredVisitors.length > 5,
            pageSizeOptions: ['5', '10', '20']
          }}
        >
          {columns(sponsorMeetings, isFetchingMeetings).map((column) => {
            return (
              <Table.Column
                key={column.key}
                dataIndex={column.dataIndex}
                width={column.width}
                render={column.render}
                title={column.title}
                sorter={column.sorter}
                ellipsis={column.ellipsis}
              />
            )
          })}
        </Table>
      </StyledTableWrapper>
      {visitors.length > 0 &&
      <StyledLinkButton
        data-test={'view-all-visitors'}
        onClick={_redirectToTrafficAnalytics}
      >
        {'View all visitors'}
        {' '}
        <ArrowRightOutlined/>
      </StyledLinkButton>
      }
    </Card>
  )
}

export default ActiveVisitors

const StyledTableWrapper = styled('div')({
}, (props) => ({
  '.ant-table-column-title': {
    color: props.theme.colors.secondaryText
  },
  '.ant-table-pagination.ant-pagination': {
    margin: '32px 0 8px'
  }
}))

const slideLeft = keyframes`
  0% {
    transform: translate3d(0,0, 0);
  }
  40% {
    transform: translate3d(22px,0, 0);
  }
  100% {
    transform: translate3d(12px ,0, 0);
  }
`

const StyledLinkButton = styled(LinkButton)({
  position: 'absolute',
  bottom: 39,
  display: 'flex',
  alignItems: 'center',
  '>span': {
    marginTop: 2,
    marginLeft: 12
  },
  '&:hover': {
    '>span': {
      marginLeft: 16,
      animation: `${slideLeft} 500ms ease-in-out forwards`,
      animationFillMode: 'forwards'
    }
  }
})
