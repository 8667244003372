import styled from '@emotion/styled'

type StyledColumnWrapperProps = {
  margin?: string
}
export const StyledColumnWrapper = styled('div')<StyledColumnWrapperProps>({
}, (props) => ({
  margin: props.margin || '24px 0',
  textAlign: 'center'
}))

export const StyledLogoImg = styled('img')({
  width: 24,
  height: 24
})

export const StyledOverFlow = styled('div')({
}, (props) => ({
  overflow: 'auto',
  display: 'flex',
  whiteSpace: 'nowrap',
  paddingBottom: 10,
  '>a': {
    color: `${props.theme.colors.text} !important`,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))
