import styled from '@emotion/styled'
import { Checkbox, Input } from 'antd'

import { useGlobalContext } from '../../../GlobalContext/GlobalContext'
import { FilterEventsAction, FilterEventsActionType } from '../Types'

interface TableHeaderProps {
  filterEvents: (action: FilterEventsAction) => void,
  disabled: boolean
}

const TableHeader = ({ filterEvents, disabled }: TableHeaderProps) => {
  const { setPastChecked } = useGlobalContext()
  const _onSearch = (value: string) => {
    filterEvents({ type: FilterEventsActionType.SEARCH, payload: value })
  }

  const _onShowPastChange = (e: { target: { checked: boolean } }) => {
    filterEvents({ type: FilterEventsActionType.SHOW_PAST, payload: e.target.checked })
    setPastChecked(e.target.checked)
  }

  const _onSearchBlur = (e: { target: { value: string } }) => {
    if (e.target.value === '') {
      _onSearch('')
    }
  }
  return (
    <TableHeaderContainer>
      <FilterRow>
        <SearchInput
          allowClear
          enterButton
          placeholder={'Filter by Event or Company'}
          onSearch={_onSearch}
          onChange={(e) => _onSearch(e.target.value)}
          onBlur={_onSearchBlur}
          className={'br-events-list-table-search'}
          data-test={'events-list-search-input'}
        />
        <ToolGroup>
          <ShowPastCheckbox
            onChange={_onShowPastChange}
            defaultChecked={true}
            data-test={'show-past-checkbox'}
            disabled={disabled}
          >
            {'Show past events'}
          </ShowPastCheckbox>
        </ToolGroup>
      </FilterRow>
    </TableHeaderContainer>
  )
}

export default TableHeader

const TableHeaderContainer = styled('div')({ })

const FilterRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
})

const SearchInput = styled(Input.Search)({
  maxWidth: 400,
  '@media screen and (max-width: 1200px)': {
    width: '100%',
    maxWidth: 'none',
    order: 2
  }
})

const ToolGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '> *': {
    marginLeft: 10
  }
})

const ShowPastCheckbox = styled(Checkbox)({
  position: 'relative',
  '@media screen and (max-width: 1200px)': {
    marginBottom: 24
  }
})
