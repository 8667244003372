export const legend = {
  left: 5,
  textStyle: {
    fontSize: 14,
    fontWeight: 400
  }
}

export const tooltip = {
  trigger: 'item',
  textStyle: {
    fontSize: 14
  }
}

export const grid = {
  top: 10,
  left: 10,
  right: 20,
  bottom: 30,
  containLabel: true
}

export function secondsToHms(d: number) {
  const h = Math.floor(d / 3600)
  const m = Math.floor(d % 3600 / 60)
  const s = Math.floor(d % 3600 % 60)

  const hDisplay = h > 0 ? h + 'h ' : ''
  const mDisplay = m > 0 ? m + 'm ' : ''
  const sDisplay = s > 0 ? s + 's' : ''
  return hDisplay + mDisplay + sDisplay
}
