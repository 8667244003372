import { Editor } from 'react-draft-wysiwyg'
import styled from '@emotion/styled'
import { useSponsorContext } from '@SponsorContext'

import { parseContentFromBackend } from '../../helpers/Sponsors'

const CompanyDetails = () => {
  const { sponsorState } = useSponsorContext()
  const content = sponsorState?.content

  return (
    <CompanyDetailsContainer>
      <Editor
        readOnly={true}
        editorState={parseContentFromBackend(content)}
        toolbarHidden={true}
      />
    </CompanyDetailsContainer>
  )
}

export default CompanyDetails

const CompanyDetailsContainer = styled('div')({
  maxHeight: 800,
  overflow: 'auto'
})
