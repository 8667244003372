import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { sponsorAttendeesQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'
import { message, Spin } from 'antd'
import { EventParamsTypes } from 'src/Types'

interface AddRepButtonProps {
  attendees: number[],
  refetchSponsorStatistics: () => void
}

const AddRepButton = ({ attendees, refetchSponsorStatistics }: AddRepButtonProps) => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const [post, { isLoading, status: postStatus }] = entitySauce.usePost(
    sponsorAttendeesQuery(eventId, sponsorId, attendees)
  )

  const success = () => {
    message.success(`Representative${attendees.length > 1 ? 's' : ''} was added successfully.`)
  }

  const error = () => {
    message.error(`We couldn't add representative${attendees.length > 1 ? 's' : ''}. Please try again.`)
  }

  useEffect(() => {
    if (postStatus === 'success') {
      success()
      refetchSponsorStatistics()
    }
    if (postStatus === 'error') {
      error()
    }
  }, [postStatus])

  return (
    <AddRepButtonContainer onClick={post}>
      {isLoading ? <Spin size={'small'}/> : 'Add'}
    </AddRepButtonContainer>
  )
}

export default AddRepButton

const AddRepButtonContainer = styled('div')({
  '.ant-spin-dot-item': {
    background: '#fff'
  }
})
