import { useEffect } from 'react'
import { sponsorMediaQuery } from '@EntitySauce/Queries'
import { DeckMedum } from '@Hooks/Types'
import { useMediaContext } from '@MediaContext'
import CustomMessage from '@Reusable/CustomMessage'
import { useSponsorContext } from '@SponsorContext'
import { entitySauce } from '@State'
import { Button, Popconfirm, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import snakecaseKeys from 'snakecase-keys'

import ModalForm, { bodyType } from './ModalForm'

export enum ModalStatus {
  NEW = 'new',
  EDIT = 'edit'
}

export enum ModalTypes {
  CAROUSEL_SLIDE = 'carousel-slide',
  SIDE_BAR_ITEM = 'side-bar-item'
}

interface MediaModalProps {
  isModalVisible?: boolean,
  setIsModalVisible: (isModalVisible: boolean) => void,
  type: ModalTypes,
  modalStatus: ModalStatus,
  refetchSponsor: () => void
}

const MediaModal = ({
  isModalVisible,
  setIsModalVisible,
  type,
  modalStatus,
  refetchSponsor
}: MediaModalProps) => {
  const { mediaState, setMediaState } = useMediaContext()
  const { setUpdatingCarouselDeck } = useSponsorContext()
  const { setPatchItemId } = useMediaContext()
  const mediaDeckItem = mediaState?.mediaDeckItem

  const [patch, { isLoading: patchLoading, status: patchStatus }] = entitySauce.usePatch(
    sponsorMediaQuery()
  )

  const [post, { isLoading: postLoading, status: postStatus }] = entitySauce.usePost(
    sponsorMediaQuery()
  )

  useEffect(() => {
    if (!patchLoading && patchStatus === 'success') {
      handleCancel()
      refetchSponsor()
    }
  })

  useEffect(() => {
    if (mediaDeckItem?.id) {
      setPatchItemId(mediaDeckItem?.id)
    }
  }, [mediaDeckItem?.id])

  const handleOk = async() => {
    if (!mediaDeckItem) { return }
    const payload = {
      mediableId: mediaDeckItem?.id,
      ...mediaDeckItem,
      mediableType: 'sponsor',
      mediableSecondaryType: type === ModalTypes.SIDE_BAR_ITEM ? 'tabs' : 'deck'
    }
    if (type === ModalTypes.CAROUSEL_SLIDE) {
      setUpdatingCarouselDeck(true)
    }
    if (mediaDeckItem.bodyType === bodyType.IMAGE) {
      payload.image = payload.url
    }
    const media = snakecaseKeys(payload) as DeckMedum
    switch (modalStatus) {
      case ModalStatus.NEW:
        await post({ data: { media } })
        await refetchSponsor()
        handleCancel()
        setUpdatingCarouselDeck(false)
        break
      case ModalStatus.EDIT:
        if (!media?.image?.startsWith('data:image')) {
          delete media.image
        }
        await patch({ mediaId: mediaDeckItem.id, data: { media } })
        setUpdatingCarouselDeck(false)
        break
      default:
        break
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setMediaState({})
  }

  const fieldsTouched = (mediaState?.fieldsTouched ?? false)

  const loading = patchLoading || postLoading

  const createButtonDisabled = (!mediaDeckItem?.url || !mediaDeckItem?.title || mediaDeckItem?.title?.length > 50) || loading

  const isModalClosable =
  (modalStatus === ModalStatus.NEW && !mediaDeckItem?.url && !mediaDeckItem?.title) ||
  (modalStatus === ModalStatus.EDIT && !fieldsTouched)

  const _renderCancelButton = (
    isModalClosable
      ? (
        <Button key={'cancel'} onClick={ handleCancel} data-test={'media-modal-cancel-button'}>
          {'Cancel'}
        </Button>
      )
      : (
        <Popconfirm
          key={'cancel'}
          title={'Are you sure you want to discard changes'}
          onConfirm={handleCancel}
        >
          <Button data-test={'media-modal-cancel-button'}>
            {'Cancel'}
          </Button>
        </Popconfirm>
      )
  )

  const _renderSubmitButton = (
    <Button
      data-test={`media-modal-${modalStatus === ModalStatus.EDIT ? 'edit' : 'create'}-button`}
      key={'submit'}
      type={'primary'}
      loading={false}
      onClick={handleOk}
      disabled={createButtonDisabled}
    >
      {loading ? <Spin/> : modalStatus === ModalStatus.EDIT ? 'Edit' : 'Create'}
    </Button>
  )

  const _customMessages = [
    {
      id: 1,
      status: postStatus,
      errorMessage: `We couldn't create a new ${type.replace(/-/g, ' ')}. Please try again.`,
      successMessage: `A new ${type.replace(/-/g, ' ')} was created successfully`
    },
    {
      id: 2,
      status: patchStatus
    }
  ]

  return (
    <Modal
      title={`${modalStatus === ModalStatus.EDIT ? 'Edit' : 'Create a new'} ${type.replace(/-/g, ' ')}`}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={isModalClosable ? handleCancel : () => { return null } }
      centered={true}
      data-test={'modal'}
      closable={isModalClosable}
      footer={[
        _renderCancelButton,
        _renderSubmitButton
      ]}
    >
      {_customMessages.map((msg) => {
        return (
          <CustomMessage
            key={msg.id}
            status={msg.status}
            errorMessage={msg.errorMessage}
            successMessage={msg.successMessage}
          />
        )
      })}
      <ModalForm
        type={type}
      />
    </Modal>
  )
}

export default MediaModal
