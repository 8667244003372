import React from 'react'
import styled from '@emotion/styled'
import BrellaLogoWithText from '@SVG/brella-logo.svg'

interface LogoProps {
  onClick: () => void
}
const Logo = ({ onClick }: LogoProps) => {
  return (
    <LogoContainer onClick={onClick}>
      <img src={BrellaLogoWithText} alt={'Brella'} />
    </LogoContainer>
  )
}

export default Logo

const LogoContainer = styled.div({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  '>img': {
    height: 16,
    cursor: 'pointer'
  }
})
