/* eslint-disable no-multi-spaces */

const BASE_MARGIN = 16
const SCROLLBAR_WIDTH = 12

const fontFamily = '"IBM Plex Sans", sans-serif'

/**
 * Name that Color http://chir.ag/projects/name-that-color/#6195ED
 */
const palette = {
  codGray: '#1A1A1A',
  gray: '#8C8C8C',
  silver: '#C6C6C6',
  silverChalice: '#B2B2B2',
  concrete: '#F3F3F3',
  jade: '#00AF66',
  eucalyptus: '#27A85E',
  redViolet: '#BB16A3',
  offGreen: '#EEFBF3',
  tara: '#E5F7EF',
  roman: '#D75858',
  guardsmanRed: '#D60000',
  wePeep: '#FBEEEE',
  tacha: '#D7D458',
  white: '#FFFFFF',
  jellyBean: '#2785A8',
  catskillWhite: '#E9F2F6',
  bianca: '#FBFAEE',
  buttercup: '#F5A623',
  amour: '#FBEEFB',
  tutu: '#FFFAFF',
  alabaster: '#FAFAFA',
  gallery: '#F0F0F0',
  black: '#000000',
  rollingStone: '#727374',
  blackSqueeze: '#F5FBFA',
  lightJade: '#AFE4CE',
  lightJade2: '#F9FBF9',
  iron: '#DCDDDE',
  supernova: '#FFCC00',
  raisinBlack: '#262626',
  gainsboro: '#DFDFDF',
  gray2: '#595959',
  gray3: '#F5F5F5',
  blueGray: '#F3F5F7',
  blueGray3: '#F9FAFB',
  blueGray4: '#E5E8EB',
  blueGray9: '#384149',
  dangerRed: '#F5222D',
  dangerRedBackground: '#FFF1F0',
  infoYellow: '#FA8C16',
  infoYellowBackground: '#FFF7E6',
  sunsetOrange: '#D46B08',
  greenDew: '#E9F7EC'
}

const colors = {
  primary: palette.jade, // #00AF66
  primaryLight: palette.lightJade, // #AFE4CE
  primaryLight2: palette.lightJade2, // #F9FBF9
  secondary: palette.redViolet, // #D40DAF
  secondaryLight: palette.tutu,
  text: palette.codGray, // #1A1A1A
  secondaryText: palette.gray, // #8C8C8C
  lightText: palette.silverChalice,
  border: palette.silver, // #C6C6C6
  primaryBackground: palette.white, // #FFFFFF
  secondaryBackground: palette.concrete, // #F3F3F3
  chatBackground: palette.white,
  offering: palette.jade, // #27A85E
  offeringLight: palette.tara,
  seeking: palette.redViolet, // #D40DAF
  seekingLight: palette.amour,
  interest: palette.redViolet,
  interestLight: palette.amour,
  danger: palette.roman,
  successForeground: palette.jade, // #00AF66
  successBackground: palette.offGreen, // #EEFBF3
  errorForeground: palette.roman, // #D75858
  errorBackground: palette.wePeep, // #FBEEEE
  warningForeground: palette.tacha, // #D7D458
  warningBackground: palette.bianca, // #FBFAEE
  infoForeground: palette.jellyBean, // #2785A8
  infoBackground: palette.catskillWhite, // #E9F2F6
  request: palette.silver,
  accepted: palette.jade,
  cancelled: palette.roman,
  rejected: palette.roman,
  pending: palette.buttercup,
  waiting: palette.buttercup,
  rescheduled: palette.buttercup,
  rescheduledWaiting: palette.buttercup,
  badge: palette.buttercup,
  footerBackground: palette.white,
  lightBorder: palette.gallery,
  lightBorder2: palette.gainsboro,
  link: palette.jade,
  bookmark: palette.buttercup,
  homeProfileBackground: palette.alabaster,
  title: palette.black,
  muted: palette.rollingStone,
  description: palette.black,
  bannerBackground: palette.blackSqueeze,
  notification: palette.guardsmanRed,
  bookmarkBorder: palette.iron,
  bookmarkFill: palette.supernova,
  sponsorBlack: palette.raisinBlack,
  lightGrayText: palette.gray2,
  brellaPurple: palette.redViolet,
  lightGrayBackground: palette.alabaster,
  previewPlaceHolderBackground: palette.blueGray,
  categoryTagBackground: palette.blueGray4,
  strongSubtitle: palette.blueGray9,
  previewBackground: palette.alabaster,
  dangerRed: palette.dangerRed,
  dangerRedBackground: palette.dangerRedBackground,
  infoYellow: palette.infoYellow,
  infoYellowBackground: palette.infoYellowBackground,
  infoOrange: palette.sunsetOrange,
  dewBackground: palette.greenDew,
  disabledBackground: palette.gray3
}

const transitions = {
  basic: {
    transition: '.15s cubic-bezier(0.33, 0.66, 0.66, 1)'
  },
  hover: {
    transition: 'transform 0.12s ease-in-out',
    ':hover': {
      transform: 'scale(1.1)'
    }
  }
}

const radius = {
  circle: '100%',
  full: '999px',
  large: '10px',
  medium: '4px',
  small: '2px',
  tiny: '1px'
}

const text = {
  h1: {
    fontSize: 24,
    fontWeight: 500,
    color: colors.text,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    lineHeight: 1.2,
    fontFamily,
    letterSpacing: '-0.5px'
  },
  h2: {
    fontSize: 22,
    fontWeight: 500,
    color: colors.text,
    lineHeight: 1.2,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily,
    letterSpacing: '-0.5px'
  },
  h3: {
    fontSize: 18,
    fontWeight: 500,
    color: colors.text,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  h4: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '18px',
    color: colors.text,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  body: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.text,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  caps: {
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  tinyCaps: {
    fontSize: 9,
    fontWeight: 600,
    letterSpacing: 0.22,
    color: colors.text,
    textTransform: 'uppercase',
    fontFamily
  },
  titleCaps: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.text,
    letterSpacing: 0.7,
    textTransform: 'uppercase',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  tiny: {
    fontSize: 12,
    fontWeight: 400,
    color: colors.text,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  listHeader: {
    fontSize: 12,
    color: colors.secondaryText,
    fontFamily
  },
  button: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.primaryBackground,
    textAlign: 'center',
    fontFamily
  },
  buttonSecondary: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.secondaryText,
    textAlign: 'center',
    fontFamily
  },
  link: {
    color: colors.primary
  },
  monospace: {
    fontFamily: 'monospace',
    fontSize: 13
  },
  shortLarge: {
    fontSize: '16px',
    fontWeight: 400,
    color: colors.title,
    lineHeight: '24px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  longLarge: {
    fontSize: '16px',
    fontWeight: 400,
    color: colors.title,
    lineHeight: '22px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  shortMedium: {
    fontSize: '14px',
    fontWeight: 400,
    color: colors.title,
    lineHeight: '22px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  longMedium: {
    fontSize: '14px',
    fontWeight: 400,
    color: colors.title,
    lineHeight: '21px',
    letterSpacing: `${11.5 / 1000}em`,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  muted: {
    fontSize: '12px',
    fontWeight: 400,
    color: colors.muted,
    lineHeight: '16px',
    letterSpacing: `${27 / 1000}em`,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  highlighted: {
    fontSize: '12px',
    fontWeight: 500,
    color: colors.secondary,
    lineHeight: '16px',
    letterSpacing: `${27 / 1000}em`,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  description: {
    fontSize: '11.5px',
    fontWeight: 400,
    color: colors.description,
    lineHeight: '21px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  },
  cta: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: `${11.5 / 1000}em`,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily,
    cursor: 'pointer'
  },
  smallTitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: colors.secondary,
    lineHeight: '16.5px',
    letterSpacing: '0.12px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily
  }
}

const scrollbar = {
  '::-webkit-scrollbar': { width: `${SCROLLBAR_WIDTH}px` },
  '::-webkit-scrollbar-thumb': {
    borderRadius: 6,
    border: '4px solid rgba(0, 0, 0, 0)', // Transparent border works as a padding
    backgroundClip: 'padding-box',
    backgroundColor: colors.border,
    ':hover': {
      backgroundColor: colors.secondaryText
    }
  }
}

/**
 * Screen size break points (matches Ant Desing break points)
 */
const breakPoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}

/**
 * Styles should be defined first for the small screen size (576px),
 * then extending them for larger screens if need be, e.g.
 *
 * const Component = styled('div')({
 *   margin: 8, // Default margin
 *   [theme.media.md]: { margin: 16 // Margin for md, lg screens }
 *   [theme.media.xl]: { margin: 32 // Margin for xl+ screens }
 * })
 */
const media = {
  xs: `@media only screen and (max-width : ${breakPoints.xs}px)`,
  sm: `@media only screen and (min-width : ${breakPoints.sm}px)`,
  md: `@media only screen and (min-width : ${breakPoints.md}px)`,
  lg: `@media only screen and (min-width : ${breakPoints.lg}px)`,
  xl: `@media only screen and (min-width : ${breakPoints.xl}px)`,
  xxl: `@media only screen and (min-width : ${breakPoints.xxl}px)`
}

const brellaGradient = {
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%),
                    linear-gradient(to right, rgba(83, 253, 181, 0.5) 0%, rgba(209, 246, 255, 0.5) 50%, rgba(227, 80, 237, 0.5) 100%)`
}

const makeBrellaGradient = (primary: string, secondary: string): { backgroundImage: string } => {
  return {
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%),
                      linear-gradient(to right, ${primary} 0%, ${secondary} 100%)`
  }
}

const margins = {
  halfBaseMargin: BASE_MARGIN / 2,
  baseMargin: BASE_MARGIN,
  doubleBaseMargin: BASE_MARGIN * 2,
  largeMargin: 32,
  scrollbar: SCROLLBAR_WIDTH
}

const spacing = {
  vertical: { none: 0, xxs: 2, xs: 4, s: 8, m: 12, l: 16, xl: 24, xxl: 32 },
  horizontal: { none: 0, xxs: 2, xs: 4, s: 8, m: 12, l: 16, xl: 24, xxl: 32 }
}

const theme = {
  colors,
  radius,
  text,
  scrollbar,
  breakPoints,
  media,
  brellaGradient,
  makeBrellaGradient,
  margins,
  spacing,
  transitions,
  fontFamily
}
export type themeType  = typeof theme

export {
  breakPoints,
  brellaGradient,
  colors,
  fontFamily,
  makeBrellaGradient,
  margins,
  media,
  radius,
  scrollbar,
  spacing,
  text,
  transitions
}

export default theme
