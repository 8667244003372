export const sponsorAttendeesQuery = (eventId: number, sponsorId: number, ids?: number[]) => [
  'sponsorAttendees', {
    eventId,
    sponsorId,
    data: { id: ids }
  }
]

export const removeSponsorAttendeesQuery = (eventId: number, sponsorId: number, id: number) => [
  'sponsorAttendees', {
    eventId,
    sponsorId,
    attendeeId: id
  }
]

export const sponsorQuery = (eventId: string, sponsorId: string) => [
  'sponsor', {
    eventId,
    sponsorId
  }
]

export const sponsorMeetingsQuery = (eventId: number, sponsorId: number) => [
  'sponsorMeetings', {
    eventId,
    sponsorId
  }
]

export const sponsorMediaQuery = (mediaId?: number | string) => [
  'sponsorMedia', {
    mediaId
  }
]

export const visitorsQuery = (eventId: number, sponsorId: number, queryParams?: {page: { number: number, size: number} }) => [
  'visitor', {
    eventId,
    sponsorId,
    queryParams
  }
]

export const sponsorVisitorStatisticsQuery = (eventId: number, sponsorId: number) => [
  'sponsorVisitorStatistics', {
    eventId,
    sponsorId
  }
]

export const sponsorVisitorStatisticsDay = (eventId: number, sponsorId: number, queryParams: {startDate: string, endDate: string}) => [
  'sponsorVisitorStatisticsDay', {
    eventId,
    sponsorId,
    queryParams: {
      start_date: queryParams.startDate,
      end_date: queryParams.endDate
    }
  }
]

export const visitorsExportQuery = (eventId: number, sponsorId: number) => [
  'visitorsExport', {
    eventId,
    sponsorId
  }
]

export const eventSummaryExportQuery = (eventIds: number[]) => [
  'eventSummaryExport', {
    queryParams: {
      ids: eventIds
    }
  }
]

export const eventAttendeesQuery = (eventId: number, queryParams?: {
  page: { number: number, size: number},
  search: string
}) => [
  'eventAttendees', {
    eventId,
    queryParams
  }
]

export const sponsorBoothsQuery = () => [
  'sponsorBooth'
]

export const eventStatisticsQuery = (eventId: number) => [
  'eventStatistics', {
    eventId
  }
]

export const sponsorStatisticsQuery = (eventId: number, sponsorId: number) => [
  'sponsorStatistics', {
    eventId,
    sponsorId
  }
]

export const userQuery = () => [
  'meUser'
]

export const sponsorAdminsQuery = (token: string | null) => [
  'sponsorAdmins', {
    token
  }
]

export const leadQuery = (eventId: number, sponsorId: number, queryParams?: {page: {size: number, number: number}, 'scanned_by': 'others' | 'self'}) => [
  'lead', {
    eventId,
    sponsorId,
    queryParams
  }
]

// eslint-disable-next-line camelcase
export const productsQuery = (eventId: number, sponsorId: number, queryParams?: {product_ids: (string | number | undefined)[]}) => [
  'products', {
    eventId,
    sponsorId,
    queryParams
  }
]

export const sponsorToggleUsesMeetAtBoothQuery = (eventId: number, sponsorId: number) => [
  'sponsorToggleUsesMeetAtBooth', {
    eventId,
    sponsorId
  }
]
