import useSponsorStatistics from '@Hooks/useSponsorStatistics/useSponsorStatistics'
import Card from '@Reusable/Card/Card'
import { StyledFlex } from '@Reusable/Styled'
import { Empty } from 'antd'

import ChartInfo from '../Reusable/ChartInfo'
import DonutChart from '../Reusable/DonutChart'

const renderData = (
  sponsorStatistics: {
    chatMessagesCount: number,
    openChatRequestsCount: number,
    openChatRequestsAnsweredCount: number,
    openChatRequestsConvertedCount: number
  }
) => {
  const {
    chatMessagesCount = 0,
    openChatRequestsCount = 0,
    openChatRequestsAnsweredCount = 0,
    openChatRequestsConvertedCount = 0
  } = sponsorStatistics || {}
  return (
    [
      { key: 1, label: 'Total chat messages', value: chatMessagesCount },
      { key: 2, label: 'Open chat request', value: openChatRequestsCount },
      { key: 3, label: 'Answered', value: openChatRequestsAnsweredCount, nested: 1 },
      { key: 4, label: 'Converted to meetings', value: openChatRequestsConvertedCount, nested: 2 },
      { key: 5, label: 'Pending', value: openChatRequestsCount - openChatRequestsAnsweredCount, nested: 1 }
    ]
  )
}

const TooltipNode = (
  <span>
    {'See total inbound and outbound chat current status. '}
    <a href={'https://help.brella.io/en/sponsors/meeting-requests-chat-activity'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
  </span>
)

const ChatActivity = () => {
  const { sponsorStatistics, isFetching } = useSponsorStatistics()

  const {
    receivedChatMessagesCount,
    requestedChatMessagesCount,
    openChatRequestsCount,
    openChatRequestsAnsweredCount,
    openChatRequestsConvertedCount
  } = sponsorStatistics
  const chatMessagesCount: number = receivedChatMessagesCount + requestedChatMessagesCount

  return (
    <Card
      title={'Chat activity'}
      TooltipNode={TooltipNode}
      testId={'chat-activity'}
      testTotal={`${chatMessagesCount}`}
      paddingTop={0}
      paddingBottom={chatMessagesCount ? 0 : ''}
      loading={isFetching}
      empty={!chatMessagesCount}
    >
      {!chatMessagesCount
        ? <Empty description={'Chat activity data is not available'}/>
        : <StyledFlex justifyContent={'space-between'}>
          <DonutChart
            name= {'Chat activity'}
            type={'messages'}
            data={[
              { value: requestedChatMessagesCount, name: 'Outbound' },
              { value: receivedChatMessagesCount, name: 'Inbound' }
            ]}
            colors={[
              '#00AF66',
              '#BB16A3'
            ]}
            width= {'50%'}
          />
          <ChartInfo
            testId={'chat-activity'}
            data={renderData({
              chatMessagesCount,
              openChatRequestsCount,
              openChatRequestsAnsweredCount,
              openChatRequestsConvertedCount
            })}
            width={'45%'}
          />
        </StyledFlex>
      }
    </Card>
  )
}

export default ChatActivity
