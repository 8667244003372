import { useEffect, useState } from 'react'
import useEventAttendees from '@Hooks/useEventAttendees'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import useSponsor from '@Hooks/useSponsor'
import useSponsorDownloads from '@Hooks/useSponsorDownloads'
import useSponsorProducts from '@Hooks/useSponsorProducts'
import Spinner from '@Reusable/Spinner'
import { SponsorContext } from '@SponsorContext'
import { convertToRaw } from 'draft-js'

import { SponsorTabKeyType } from '../Types'

import MediaComponents from './Modules/Media/MediaComponents'
import SponsorNavRoutes from './SponsorNav/SponsorNavRoutes'
import SponsorNav from './SponsorNav'
import { UpdateSponsorAction, updateSponsorActionTypes } from './types'
import { getTabLabel } from './util'

const Sponsor = () => {
  const { sponsor } = useSponsor()
  const { attendees } = useEventAttendees()
  const { selectedEvent: event } = useSelectedEvent()
  const usesAnalytics = sponsor?.usesAnalytics ?? false
  const usesLeadScanning = (event?.usesLeadScanning && sponsor?.leadScanningEnabled) ?? false

  const [virtualBoothFieldsTouched, setVirtualBoothFieldsTouched] = useState<boolean>(false)
  const [updatingCarouselDeck, setUpdatingCarouselDeck] = useState<boolean>(false)
  const [sponsorState, setSponsorState] = useState(sponsor)
  const [eventAttendees, setEventAttendees] = useState<unknown>([])
  const { products } = useSponsorProducts()
  const { downloads } = useSponsorDownloads()
  const { productsTabDisabled } = sponsorState || {}

  useEffect(function detectSponsorStateUpdate() {
    if (sponsor) {
      setSponsorState({
        ...sponsor,
        boothTabs: sponsor?.boothTabs?.map(
          (tab) =>
            ({
              ...tab,
              value: tab.title,
              id: tab.key,
              name: tab.key,
              label: getTabLabel(tab.key),
              limit: 40,
              disabled: (
                (tab.key === SponsorTabKeyType.PRODUCTS && (!!productsTabDisabled || !products.length)) ||
                (tab.key === SponsorTabKeyType.DOWNLOADS && !downloads.length)
              )
            })
        )
      })
    }
  }, [sponsor?.content, productsTabDisabled, products.length, sponsor?.usesChat, sponsor?.usesMeetAtBooth, downloads.length])

  useEffect(() => {
    setEventAttendees(attendees)
  }, [])

  const updateSponsorState = (field: string, action: UpdateSponsorAction) => {
    switch (action.type) {
      case updateSponsorActionTypes.SET_CONTENT:
        setSponsorState({
          ...sponsorState,
          content: convertToRaw(action.payload.getCurrentContent())
        })
        break

      default:
        setSponsorState({
          ...sponsorState,
          [field]: action.payload
        })
        break
    }
  }

  const [sponsorTabsSortTouched, setSponsorTabsSortTouched] = useState(false)

  const SponsorContextProviderValue = {
    virtualBoothFieldsTouched,
    setVirtualBoothFieldsTouched,
    sponsorState,
    setSponsorState,
    updateSponsorState: updateSponsorState,
    eventAttendees,
    setEventAttendees,
    updatingCarouselDeck,
    setUpdatingCarouselDeck,
    sponsorTabsSortTouched,
    setSponsorTabsSortTouched
  }

  if (!sponsorState) return <Spinner />
  return (
    <SponsorContext.Provider value={SponsorContextProviderValue}>
      <SponsorNav usesAnalytics={usesAnalytics} usesLeadScanning={usesLeadScanning}/>
      <SponsorNavRoutes usesAnalytics={usesAnalytics} usesLeadScanning={usesLeadScanning}/>
      <MediaComponents />
    </SponsorContext.Provider>
  )
}

export default Sponsor
