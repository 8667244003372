import { useState } from 'react'
import { queryCache } from 'react-query'
import { TermsOfUsePolicy } from '@Common/Policies/TermsOfUsePolicy'
import styled from '@emotion/styled'
import useUser from '@Hooks/useUser'
import { entitySauce } from '@State'
import BrellaLogoWithText from '@SVG/brella-logo.svg'
import theme from '@Theme'
import { Button, Modal, Switch } from 'antd'

import LogOutButton from '../Header/Components/LogOutButton'

const TermsOfUse = () => {
  const { user, refetch: refetchUser } = useUser()
  const visible = user && !user?.sponsorTermsAcceptedAt
  const [accepted, setAccepted] = useState(false)
  const invalidateEventsCache = () => queryCache.invalidateQueries('sponsorBooth')
  const [post, { isLoading: acceptingTerms }] = entitySauce.usePost(
    ['acceptSponsorTerms'],
    {
      onSuccess: () => {
        refetchUser()
        invalidateEventsCache()
      }
    }
  )

  const _onCheckboxChange = (checked: boolean) => {
    setAccepted(checked)
  }

  const _onCheckboxToggle = () => {
    setAccepted((accepted) => !accepted)
  }

  const _handleAcceptClick = () => {
    post()
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
    >
      <Container>
        <BrellaLargeLogo src={BrellaLogoWithText} />
        <h2>
          {'Terms of use'}
        </h2>
        <WallOfText>{TermsOfUsePolicy}</WallOfText>
        <Footer>
          <SwitchContainer>
            <Switch onChange={_onCheckboxChange} checked={accepted} />
            <SwitchText onClick={_onCheckboxToggle}>
              {'I confirm that I have read and accept the terms of use'}
            </SwitchText>
          </SwitchContainer>
          <ButtonContainer>
            <Button
              disabled={!accepted}
              size={'large'}
              type={'primary'}
              onClick={_handleAcceptClick}
              loading={acceptingTerms}
            >
              {'Continue'}
            </Button>
            <StyledLogOutButtonWrapper>
              <LogOutButton noIcon red/>
            </StyledLogOutButtonWrapper>

          </ButtonContainer>
        </Footer>
      </Container>
    </Modal>
  )
}

export default TermsOfUse

const Container = styled('div')({
  padding: theme.margins.baseMargin
})

const BrellaLargeLogo = styled('img')({
  height: 15,
  marginTop: '4px',
  marginBottom: '30px'
})

const Footer = styled('div')({
  alignItems: 'center',
  textAlign: 'center'
})

const SwitchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme.margins.doubleBaseMargin,
  marginBottom: theme.margins.doubleBaseMargin
})

const SwitchText = styled('span')({
  textAlign: 'left',
  paddingLeft: '6px',
  cursor: 'pointer',
  fontSize: '0.9em'
})

const WallOfText = styled('pre')({
  fontSize: '0.9em',
  height: '400px',
  whiteSpace: 'pre-wrap',
  padding: theme.margins.baseMargin,
  backgroundColor: theme.colors.secondaryBackground,
  borderRadius: theme.radius.medium
})

const StyledLogOutButtonWrapper = styled('div')({
  marginTop: theme.margins.baseMargin
})

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
})
