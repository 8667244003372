import { useEffect } from 'react'
import { message } from 'antd'

interface CustomMessageProps {
  status: string,
  successMessage?: string,
  errorMessage?: string
}

const CustomMessage = ({ status, successMessage, errorMessage }: CustomMessageProps) => {
  const success = () => {
    message.success(successMessage || 'Changes saved successfully.')
  }

  const error = () => {
    message.error(errorMessage || 'We couldn\'t save changes. Please try again.')
  }

  useEffect(() => {
    if (status === 'success') {
      success()
    }
    if (status === 'error') {
      error()
    }
  }, [status])
  return null
}

export default CustomMessage
