import { useParams } from 'react-router-dom'
import { sponsorQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'
import { prop, uniqBy } from 'ramda'

import { EventParamsTypes } from '../Types'

import { ResponseType, Sponsor } from './Types'

type useSponsorType = ResponseType & {
  sponsor: Sponsor & {
    productsTabDisabled: boolean
  }
}

const useSponsor = (): useSponsorType => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { data, ...rest } = entitySauce.useGet(
    sponsorQuery(eventId, sponsorId),
    {
      staleTime: 50000,
      refetchOnWindowFocus: false,
      enabled: !!eventId && !!sponsorId
    }
  )

  const sponsor: Sponsor = data?.data?.[0]

  return {
    sponsor: sponsor?.tabs ? { ...sponsor, tabs: uniqBy(prop('id'), sponsor?.tabs ?? []), deckMedia: uniqBy(prop('id'), sponsor?.deckMedia ?? []) } : null,
    ...rest
  }
}

export default useSponsor
