import useSponsorMeetings from '@Hooks/useSponsorMeetings'
import useVisitorsStatistics from '@Hooks/useVisitorsStatistics'
import Card from '@Reusable/Card/Card'
import { Empty } from 'antd'

import HorizontalBarChart from './HorizontalBarChart'
const BAR_MAX_WIDTH = 80
const TooltipNode = (
  <span>
    {'Measure your booth visibility and success in the event through the number of visits and meetings. '}
    <a href={'https://help.brella.io/en/sponsors/overall-performance-booths-activity'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
  </span>
)

const OverAllPerformance = () => {
  const { visitorsStatistics, isFetching: isFetchingVisitorsStats } = useVisitorsStatistics()
  const { sponsorMeetings, totalMeetings, isFetching: isFetchingSponsorMeetings } = useSponsorMeetings()
  const acceptedMeetings = sponsorMeetings.filter((meeting: { currentStatus: { state: string } }) => meeting.currentStatus.state === 'accepted').length

  const {
    totalBoothVisits
  } = visitorsStatistics

  const series = [
    {
      name: 'Booth visits',
      type: 'bar',
      barMaxWidth: BAR_MAX_WIDTH,
      data: [totalBoothVisits]
    }
  ]

  if (totalMeetings) {
    series.push(
      {
        name: 'Total meeting requests',
        type: 'bar',
        barMaxWidth: BAR_MAX_WIDTH,
        data: [totalMeetings]
      }
    )
  }

  if (acceptedMeetings) {
    series.push(
      {
        name: 'Total accepted meeting requests',
        type: 'bar',
        barMaxWidth: BAR_MAX_WIDTH,
        data: [acceptedMeetings]
      }
    )
  }

  const isFetching = isFetchingVisitorsStats || isFetchingSponsorMeetings
  return (
    <Card
      title={'Overall performance'} TooltipNode={TooltipNode} testId={'overall-performance'}
      paddingTop={0}
      loading={isFetching}
      empty={!totalBoothVisits}
    >
      {!totalBoothVisits
        ? <Empty description={'Overall performance data is not available'} data-test={'overall-performance-empty'}/>
        : <HorizontalBarChart
          name= {'Overall performance'}
          colors = {[
            '#71DC94',
            '#00AF66',
            '#006341'
          ]}
          xAxis={{
            splitLine: {
              show: false
            }
          }}
          yAxis={{
            show: false,
            data: []
          }}
          series={series}
        />
      }
    </Card>
  )
}

export default OverAllPerformance
