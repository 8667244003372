import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './ContentEditor.css'

import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import Translations from '@Constants/Translations'
import { uploadEventImage } from '@EntitySauce/Api'
import { useSponsorContext } from '@SponsorContext'
import { EditorState } from 'draft-js'

import { parseContentFromBackend } from '../../helpers/Sponsors'

const _toolbar = (uploadImageCallback: (file: Blob) => void) => {
  return {
    options: ['inline', 'blockType', 'list', 'link', 'emoji', 'image', 'remove', 'history'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough']
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1']
    },
    textAlign: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: []
    },
    link: {
      options: ['link'],
      popupClassName: 'br-content-editor-link-popup-left'
    },
    emoji: {
      popupClassName: 'br-content-editor-emoji-popup-left'
    },
    image: {
      popupClassName: 'br-content-editor-image-popup-left',
      alignmentEnabled: false,
      uploadEnabled: true,
      urlEnabled: false,
      previewImage: true,
      uploadCallback: uploadImageCallback
    }
  }
}

interface ContentEditorProps {
  disabled?: boolean,
  onChange?: (editorState: EditorState) => void,
  eventId: number
}

const ContentEditor = ({ disabled, onChange, eventId }: ContentEditorProps) => {
  const { sponsorState } = useSponsorContext()
  const content = sponsorState?.content
  const [editorState, setEditorState] = useState(parseContentFromBackend((content)) || EditorState.createEmpty())
  const _onEditorStateChange = (editorState: EditorState) => {
    if (editorState) { setEditorState(editorState) }
    _triggerChange(editorState)
  }

  const _triggerChange = (editorState: EditorState) => {
    if (onChange) {
      onChange(editorState)
    }
  }

  const _fallbackUpload = (eventId: number, image: string, onSuccess: (url: string) => void, onError: () => void) => {
    uploadEventImage(eventId, image)
      .then(({ ok, data }: {ok: boolean, data: {data: {attributes: {['image-url']: string}}}, error: string}) => {
        if (ok) {
          onSuccess(data?.data?.attributes?.['image-url'])
        } else {
          onError()
        }
      })
      .catch(() => {
        onError()
      })
  }

  const _uploadImageCallback = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader()

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const data = `data:image/png;base64,${window.btoa(event.target?.result?.toString() ?? '')}`

        const onSuccess = (url: string) => {
          resolve({ data: { link: url } })
        }

        const onError = () => {
          reject(new Error('Error uploading image'))
        }
        _fallbackUpload(eventId, data, onSuccess, onError)
      }

      reader.readAsBinaryString(file)
    })
  }

  return (
    <Editor
      readOnly={disabled}
      editorClassName={`br-content-editor-text-area${disabled ? '-disabled' : ''}`}
      toolbarClassName={'br-content-editor-toolbar'}
      editorState={editorState}
      onEditorStateChange={_onEditorStateChange}
      toolbar={_toolbar(_uploadImageCallback)}
      webDriverTestID={'content-editor'}
      toolbarHidden={disabled}
      localization={{
        locale: 'en',
        translations: Translations
      }}
    />
  )
}

export default ContentEditor
