// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ExtList: any = {
  video: [
    'avi',
    '3g2',
    '3gp',
    'aep',
    'asf',
    'flv',
    'm4v',
    'mkv',
    'mov',
    'mp4',
    'mpeg',
    'mpg',
    'ogv',
    'pr',
    'swfw',
    'webm',
    'wmv',
    'swf',
    'rm'
  ],
  image: ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tif', 'tiff'],
  zip: ['zip', 'zipx', '7zip', 'tar', 'sitx', 'gz', 'rar'],
  audio: [
    'aac',
    'aif',
    'aiff',
    'flac',
    'm4a',
    'mid',
    'mp3',
    'ogg',
    'wav'
  ],
  text: [
    'cue',
    'odt',
    'md',
    'rtf',
    'txt',
    'doc',
    'tex',
    'wpd',
    'wps',
    'xlr',
    'fodt'
  ],
  system: ['exe', 'ini', 'dll', 'plist', 'sys'],
  src: [
    'asp',
    'aspx',
    'c',
    'cpp',
    'cs',
    'css',
    'scss',
    'py',
    'json',
    'htm',
    'html',
    'java',
    'yml',
    'php',
    'js',
    'ts',
    'rb',
    'jsx',
    'tsx'
  ],
  vector: ['dwg', 'dxf', 'ps', 'svg', 'eps'],
  font: ['fnt', 'ttf', 'otf', 'fon', 'eot', 'woff'],
  object: ['3dm', '3ds', 'max', 'obj', 'pkg'],
  sheet: ['pdf', 'csv', 'fods', 'ods', 'xlr'],
  doc: ['doc', 'docx']
}

export default ExtList
