import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { Layout } from 'antd'

import IntercomChat from './Components/IntercomChat'
import UserDropdown from './Components/UserDropdown'
import Logo from './Logo'

const Header = () => {
  const history = useHistory()

  const _onLogoClick = () => {
    history.replace('/events')
  }

  return (
    <HeaderContainer>
      <Logo onClick={_onLogoClick}/>
      <UserDropdown />
      {!import.meta.env.DEV && (
        <IntercomChat />
      )}
    </HeaderContainer>
  )
}

export default Header

const HeaderContainer = styled(Layout.Header)({
}, (props) => ({
  borderBottom: `1px solid ${props.theme.colors.lightBorder2}`,
  backgroundColor: props.theme.colors.primaryBackground,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'fixed',
  zIndex: 2,
  gap: '24px'
}))
