import { useEffect, useReducer } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { MoreOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { BoothEvent, Sponsor } from '@Hooks/Types'
import Card from '@Reusable/Card/Card'
import { Empty, Table } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'

import { useGlobalContext } from '../../../GlobalContext/GlobalContext'
import { FilterEventsAction, FilterEventsActionType } from '../Types'

import { tableHeight } from './Styles'
import { renderCountryColumn, renderDateColumn, renderNameColumn, renderSponsorColumn } from './TableColumns'
import TableFooter from './TableFooter'
import TableHeader from './TableHeader'

interface EventsTableProps {
  events: BoothEvent[],
  loading: boolean
}

enum actionTypes {
  SET_VISIBLE_COLUMNS = 'setVisibleColumns',
  SET_SELECTED_EVENTS = 'selectedEvents',
  UPDATE_PAGINATION = 'updatePagination'
}

export type OnBulkAction = {e: MenuInfo, action: { title?: string, onClick: (arg0: { id: React.Key }[], arg1: () => void) => void }}

export type PaginationType = {
  current: number,
  pageSize: number | undefined
}

type State = {
  selectedEvents: React.Key[],
  visibleColumns: string[],
  pagination: PaginationType
}

const initialState: State = {
  selectedEvents: [],
  visibleColumns: ['country', 'attendees', 'engagement', 'meetingRequests', 'meetings'],
  pagination: { current: 1, pageSize: 20 }
}

type Action =
  | { type: actionTypes.SET_VISIBLE_COLUMNS, visibleColumns: string[] }
  | { type: actionTypes.SET_SELECTED_EVENTS, selectedEvents: React.Key[] }
  | { type: actionTypes.UPDATE_PAGINATION, pagination: PaginationType }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case actionTypes.SET_VISIBLE_COLUMNS:
      return {
        ...state,
        visibleColumns: action.visibleColumns
      }
    case actionTypes.SET_SELECTED_EVENTS:
      return {
        ...state,
        selectedEvents: action.selectedEvents
      }
    case actionTypes.UPDATE_PAGINATION:
      return {
        ...state,
        pagination: action.pagination
      }
    default:
      return { ...state }
  }
}

const EventsTable = ({ events, loading }: EventsTableProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()
  const match = useRouteMatch()

  const onRowClick = (record: Record<string, Sponsor>) => {
    history.push(`${match.url}/${record.id}/sponsor/${record.sponsor.id}`)
  }
  const _setPagination = (pagination: PaginationType) => {
    dispatch({
      type: actionTypes.UPDATE_PAGINATION,
      pagination: pagination
    })
  }

  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'startDate',
      width: '10%',
      render: renderDateColumn,
      renderCondition: false
    },
    {
      title: 'Event',
      dataIndex: 'name',
      width: '30%',
      render: renderNameColumn,
      renderCondition: false
    },
    {
      title: 'Company',
      dataIndex: 'sponsor',
      width: '25%',
      render: renderSponsorColumn,
      renderCondition: false
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: '15%',
      render: renderCountryColumn,
      renderCondition: true
    }
  ]

  const { setSearchTerm, filteredEvents, pastChecked, setFilteredEvents } = useGlobalContext()

  useEffect(() => {
    setFilteredEvents(events)
  }, [events.length])

  const _filterEvents = (action: FilterEventsAction) => {
    switch (action.type) {
      case FilterEventsActionType.SEARCH:
        setFilteredEvents(
          events
            .filter(
              (event: { name: string, sponsor: { name: string }, past: boolean }) =>
                (
                  (event.name?.toLocaleLowerCase()).includes(action.payload.toLocaleLowerCase()) ||
                (event.sponsor?.name?.toLocaleLowerCase()).includes(action.payload.toLocaleLowerCase())
                )
            ).filter((event: { past: boolean }) => pastChecked ? event : !event.past)
        )
        setSearchTerm(action.payload)
        break
      case FilterEventsActionType.SHOW_PAST:
        setFilteredEvents(events.filter((event: { past: boolean }) => action.payload ? event : !event.past))
        break
    }
  }

  return (
    <StyledContainer>
      <SponsorHeaderContainer>
        <TableHeader filterEvents={_filterEvents} disabled={!events.length} />
      </SponsorHeaderContainer>
      <Card testId={'events'} margin={'24px'}>
        {!events.length && !loading
          ? <Empty
            style={{ margin: '100px 0' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              'You don’t have edit rights, please contact the event organizer'
            }
          />
          : <EventListTable
            dataSource={filteredEvents}
            scroll={{ x: 0, y: tableHeight < 350 ? 'max-content' : tableHeight }}
            loading={loading}
            onRow={(record) => ({
              onClick: () => {
                onRowClick(record as Record<string, Sponsor>)
              }
            })}
            pagination = {
              state.pagination
            }
            data-test={'event-table'}
          >
            {tableColumns.map((column) => {
              if (!column.renderCondition || (state.visibleColumns.includes(column.dataIndex))) {
                return (
                  <Table.Column
                    title={column.title}
                    dataIndex={column.dataIndex}
                    key={column.dataIndex}
                    width={column.width}
                    render={column.render}
                  />
                )
              } else {
                return null
              }
            })}

          </EventListTable>}
        <TableFooter
          totalCount={filteredEvents.length}
          setPagination={_setPagination}
          showSizeChanger={filteredEvents.length > 10}
        />
      </Card>
    </StyledContainer>
  )
}

export default EventsTable

const StyledContainer = styled('div')({
  '.ant-table-pagination': {
    display: 'none'
  }
})
export const EventListTable = styled(Table)({
  cursor: 'pointer'
})

export const ColumnToolButton = styled(MoreOutlined)({
  padding: 10
})

const SponsorHeaderContainer = styled('div')({
}, (props) => ({
  padding: '24px 48px',
  backgroundColor: props.theme.colors.primaryBackground
}))
