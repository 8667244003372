/* eslint-disable @typescript-eslint/no-explicit-any */
import './RepresentativeModal.css'

import React, { useReducer, useState } from 'react'
import Scroll from 'react-scroll'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import styled from '@emotion/styled'
import useEventAttendees from '@Hooks/useEventAttendees'
import { StyledFlex } from '@Reusable/Styled'
import InfoSubTitle from '@SponsorCommon/InfoSubTitle'
import { Checkbox, Input, Modal, Pagination, Spin } from 'antd'
import { includes } from 'ramda'

import AddRepButton from './AddRepButton'

interface RepresentativeModalProps {
  visible: boolean,
  onCancel: () => void,
  sponsorAttendees: any[],
  refetchSponsorStatistics: () => void
}
type State = {
  search: string,
  pageSize: number,
  pageNumber: number
}

const initialState: State = {
  search: '',
  pageSize: 10,
  pageNumber: 1
}

enum actionTypes {
  SET_SEARCH = 'setSearch',
  SET_PAGE_SIZE = 'setPageSize',
  SET_PAGE_NUMBER = 'setPageNumber'
}

type Action =
  | { type: actionTypes.SET_SEARCH, payload: string }
  | { type: actionTypes.SET_PAGE_SIZE | actionTypes.SET_PAGE_NUMBER, payload: number }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionTypes.SET_SEARCH:
      return {
        ...state,
        search: action.payload
      }
    case actionTypes.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload
      }
    case actionTypes.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload
      }
    default:
      return { ...state }
  }
}

const RepresentativeModal = (props: RepresentativeModalProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { attendees, isFetching, meta } = useEventAttendees({
    page: { number: state.pageNumber, size: state.pageSize },
    search: state.search
  })
  const [selectedAttendees, setSelectedAttendees] = useState<any>([])

  React.useEffect(() => {
    Scroll.animateScroll.scrollToTop({
      duration: 100
    })
  }, [state.pageNumber, state.pageSize, state.search])

  const _onSearch = (search: string) => {
    _setPageNumber(1)
    _setPageSize(10)
    dispatch({
      type: actionTypes.SET_SEARCH,
      payload: search
    })
  }

  const _setPageNumber = (pageNumber: number) => {
    dispatch({
      type: actionTypes.SET_PAGE_NUMBER,
      payload: pageNumber
    })
  }

  const _setPageSize = (pageSize: number) => {
    dispatch({
      type: actionTypes.SET_PAGE_SIZE,
      payload: pageSize
    })
  }

  const _handleSearchChange = (e: { target: { value: string } }) => {
    if (!e.target.value) {
      _onSearch('')
    }
  }

  const _onCancel = () => {
    _clearAttendees()
    dispatch({
      type: actionTypes.SET_SEARCH,
      payload: ''
    })
    props.onCancel()
  }

  const _clearAttendees = () => {
    setSelectedAttendees([])
  }

  const {
    visible,
    sponsorAttendees
  } = props

  const _renderAttendee = (attendee: { id: React.Key | null | undefined, disabled: boolean | undefined, firstName: any, lastName: any, companyName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, companyTitle: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }) => {
    if (!attendee.id) { return }

    return (

      <Checkbox
        className={'checkbox-item'}
        key={attendee.id}
        value={attendee.id}
        disabled={includes(attendee.id, (sponsorAttendees || []).map((a: any) => a.id))}
        onChange={_selectAttendee}
      >
        <span className={'data'}>{`${attendee.firstName} ${attendee.lastName}`}</span>
        <span className={'data'}>{attendee.companyName}</span>
        <span className={'data'}>{attendee.companyTitle}</span>
      </Checkbox>
    )
  }

  const _selectAttendee = (e: { target: any }) => {
    const { target } = e
    const { checked, value } = target
    if (!checked) {
      return setSelectedAttendees(selectedAttendees.filter((id: any) => id !== value))
    }
    setSelectedAttendees(selectedAttendees.concat([value]))
  }

  return (
    <Modal
      className={'br-representative-modal'}
      title={
        <>
          <div>{'Add representatives'}</div>
          <InfoSubTitle testId={'add-representatives-subtitle'} margin={'12px 0 0 '}>
            {'You can only add representatives who have joined your event. '}
            <a href={'https://help.brella.io/en/organizers/how-do-attendees-get-linked-to-sponsors#MANUAL'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
          </InfoSubTitle>
        </>
      }
      visible={visible}
      okText={
        <AddRepButton
          attendees={selectedAttendees}
          refetchSponsorStatistics={props.refetchSponsorStatistics}
        />
      }
      okButtonProps={{ disabled: !selectedAttendees.length }}
      onCancel={_onCancel}
      destroyOnClose
    >
      <Wrapper>
        <Input.Search
          placeholder={'Search'}
          enterButton={<SearchOutlined />}
          onSearch={_onSearch}
          onChange={_handleSearchChange}
          disabled={isFetching}
        />

        <Checkbox.Group className={'select'} >
          {isFetching
            ? <StyledFlex justifyContent={'center'} margin={'150px 0'}>
              <Spin size={'small'} />
            </StyledFlex>
            : attendees?.map(_renderAttendee)
          }
        </Checkbox.Group>
      </Wrapper>
      <Pagination
        className={'pagination'}
        total={meta?.totalCount}
        defaultPageSize={state.pageSize}
        onChange={_setPageNumber}
        current={state.pageNumber}
        onShowSizeChange={(__, pageSize) => {
          _setPageNumber(1)
          _setPageSize(pageSize)
        }}
      />
    </Modal>
  )
}

export default RepresentativeModal

const Wrapper = styled('div')({
  minHeight: 400,
  minWidth: 450,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '.select': {
    margin: '20px 0'
  }
})
