import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import NavTabMenu from '@Common/NavTabMenu'
import styled from '@emotion/styled'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import { StyledFlex, StyledHeaderText, StyledLightInnerTooltip, StyledSubtitleText } from '@Reusable/Styled'
import { useSponsorContext } from '@SponsorContext'
import theme from '@Theme'
import { Tooltip } from 'antd'
import { EventParamsTypes } from 'src/Types'

import { useGlobalContext } from '../../GlobalContext/GlobalContext'

import BackButton from './BackButton'

interface SponsorNavProps {
  usesAnalytics: boolean,
  usesLeadScanning: boolean
}

const SponsorNav = ({ usesAnalytics, usesLeadScanning }: SponsorNavProps) => {
  const params = useParams<EventParamsTypes>()
  const match = useRouteMatch()
  const history = useHistory()
  const { setSearchTerm } = useGlobalContext()
  const _handleGoBack = () => {
    const { url } = match
    const { eventId } = params
    history.replace(url.slice(0, url.lastIndexOf(`/${eventId}`)))
    setSearchTerm('')
  }

  const { virtualBoothFieldsTouched, sponsorState } = useSponsorContext()
  const { selectedEvent: event } = useSelectedEvent()
  const { name: eventName } = event
  const sponsorName = sponsorState?.name
  const canBeDisabledTabs = [
    {
      key: 'traffic-analytics',
      name: 'Traffic Analytics',
      disabled: !usesAnalytics,
      tooltipText: 'To see your booth’s traffic analytics, reach out to the event organizer.'
    },
    {
      key: 'prospects',
      name: 'Prospects',
      disabled: !usesLeadScanning,
      tooltipText: 'To see your prospects details, reach out to the event organizer.'
    }
  ]

  return (
    <SponsorNavContainer>
      <StyledFlex>
        <BackButton
          disabled={virtualBoothFieldsTouched} onClick={() => _handleGoBack()} style={{ marginLeft: -16 }}
          data-test={'back-to-events-page-button'}
        />
        <div>
          <StyledHeaderText data-test={'event-title'}>
            {'Event: '}
            {eventName}
          </StyledHeaderText>
          <StyledSubtitleText data-test={'sponsor-title'}>
            {'Company: '}
            {sponsorName}
          </StyledSubtitleText>
        </div>
      </StyledFlex>
      <NavTabMenu defaultSelectedKey={!usesAnalytics ? 'virtual-booth' : 'overview'} >
        <NavTabMenu.Item key={'overview'} tab={'Overview'}/>
        <NavTabMenu.Item key={'virtual-booth'} tab={'Virtual Booth'} />
        <NavTabMenu.Item key={'representatives'} tab={'Representatives'} />
        <NavTabMenu.Item key={'products'} tab={'Products'} />
        <NavTabMenu.Item key={'downloads'} tab={'Downloads'} />
        <NavTabMenu.Item key={'meetings'} tab={'Engagement'} />
        {canBeDisabledTabs.map(({ key, name, disabled, tooltipText }) => (
          <NavTabMenu.Item
            key={key}
            tab={!disabled
              ? name
              : <Tooltip
                color={theme.colors.primaryBackground}
                title={
                  <StyledLightInnerTooltip>
                    {tooltipText}
                  </StyledLightInnerTooltip>}
              >
                <span
                  data-test={`disabled-${key}-tab`}
                >
                  {name}
                </span>
              </Tooltip>}
            disabled={disabled}
          />
        ))}
      </NavTabMenu>
    </SponsorNavContainer>
  )
}

export default SponsorNav

const SponsorNavContainer = styled('div')({
}, (props) => ({
  padding: '24px 48px 0',
  backgroundColor: props.theme.colors.primaryBackground,
  '.ant-tabs-nav': {
    marginBottom: 0,
    marginTop: 12
  }
}))
