import React from 'react'
import AuthService from '@Auth'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { message } from 'antd'
import { RPCError } from 'magic-sdk'
import PropTypes from 'prop-types'

import AuthButton from './AuthButton'
import { getRedirectUri } from './helpers'
import SignInWithEmail from './SignInWithEmail'

export default function SignInBox({ withAnimation = true, initialPath = '/' }) {
  const [emailLoginVisible, setEmailLoginVisible] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)

  async function onOauthLogin(provider) {
    try {
      setDisabled(true)
      localStorage.setItem('redirect-url-after-login', initialPath)
      AuthService.loginWithRedirect(provider, getRedirectUri())
    } catch (error) {
      error instanceof RPCError && message.error(error.rawMessage)
      console.error(error)
      setDisabled(false)
    }
  }

  return (
    <FadeIn withAnimation={withAnimation}>
      <Container data-test={'sign-in-card'}>
        {emailLoginVisible
          ? (
            <SignInWithEmail initialPath={initialPath} />
          )
          : (
            <>
              <AuthButton.Google
                disabled={disabled}
                onSubmit={onOauthLogin}
                data-test={'google-social-login-button'}
              />
              <AuthButton.Email
                disabled={disabled}
                onSubmit={setEmailLoginVisible}
                data-test={'change-login-method-link'}
              />
            </>
          )}
        {emailLoginVisible && (
          <ChangeAuthenticationMethodLink
            data-test={'back-button'}
            onClick={() => { setEmailLoginVisible(false) }}
          >
            {'← Go back'}
          </ChangeAuthenticationMethodLink>
        )}
      </Container>
    </FadeIn>
  )
}

SignInBox.propTypes = {
  initialPath: PropTypes.string,
  withAnimation: PropTypes.bool
}

const fadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-20px)' },
  '66%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const Container = styled('div')({
  margin: '0 auto',
  maxWidth: '340px'
})

const ChangeAuthenticationMethodLink = styled('a')({
  color: '#333',
  fontSize: '12px',
  fontWeight: '500',
  display: 'block',
  textDecoration: 'underline',
  marginTop: '2em',
  textAlign: 'center',

  ':hover': {
    color: '#000'
  }
})

const FadeIn = styled('div')((props) => ({
  animation: `${fadeIn} ${props.withAnimation ? '1s' : '0s'} cubic-bezier(0.22, 1, 0.36, 1)`
}))
