import React from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { Attendee } from '@Hooks/Types'
import { prop, uniqBy } from 'ramda'

import theme from '../../../../../../Themes/BrellaTheme'

import AttendeeListItem from './AttendeeListItem/AttendeeListItem'

interface PartnerAttendeeListProps {
  sponsorAttendees: Attendee[],
  fetchingAttendees: boolean

}

const PartnerAttendeeList = (props: PartnerAttendeeListProps) => {
  const renderAttendee = (attendee: Attendee) => {
    return (
      <AttendeeListItem
        key={attendee.id}
        attendee={attendee}
      />
    )
  }

  const { sponsorAttendees, fetchingAttendees } = props

  const UniqueSponsorAttendees = uniqBy(prop('id'), sponsorAttendees)
  const showLoading = fetchingAttendees && (sponsorAttendees.length === 0)

  return (
    <React.Fragment>
      {showLoading && (
        <LoadingContainer>
          <LegacyIcon type={'loading'} style={{ fontSize: 24 }} spin />
        </LoadingContainer>
      )}
      <Grid>
        {UniqueSponsorAttendees.map((attendee) => renderAttendee(attendee))}
      </Grid>
    </React.Fragment>
  )
}

export default PartnerAttendeeList

const LoadingContainer = styled('div')({
  padding: '8px 0px 32px 0px',
  textAlign: 'center',
  margin: '0 auto'
})

const Grid = styled('div')({
  display: 'grid',
  gridRowGap: theme.margins.baseMargin,
  gridColumnGap: theme.margins.baseMargin,
  gridTemplateColumns: '1fr 1fr',
  [theme.media.xs]: { gridTemplateColumns: '1fr' }
})
