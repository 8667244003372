import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AuthService from '@Auth'
import styled from '@emotion/styled'
import BrellaLogoWithText from '@SVG/brella-logo.svg'
import { Button, Divider, Form, Input, message, Popconfirm, Spin } from 'antd'
import PropTypes from 'prop-types'

import { useAuth } from './useAuth'
import withPasswordValidation from './withPasswordValidation.jsx'

const oauthUser = AuthService.getOauthUser()

const AuthCreateAccount = ({ validatePasswordError, renderPasswordErrors, isPasswordValid }) => {
  const [disabled, setDisabled] = useState(false)
  const [profileData, setProfileData] = useState({})
  const [form] = Form.useForm()
  const [cancelling, setCancelling] = useState(false)
  const location = useLocation()
  const auth = useAuth()
  const history = useHistory()

  const cancel = useCallback(
    () => {
      setCancelling(true)
      localStorage.removeItem('authHeaders')
      localStorage.removeItem('oauthUser')
      history.replace('/login')
    },
    [history]
  )

  useEffect(() => {
    form.setFieldsValue({
      ...oauthUser,
      email: location.state?.email || oauthUser?.email
    })
  }, [form, location.state])

  async function onSubmit(values) {
    try {
      setDisabled(true)
      await auth.signUp({
        user: {
          ...values
        },
        onSuccess: () => history.replace(location.state?.from || '/dashboard'),
        onError: (errorMessage) => message.error('Sign up failed: ' + errorMessage)
      })

      setDisabled(false)
    } catch (error) {
      setDisabled(false)
    }
  }

  const onChange = (propName) => (e) => {
    setProfileData({
      ...profileData,
      [propName]: e.target.value
    })
  }

  return (
    <AuthCreateContainer>
      <BrellaLargeLogo src={BrellaLogoWithText} />
      <Header>{'Just a few details and we are good to go!'}</Header>
      <Form
        form={form}
        name={'create-account'}
        onFinish={onSubmit}
        layout={'vertical'}
        initialValues={{ remember: true }}
      >
        <Form.Item
          label={'Email'}
          name={'email'}
          rules={[
            { required: true, message: 'Email cannot be empty' },
            { type: 'email', message: 'Email is not valid' }
          ]}
        >
          <LargeInput
            placeholder={location.state?.email || 'Email'}
            size={'large'}
            type={'email'}
            disabled
          />
        </Form.Item>

        <Divider>
          {'Name'}
        </Divider>

        <Form.Item
          label={'First name'}
          name={'first-name'}
          rules={[{ required: true, message: 'First name cannot be empty' }]}
        >
          <LargeInput
            placeholder={oauthUser['first-name'] || 'First name'}
            size={'large'}
            autoFocus
            type={'text'}
            autoComplete={'name.given-name'}
            onChange={onChange('firstName')}
          />
        </Form.Item>

        <Form.Item
          label={'Last name'}
          name={'last-name'}
          rules={[{ required: true, message: 'Last name cannot be empty' }]}
        >
          <LargeInput
            placeholder={oauthUser['last-name'] || 'Last name'}
            size={'large'}
            type={'text'}
            autoComplete={'name.family-name'}
            onChange={onChange('lastName')}
          />
        </Form.Item>

        <>
          <Divider>
            {'Password'}
          </Divider>

          <Form.Item
            label={'Password'}
            name={'password'}
            style={{ marginBottom: '0px' }}
            rules={[
              { required: true, message: 'Password cannot be empty' }, () => ({
                validator(_, value) {
                  validatePasswordError(value)
                  return Promise.resolve()
                }
              })
            ]}
            help={''}
          >
            <LargeInputPassword
              placeholder={'Password'}
              size={'large'}
              type={'password'}
              autoComplete={'new-password'}
              onChange={onChange('password')}
            />
          </Form.Item>
          {renderPasswordErrors}
        </>

        <Divider>
          {'Company'}
        </Divider>

        <Form.Item
          label={'Job title'}
          name={'company-title'}
          rules={[{ required: true, message: 'Job title cannot be empty' }]}
        >
          <LargeInput
            placeholder={'Job title'}
            size={'large'}
            type={'text'}
            autoComplete={'organization-title'}
            onChange={onChange('companyTitle')}
          />
        </Form.Item>

        <Form.Item
          label={'Company / Organization'}
          name={'company-name'}
          rules={[{ required: true, message: 'Company / Organization cannot be empty' }]}
        >
          <LargeInput
            placeholder={'Company / Organization'}
            size={'large'}
            type={'text'}
            autoComplete={'organization'}
            onChange={onChange('companyName')}
          />
        </Form.Item>

        <Divider />

        <LargeButton
          type={'primary'} size={'large'} htmlType={'submit'}
          disabled={disabled || !isPasswordValid}
        >
          {disabled ? 'Processing' : 'Finish'}
        </LargeButton>
      </Form>

      <Popconfirm
        title={'Cancel account creation?'}
        onConfirm={cancel}
        okText={'Yes'}
        cancelText={'No'}
      >
        <CancelLink type={'link'}>
          {'Cancel / Change email'}
          {cancelling && (
            <Spin size={'small'} />
          )}
        </CancelLink>
      </Popconfirm>
    </AuthCreateContainer>
  )
}

export default withPasswordValidation(AuthCreateAccount)

AuthCreateAccount.propTypes = {
  validatePasswordError: PropTypes.func.isRequired,
  renderPasswordErrors: PropTypes.node.isRequired,
  isPasswordValid: PropTypes.bool.isRequired
}

const inputStyle = (props) => ({
  padding: '12px 18px',
  borderRadius: '8px!important',
  backgroundColor: props.disabled && '#f0f0f0!important',
  color: '#1a1a1a!important'
})

const AuthCreateContainer = styled('div')({
  margin: '60px auto 30px auto',
  maxWidth: '340px',
  alignItems: 'center',
  textAlign: 'center'
})

const LargeInput = styled(Input)(inputStyle)

const LargeInputPassword = styled(Input.Password)(inputStyle)

const LargeButton = styled(Button)({
  width: '100%',
  height: '50px!important'
})

const Header = styled('h3')({
  color: '#000',
  fontSize: '16px',
  fontWeight: 500,
  margin: '0px 32px 32px 32px'
})

const BrellaLargeLogo = styled('img')({
  height: 20,
  marginBottom: '30px'
})

const CancelLink = styled(Button)({
  color: '#777!important',
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '12px!important'
})
