import Event from '../../Modules/Event/Event'
import Events from '../../Modules/Events/Events'

const Routes = [
  {
    id: 1,
    to: Events.routeConfig.path,
    exact: true,
    type: 'redirect',
    from: '/',
    label: 'Events'
  },
  {
    id: 2,
    to: Events.routeConfig.path,
    label: 'Events',
    component: Events,
    exact: true,
    type: 'menu-item'
  },
  {
    id: 3,
    to: Event.routeConfig.path,
    label: 'Sponsors',
    component: Event,
    exact: false,
    type: 'menu-item'
  }
]

export default Routes
