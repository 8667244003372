/* eslint-disable @typescript-eslint/no-explicit-any */
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { has, omit } from 'ramda'
import Immutable from 'seamless-immutable'

export function parseContentFromBackend(contentFromBackend: {blocks: any, entityMap: any}) {
  let content

  if (!contentFromBackend) return

  if (has('blocks')(contentFromBackend)) {
    const mutableContent = Immutable.asMutable(contentFromBackend, {
      deep: true
    })
    mutableContent.entityMap = mutableContent.entityMap
      ? mutableContent.entityMap
      : {}
    content = EditorState.createWithContent(convertFromRaw(mutableContent))
  }

  return content
}

export function formatPartnerForBackend(
  partnerId: any,
  formValues:
  { id: any, logo?: string | any[] | null, content: { getCurrentContent: () => any } | any },
  editMode: any
) {
  let payload = {
    ...formValues
  }

  function isLogoUpdated() {
    // Urls are less than 200 chars, Base64s are not
    // Removed logo should be updated and could contain null as value
    return (formValues.logo && formValues.logo.length > 200) || formValues.logo === null
  }

  if (editMode) {
    payload = {
      ...payload,
      id: partnerId
    }
  }

  if (formValues.content) {
    payload = {
      ...payload,
      content: convertToRaw(formValues.content.getCurrentContent())
    }
  }

  if (editMode && !isLogoUpdated()) {
    payload = omit(['logo'])(payload)
  }

  return payload
}
