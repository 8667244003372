import { useParams } from 'react-router-dom'
import { sponsorVisitorStatisticsDay } from '@EntitySauce/Queries'
import { entitySauce } from '@State'

import { EventParamsTypes } from '../Types'

import { ResponseType } from './Types'

type QueryParams = {
  startDate: string,
  endDate: string
}

type BoothVisits = {
  id: string,
  type: string,
  date: string,
  totalBoothVisits: number,
  totalUniqueVisitors: number
}

type SponsorVisitorStatisticsDay = ResponseType & {
  boothVisits: BoothVisits[]
}

const useSponsorVisitorStatisticsDay = (queryParams: QueryParams): SponsorVisitorStatisticsDay => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { data, ...rest } = entitySauce.useGet(sponsorVisitorStatisticsDay(
    eventId,
    sponsorId,
    queryParams
  ), {
    staleTime: 50000,
    refetchOnWindowFocus: false,
    enabled: !!eventId && !!sponsorId
  })
  return { boothVisits: data?.data ?? [], ...rest }
}

export default useSponsorVisitorStatisticsDay
