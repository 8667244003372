import { ReactNode } from 'react'
import styled from '@emotion/styled'

interface InfoSubTitleProps {
  children: ReactNode,
  margin?: string,
  testId: string
}

const InfoSubTitle = ({ children, margin, testId }: InfoSubTitleProps) => {
  return (
    <StyledInfoSubTitle data-test={testId} margin={margin}>{children}</StyledInfoSubTitle>
  )
}

export default InfoSubTitle

type StyledInfoSubTitleType = {
  margin?: string
}
const StyledInfoSubTitle = styled('div')<StyledInfoSubTitleType>({
}, (props) => ({
  margin: props.margin,
  color: props.theme.colors.lightText,
  fontWeight: 400,
  fontSize: 14,
  a: {
    color: `${props.theme.colors.primary} !important`,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))
