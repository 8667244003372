import './ImageCropper.css'

import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import { Button, Slider } from 'antd'
import PropTypes from 'prop-types'

const DEFAULT_EDITOR_SIZE = 520
const EDITOR_BORDER_SIZE = 48

class ImageCropper extends React.PureComponent {
  constructor(props) {
    super(props)

    this._resizeTimeout = null // For resize debouncing

    this.state = {
      image: null,
      editorOpen: false,
      editorSize: DEFAULT_EDITOR_SIZE,
      editorScale: 1
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this._onWindowResize)
    this._onWindowResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onWindowResize)
  }

  /**
   * Opens the editor with default settings.
   * @param   {string}  image   Url of an image being edited.
   */
  // eslint-disable-next-line react/no-unused-class-component-methods -- open is used when other component make refs to ImageCropper, e.g. ImageUpload
  open = (image) => {
    this.setState({
      image,
      editorOpen: true,
      editorScale: 1
    })
  }

  _onWindowResize = () => {
    // Debounce resize calls
    clearTimeout(this._resizeTimeout)
    this._resizeTimeout = setTimeout(this._updateWidth, 200)
  }

  _updateWidth = () => {
    // Check mobile break point
    if (window.innerWidth < 480) {
      this.setState({ editorSize: window.innerWidth - 32 })
    } else {
      this.setState({ editorSize: DEFAULT_EDITOR_SIZE })
    }
  }

  _close = () => {
    this.setState({ editorOpen: false })
  }

  _onScaleChange = (value) => {
    this.setState({ editorScale: value })
  }

  _onAccept = () => {
    if (this._editor) {
      let [imageFormat] = this.state.image.match(/(image\/[^;]{3,4})/gi)

      const hasKnownFormat = ['svg', 'png', 'jpeg'].some((format) => imageFormat.includes(format))
      if (!hasKnownFormat) imageFormat = 'image/jpeg'

      const image = this.props.cropToSize
        ? this._editor.getImageScaledToCanvas().toDataURL(imageFormat, this.props.imageQuality)
        : this._editor.getImage().toDataURL(imageFormat, this.props.imageQuality)

      this.props.onAccept && this.props.onAccept(image)
    }

    this._close()
  }

  _saveEditorRef = (ref) => {
    this._editor = ref
  }

  render() {
    const { aspectRatio, circle, scaleMin, scaleMax } = this.props
    const { editorOpen, editorSize, editorScale, image } = this.state

    if (!editorOpen) {
      return null
    }

    const width = editorSize - 2 * EDITOR_BORDER_SIZE
    const height = circle ? width : width / aspectRatio
    const borderRadius = circle ? width : 0

    return (
      <div className={'image-cropper-backdrop'}>
        <div className={'image-cropper-wrapper'}>
          <div
            className={'image-cropper-container'}
            style={{ width: editorSize }}
          >
            <div
              icon={'close'}
              className={'image-cropper-close'}
              onClick={this._close}
            >
              <CloseOutlined />
            </div>
            <AvatarEditor
              ref={this._saveEditorRef}
              image={image}
              width={width}
              height={height}
              scale={editorScale}
              border={EDITOR_BORDER_SIZE}
              borderRadius={borderRadius}
              color={[0, 0, 0, 0.6]}
            />
            <Slider
              style={{ margin: 16 }}
              min={scaleMin}
              max={scaleMax}
              step={0.01}
              value={editorScale}
              onChange={this._onScaleChange}
            />
            <div className={'image-cropper-button-container'}>
              <Button
                style={{ marginRight: 16 }}
                onClick={this._close}
              >
                {'Cancel'}
              </Button>
              <Button
                type={'primary'}
                onClick={this._onAccept}
              >
                {'Ok'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ImageCropper.defaultProps = {
  aspectRatio: 1,
  imageQuality: 0.8,
  circle: false,
  cropToSize: false,
  scaleMin: 1,
  scaleMax: 2
}

ImageCropper.propTypes = {
  aspectRatio: PropTypes.number,
  imageQuality: PropTypes.number,
  circle: PropTypes.bool,
  cropToSize: PropTypes.bool,
  scaleMin: PropTypes.number,
  scaleMax: PropTypes.number,
  onAccept: PropTypes.func
}

export default ImageCropper
