import { ReactNode } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { slugify } from '@Lib/Util'
import Card from '@Reusable/Card/Card'
import { FadeInDown, StyledFlex, StyledHeaderText, StyledLightInnerTooltip, StyledSubtitleText } from '@Reusable/Styled'
import theme from '@Theme'
import { Spin, Tooltip } from 'antd'

export type NumericStat = {
  key: number,
  label: string,
  value: number | string,
  extra?: ReactNode,
  suffix?: string,
  labelToolTip?: ReactNode,
  loading?: boolean
}

interface NumericStatCardProps {
  stat: NumericStat
}

const NumericStatCard = ({ stat } : NumericStatCardProps) => {
  return (
    <Card testId={slugify(stat.label)}>

      <StyledFlex>

        <StyledSubtitleText fontSize={'16px'} margin={'12px 6px 12px 16px'} style={{ cursor: 'pointer' }}>
          {stat.label}
        </StyledSubtitleText>

        {stat.labelToolTip && <Tooltip
          color={theme.colors.primaryBackground} title={<StyledLightInnerTooltip>
            {stat.labelToolTip}
          </StyledLightInnerTooltip>}
        >
          <InfoCircleOutlined style={{ cursor: 'pointer', marginTop: 2 }}/>
        </Tooltip>}

        {stat.loading && <Spin style={{ marginLeft: 12, marginTop: 2 }}/>}

      </StyledFlex>

      <StyledHeaderText fontSize={'30px'} margin={'12px 16px 0'}>
        {stat.value}
        {stat.suffix}
      </StyledHeaderText>
      {!stat.loading &&
          <FadeInDown delay={500} duration={1000}>
            {stat.extra}
          </FadeInDown>
      }
    </Card>
  )
}

export default NumericStatCard
