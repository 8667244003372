import { History } from 'history'
import queryString from 'query-string'
import { isNil, prop, sortBy, unless } from 'ramda'

const _getQueryParams = (search: string) => {
  return queryString.parse(search)
}

const _stringifyQueryParams = (obj: Record<string, string | number | boolean | string[] | null>) => {
  return queryString.stringify(obj)
}

export const setUriParameter = (history: History, attr: string, value: string | number | boolean) => {
  if (!history?.location) return

  const current = history.location.search || ''

  const params = _getQueryParams(current)

  const result = {
    ...params,
    [attr]: value
  }

  history.push(`?${_stringifyQueryParams(result)}`)
}

export const getBase64Image = (image: Blob, callback: (arg0: string | ArrayBuffer | null) => void) => {
  const reader = new window.FileReader()

  reader.addEventListener(
    'load',
    () => callback(reader.result),
    false
  )

  if (image) {
    reader.readAsDataURL(image)
  }
}

export const sortItemsByPosition = unless(
  isNil,
  sortBy(prop('position'))
)
