/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from 'react-router-dom'
import { eventAttendeesQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'

import { EventParamsTypes } from '../Types'

const useEventAttendees = (
  queryParams?: {
    page: { number: number, size: number},
    search: string
  }
) => {
  const params = useParams<EventParamsTypes>()
  const { eventId } = params

  const { data, ...rest } = entitySauce.useGet(
    eventAttendeesQuery(eventId, queryParams),
    { staleTime: 50000, refetchOnWindowFocus: false, enabled: eventId }
  )
  const attendeesData = data?.data ?? []
  const attendees = attendeesData.map((attendee: any) => {
    return (
      { ...attendee, ...attendee?.user }
    )
  })
  const { meta } = data

  return { attendees, meta, ...rest }
}

export default useEventAttendees
