/* eslint-disable @typescript-eslint/no-explicit-any */
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import styled from '@emotion/styled'
import { sponsorQuery } from '@EntitySauce/Queries'
import useSponsor from '@Hooks/useSponsor'
import Button, { ButtonTypes } from '@Reusable/Button'
import { useSponsorContext } from '@SponsorContext'
import { entitySauce } from '@State'
import { message, Popconfirm, Spin } from 'antd'

import { EventParamsTypes } from '../../../../Types'

interface ActionButtonsProps {
  onSaveCallBack?: () => void,
  onCancelCallBack?: () => void,
  padding?: string
}

const ActionButtons = ({ onCancelCallBack, onSaveCallBack, padding }: ActionButtonsProps) => {
  const {
    virtualBoothFieldsTouched,
    setVirtualBoothFieldsTouched,
    sponsorTabsSortTouched,
    setSponsorTabsSortTouched,
    setSponsorState,
    sponsorState
  } = useSponsorContext()
  const { sponsor, refetch: refetchSponsor } = useSponsor()
  const params = useParams<EventParamsTypes>()
  const match = useRouteMatch()
  const history = useHistory()
  const { eventId } = params

  const _handleGoBack = () => {
    const { url } = match
    const { eventId } = params
    history.replace(url.slice(0, url.lastIndexOf(`/${eventId}`)))
  }

  const _extractPayload = function({
    name,
    logoUrl,
    coverImageUrl,
    facebook,
    twitter,
    linkedin,
    website,
    instagram,
    subtitle,
    ctaText,
    industry,
    content,
    boothTabs
  }: any) {
    const payload = {
      name,
      logo: logoUrl,
      cover_image: coverImageUrl,
      facebook,
      twitter,
      linkedin,
      website,
      instagram,
      subtitle,
      cta_text: ctaText,
      industry,
      content,
      booth_tabs: boothTabs
    }

    if (!payload?.logo?.startsWith('data:image')) {
      delete payload.logo
    }
    if (!payload?.cover_image?.startsWith('data:image')) {
      delete payload.cover_image
    }
    return { sponsor: payload }
  }
  const [patch, { isLoading }] = entitySauce.usePatch(
    sponsorQuery(eventId, sponsor?.id),
    {
      onSuccess: () => {
        message.success('Changes saved successfully.')
        setVirtualBoothFieldsTouched(false)
        setSponsorTabsSortTouched(false)
        refetchSponsor()
      },
      onError: (res: { data: { errors: { title: string }[] } }) => {
        message.error(`${res?.data?.errors?.[0].title ?? 'Couldn\'t save the changes'}. Please try again.`)
      }
    }
  )

  const _exitWithoutSaving = () => {
    setVirtualBoothFieldsTouched(false)
    setSponsorState(sponsor)
    _handleGoBack()
    onCancelCallBack && onCancelCallBack()
  }

  const _save = async() => {
    onSaveCallBack && onSaveCallBack()
    await patch({ data: _extractPayload(sponsorState) })
  }

  const _saveButton = (
    <Button
      style={{ marginRight: 8 }}
      disabled={(!virtualBoothFieldsTouched && !sponsorTabsSortTouched) || isLoading}
      onClick={_save}
    >
      {isLoading ? <Spin size={'small'}/> : 'Save'}
    </Button>
  )

  const _exitWithoutSavingButton = (
    <Popconfirm
      placement={'topLeft'}
      key={'cancel'}
      title={'Are you sure you want to discard changes?'}
      onConfirm={_exitWithoutSaving}
      okText={'Exit'}
    >
      <Button
        type={ButtonTypes.Default}
        label={'Exit without saving'}
      />
    </Popconfirm>
  )
  return (
    <ActionButtonsContainer padding={padding} data-test={'form-buttons-container'}>
      {_saveButton}
      {(virtualBoothFieldsTouched || sponsorTabsSortTouched) &&
           _exitWithoutSavingButton }
    </ActionButtonsContainer>
  )
}

export default ActionButtons

type ActionButtonsContainerProps = {
  padding?: string
}
const ActionButtonsContainer = styled('div')<ActionButtonsContainerProps>({
}, (props) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: props.padding
}))
