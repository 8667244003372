import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Table } from 'antd'
import CSS from 'csstype'

type StyledFlexProps = {
  alignItems?: CSS.Property.AlignItems,
  justifyContent?: CSS.Property.JustifyContent,
  margin?: CSS.Property.Margin
}
export const StyledFlex = styled('div')<StyledFlexProps>({
}, (props) => ({
  display: 'flex',
  alignItems: props.alignItems || 'center',
  justifyContent: props.justifyContent || 'flex-start',
  margin: props.margin
}))

type StyledHeaderTextProps = {
  margin?: CSS.Property.Margin,
  fontSize?: CSS.Property.FontSize
}
export const StyledHeaderText = styled('h1')<StyledHeaderTextProps>({
}, (props) => ({
  fontWeight: 600,
  fontSize: props.fontSize || 20,
  margin: props.margin || 0
}))

type StyledTitleTextProps = StyledHeaderTextProps
export const StyledTitleText = styled('h3')<StyledTitleTextProps>({
}, (props) => ({
  fontWeight: 600,
  fontSize: props.fontSize || 16,
  margin: props.margin || 0,
  color: '#000'
}))

type StyledSubtitleTextProps = {
  margin?: CSS.Property.Margin,
  strong?: boolean,
  fontSize?: CSS.Property.FontSize
}
export const StyledSubtitleText = styled('p')<StyledSubtitleTextProps>({
}, (props) => ({
  fontSize: props.fontSize || 14,
  lineHeight: '22px',
  margin: props.margin || 0,
  color: props.strong ? props.theme.colors.strongSubtitle : props.theme.colors.secondaryText
}))

type StyledMarginProps = {
  margin: CSS.Property.Margin
}
export const StyledMargin = styled('div')<StyledMarginProps>({
}, (props) => ({
  margin: props.margin
}))

export const StyledTable = styled(Table)({
  '.ant-table-pagination .ant-pagination': {
    margin: '32px 0 0'
  },
  '.ant-table-container table > thead > tr >th': {
    background: '#FAFAFA !important'
  },
  '.ant-table-cell a[class="delete-action-anchor"]': {
    color: 'red !important'
  }
})

export const StyledLightInnerTooltip = styled('div')({
}, (props) => ({
  color: props.theme.colors.text,
  a: {
    color: `${props.theme.colors.primary} !important`,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

const fadeInDown = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate3d(0, -40%, 0)'
  },
  '100%': {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)'
  }
})

type FadeInDownType = {
  delay?: number,
  duration?: number
}

export const FadeInDown = styled('div')<FadeInDownType>({
}, ({ delay, duration }) => ({
  animation: `${fadeInDown} ease-in both`,
  animationDelay: `${delay || 0}ms`,
  animationDuration: `${duration || 0}ms`
}))
