import { useEffect } from 'react'
import { useQueryCache } from 'react-query'
import { Route, Switch } from 'react-router-dom'
import NoRouteMatch from '@Common/NoRouteMatch'
import PrivateRoute from '@Common/PrivateRoute'
import { ThemeProvider } from '@emotion/react'
import { EntitySauceProvider } from '@State'
import theme from '@Theme'

import AuthContainer from './Auth/AuthContainer'
import AuthCreateAccount from './Auth/AuthCreateAccount'
import LogoutContainer from './Auth/LogoutContainer'
import SocialLoginRedirect from './Auth/SocialLoginRedirect'
import { AuthProvider } from './Auth/useAuth'
import useAdminInvite from './Modules/Invite/Hooks/useAdminInvite'
import Root from './Root/Root'

const REACT_QUERY_CONFIG = {
  queries: {
    structuralSharing: false // Can't use structural sharing due to circular refs in JSON:API data
  },
  mutations: {
    structuralSharing: false // Can't use structural sharing due to circular refs in JSON:API data
  }
}

const App = () => {
  useEffect(() => {
    console.log(`Version: ${import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA}`)
  }, [])

  const cache = useQueryCache()
  const { storeToken } = useAdminInvite()

  useEffect(function storeTokenFromInvite() {
    storeToken()
  }, [])

  return (
    <EntitySauceProvider cache={cache} config={REACT_QUERY_CONFIG}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Switch>
            <Route
              path={'/login'}
              component={AuthContainer}
            />
            <Route
              path={'/logging-out'}
              component={LogoutContainer}
            />
            <Route
              path={'/auth-redirect'}
              component={SocialLoginRedirect}
            />
            <Route
              path={'/create-account'}
              component={AuthCreateAccount}
            />
            <PrivateRoute
              path={'/'}
              component={Root}
            />
            <Route path={'*'}>
              <NoRouteMatch />
            </Route>
          </Switch>
        </AuthProvider>
      </ThemeProvider>
    </EntitySauceProvider>
  )
}

export default App
