/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import { Moment } from 'moment'

export const getDaysBetweenDates = function(startDate: Moment, endDate: Moment) {
  const now = startDate.clone(); const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('MM-DD-YYYY'))
    now.add(1, 'days')
  }
  return dates
}
