import React from 'react'
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import download from '../../Lib/download'

class DownloadButton extends React.PureComponent {
  _download = (e) => {
    e.stopPropagation()

    try {
      const data = this.props.exporter(this.props.data, this.props.exportSchema)
      download(this.props.filename, data)
    } catch (error) {
      this.props.onError(error)
    }
  }

  render() {
    const { type, data, exporter, children, tableName } = this.props

    return (
      <Button
        data-test={`download-button-${tableName}`}
        type={type}
        disabled={!data || data.length === 0 || !exporter}
        icon={<DownloadOutlined />}
        onClick={this._download}
      >
        {children === undefined ? 'Download' : children}
      </Button>
    )
  }
}

DownloadButton.defaultProps = {
  type: 'default',
  filename: 'data.txt',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onError: () => {}
}

DownloadButton.propTypes = {
  type: PropTypes.string,
  filename: PropTypes.string,
  data: PropTypes.array,
  exporter: PropTypes.func,
  exportSchema: PropTypes.object,
  onError: PropTypes.func,
  children: PropTypes.string,
  tableName: PropTypes.string.isRequired
}

export default DownloadButton
