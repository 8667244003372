import Modal from 'antd/lib/modal/Modal'

import DownloadsForm from '../DownloadsForm'
import { DownloadsFormType } from '../Types'

interface DownloadsCreateNewModalProps {
  onClose: () => void,
  isVisible: boolean
}

const DownloadsCreateNewModal = ({ onClose, isVisible }: DownloadsCreateNewModalProps) => {
  return (
    <Modal
      title={'Add new downloadable'}
      visible={isVisible}
      onCancel={onClose}
      centered={true}
      data-test={'modal'}
      closable
      footer={false}
      destroyOnClose
    >

      <DownloadsForm
        onCancel={onClose}
        formType={DownloadsFormType.NEW}
      />
    </Modal>
  )
}

export default DownloadsCreateNewModal
