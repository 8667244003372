const getUrlParamater = (url: string, param: string) => {
  const urlParams = new URLSearchParams(url)

  return urlParams.get(param) // returns null if param not found (or if param is empty)
}

const getServiceHeaderFromUrl = () => {
  const url = window.location.search
  const service = getUrlParamater(url, 'service')

  if (service) {
    setServiceHeaderToSessionStorage(service) // save the service in the session storage
    return service
  }
}

const getServiceHeaderFromSessionStorage = () => {
  const service = sessionStorage.getItem('service')

  if (service) {
    return service
  }
}

const setServiceHeaderToSessionStorage = (service: string) => {
  sessionStorage.setItem('service', service)
}

/**
 * Get service header to target specific backend service.
 * eg. https://sponsor.brella.io?service=my-backend-service
 *
 * @function getServiceHeader
 * @return {object} HTTP headers to target specific backend service
 */
export const getServiceHeader = () => {
  const service = getServiceHeaderFromUrl() ?? getServiceHeaderFromSessionStorage()

  if (!service) { // if no service is found in the url or in the session storage
    return undefined
  }

  return {
    'brella-use-service': service
  }
}

export const headerDefinitions = {
  v4: {
    headers: {
      Accept: 'application/vnd.brella.v4+json',
      'brella-frontend': 'sponsor-panel',
      ...getServiceHeader()
    }
  }
}
