import useEvents from '@Hooks/useEvents/useEvents'

import EventsTable from './EventsTable/EventsTable'

function Events(): JSX.Element {
  const { events, isLoading } = useEvents()

  return (
    <EventsTable loading={isLoading} events={events} />
  )
}

Events.routeConfig = {
  path: '/events'
}

export default Events
