import { ProductType } from '../Types'

export const assignIndexes = (sponsors: ProductType[]) =>
  sponsors.map(
    (sponsor: ProductType, index: number) => ({
      ...sponsor,
      key: index + 1,
      index: index + 1
    })
  )
