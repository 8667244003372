import { ExpandOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { useOverviewContext } from '@OverviewContext'
import { Button } from 'antd'

export const ExpandButton = () => {
  const { boothVisitsActivityExpanded, setBoothVisitsActivityExpanded } = useOverviewContext()

  const _toggleExpand = () => {
    setBoothVisitsActivityExpanded(!boothVisitsActivityExpanded)
  }

  return (
    <StyledExpandButton icon={<ExpandOutlined/>} onClick={_toggleExpand} data-test={'expand-button'} />
  )
}

const StyledExpandButton = styled(Button)({
}, ({ theme }) => ({
  color: `${theme.colors.lightGrayText} !important`,
  position: 'absolute',
  zIndex: 1,
  right: 164,
  top: 16,
  paddingTop: 4,
  '&:hover': {
    color: `${theme.colors.primary} !important`
  }
}))
