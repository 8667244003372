import styled from '@emotion/styled'
import useSponsor from '@Hooks/useSponsor'
import { useMediaContext } from '@MediaContext'
import { Drawer, Tabs } from 'antd'
import { prop, sortBy } from 'ramda'

const SideBarDrawer = () => {
  const { sideBarPreviewVisible, setSideBarPreviewVisible } = useMediaContext()

  const _onClose = () => {
    setSideBarPreviewVisible(false)
  }
  const { sponsor } = useSponsor()

  const tabs = sortBy(prop('position'))(sponsor?.tabs ?? [])

  if (!sponsor || !sideBarPreviewVisible) {
    return null
  }

  return (
    <StyledDrawer
      title={'Preview Side Bar'} placement={'right'} onClose={_onClose}
      visible={sideBarPreviewVisible}
    >
      <Tabs>
        {tabs.map((tab) => (
          <Tabs.TabPane key={tab.id} tab={tab.title}>
            <iframe
              src={tab.url}
              height={'100%'}
              width ={'100%'}
              allow={'accelerometer; encrypted-media; gyroscope; picture-in-picture; camera; microphone; display-capture; autoplay; fullscreen'}
              allowFullScreen
              frameBorder={0}
              title={tab.title}
            />
          </Tabs.TabPane>
        ))}

      </Tabs>
    </StyledDrawer>
  )
}

export default SideBarDrawer
const StyledDrawer = styled(Drawer)({
  '.ant-drawer-header': {
    height: 64
  },
  '.ant-tabs-nav': {
    margin: '0 24px',
    height: 46
  },
  '.ant-drawer-content-wrapper': {
    width: '400px !important'
  },
  '.ant-drawer-body': {
    padding: 0
  },
  '.ant-tabs-content': {
    height: 'calc(100vh - 110px)'
  }
})
