import { queryCache } from 'react-query'
import { useParams } from 'react-router-dom'
import { sponsorToggleUsesMeetAtBoothQuery } from '@EntitySauce/Queries'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import useSponsorStatistics from '@Hooks/useSponsorStatistics/useSponsorStatistics'
import { StyledMargin } from '@Reusable/Styled'
import AlertToggle from '@SponsorCommon/AlertToggle'
import { useSponsorContext } from '@SponsorContext'
import { entitySauce } from '@State'
import { message } from 'antd'
import { EventParamsTypes } from 'src/Types'

import MeetingsSummaryTable from './MeetingsSummaryTable'
import MeetingsTable from './MeetingsTable'

const validateValue = (value: unknown) => {
  if (typeof value === 'number' && !isNaN(value)) {
    return value
  }
  return ''
}

function Meetings(): JSX.Element {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { sponsorMeetings, meetingSummary, isFetching } = useSponsorStatistics()

  const data = meetingSummary.map((stat: { total: unknown }) => {
    return { ...stat, total: validateValue(stat.total) }
  })

  const { sponsorState } = useSponsorContext()

  const invalidateSponsorCache = () => queryCache.invalidateQueries('sponsor')

  const [toggleUsesMeetAtBooth, { isLoading: isTogglingMeetAtBooth }] = entitySauce.usePost(sponsorToggleUsesMeetAtBoothQuery(eventId, sponsorId), {
    onSuccess: () => {
      message.success('Changes saved successfully.')
      invalidateSponsorCache()
    },
    onError: () => {
      message.error('Couldn\'t save the changes. Please try again.')
    }
  })

  const { selectedEvent: event } = useSelectedEvent()
  const eventAllowsMeetAtBooth = event?.allowsMeetAtBooth
  return (
    <>
      {sponsorState.category.allowsMeetAtBooth && eventAllowsMeetAtBooth &&
      <AlertToggle
        title={'Meet-at-booth'}
        description={'Your representatives can use the booth for networking meetings.'}
        enabled={sponsorState?.usesMeetAtBooth}
        onChange={ toggleUsesMeetAtBooth}
        loading={isTogglingMeetAtBooth}
        testId={'meet-at-booth'}
      />
      }
      <MeetingsSummaryTable data={data} loading={isFetching} />
      <StyledMargin margin={'16px 0'}/>
      <MeetingsTable sponsorMeetings={sponsorMeetings} loading={isFetching} />
    </>
  )
}

Meetings.routeConfig = {
  path: '/meetings'
}

export default Meetings
