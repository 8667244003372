import { useParams } from 'react-router-dom'
import { productsQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'

import { EventParamsTypes } from '../Types'

const useSponsorProducts = () => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { data, ...rest } = entitySauce.useGet(
    productsQuery(eventId, sponsorId),
    { refetchOnWindowFocus: false, enabled: !!eventId && !!sponsorId }
  )

  return {
    products: data?.data ?? [],
    ...rest
  }
}

export default useSponsorProducts
