import { sponsorBoothsQuery } from '@EntitySauce/Queries'
import { BoothEvent, ResponseType } from '@Hooks/Types'
import { entitySauce } from '@State'
import { pick } from 'ramda'

type UseEventsType = ResponseType & {
  events: BoothEvent[]
}

const useEvents = (): UseEventsType => {
  const { data: sponsorBooths, ...rest } = entitySauce.useGet(
    sponsorBoothsQuery(),
    { staleTime: 50000, refetchOnWindowFocus: false }
  )

  const eventsFromBooth = (sponsorBooths?.data ?? []).map((booth: { event: { id: string | number }, id: string | number }) => {
    const filteredBooth = pick(Object.keys(booth).filter((key) => key !== 'event'), booth)
    return { ...booth.event, sponsor: filteredBooth, key: booth.id }
  })

  return { events: eventsFromBooth, ...rest }
}

export default useEvents
