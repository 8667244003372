import { ReactNode } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { AUTH_STATE } from '../Auth/Constants'
import { useAuth } from '../Auth/useAuth'

interface PrivateRouteProps extends RouteProps {
  children?: ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const auth = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.authState === AUTH_STATE.AUTHENTICATED
          ? (
            children || null
          )
          : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          )
      }
    />
  )
}

export default PrivateRoute
