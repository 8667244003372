export enum actionTypes {
  SET_INITIAL_VALUES = 'setInitialValues',
  SET_TITLE = 'setTitle',
  SET_LINK = 'setLink',
  SET_DESCRIPTION = 'setDescription',
  SET_IMAGE_URL = 'setImageUrl',
  SET_FIELDS_TOUCHED = 'setFieldsTouched'
}

export type ProductType = {
  id?: number | string,
  title?: string,
  description?: string,
  url?: string,
  imageUrl?: string,
  fieldsTouched?: boolean
}

export type ProductActionType =
 { type:
 actionTypes.SET_TITLE
 | actionTypes.SET_LINK
 | actionTypes.SET_DESCRIPTION
 | actionTypes.SET_IMAGE_URL, payload: string }
 | { type: actionTypes.SET_INITIAL_VALUES, payload: ProductType }
 | { type: actionTypes.SET_FIELDS_TOUCHED, payload: boolean }

export enum ProductModalType {
  NEW = 'new',
  EDIT = 'edit'
}
