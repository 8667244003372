import styled from '@emotion/styled'
import EmailImage from '@SVG/Auth/email.svg'
import GoogleLogo from '@SVG/Auth/google-logo.svg'
import { Button } from 'antd'
import { paramCase } from 'param-case'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'

const LoginProvider = {
  google: 'Google',
  email: 'Email'
}

const ProviderBgColor = {
  google: '#FDEBEB',
  email: '#F5F5F5'
}

function LoginButtonWide({ provider, logo, disabled, onSubmit, ...rest }) {
  return (
    <WideButton
      className={'no-margin'}
      onClick={() => onSubmit(provider)}
      type={'default'}
      size={'large'}
      disabled={disabled}
      data-test={`${paramCase(LoginProvider[provider])}-login-button`}
      {...rest}
    >
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>
      {`Continue with ${LoginProvider[provider]}`}
    </WideButton>
  )
}

function LoginButtonRound({ provider, logo, disabled, onSubmit, ...rest }) {
  return (
    <RoundButton
      className={'no-margin'}
      onClick={() => onSubmit(provider)}
      shape={'circle'}
      size={'large'}
      type={'primary'}
      disabled={disabled}
      data-test={`${paramCase(LoginProvider[provider])}-login-button`}
      bg={ProviderBgColor[provider]}
      {...rest}
    >
      <Logo src={logo} />
    </RoundButton>
  )
}

LoginButtonWide.propTypes = {
  provider: PropTypes.oneOf(Object.keys(LoginProvider)),
  logo: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}

LoginButtonRound.propTypes = {
  provider: PropTypes.oneOf(Object.keys(LoginProvider)),
  logo: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}

function withProvider(provider, logo) {
  return function(WrappedComponent) {
    return function WithProvider(props) {
      return (
        <WrappedComponent
          {...props}
          provider={provider}
          logo={logo}
        />
      )
    }
  }
}

const AuthButton = {
  Google: withProvider('google', GoogleLogo)(LoginButtonWide),
  Email: withProvider('email', EmailImage)(LoginButtonWide)
}

const WideButton = styled(Button)({
  width: '100%',
  marginBottom: '16px',
  textAlign: 'left!important',
  paddingLeft: '56px!important',
  height: '46px!important'
})

const RoundButton = styled(Button)((props) => ({
  textAlign: 'center',
  paddingLeft: '10px',
  paddingRight: '10px',
  background: `${props.bg}!important`,
  borderWidth: '0!important',
  boxShadow: '0px 0px 0px #000!important',

  ':hover': {
    background: `${tinycolor(props.bg).darken(5)}!important`
  },

  img: {
    maxWidth: '15px',
    maxHeight: '15px',
    top: '-1px',
    position: 'relative'
  }
}))

const LogoContainer = styled('div')({
  width: 18,
  height: 18,
  position: 'absolute',
  left: 20,
  top: 13,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const Logo = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%'
})

export default AuthButton
