/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { getFormattedDuration } from '@Helpers/DateTime'
import { truncate } from '@Lib/Util'
import { Table } from 'antd'
import { prop, sortBy } from 'ramda'
import { AnalyticsColWidthType, TrackingSessionSlicesType } from 'src/Sponsor/Modules/TrafficAnalytics/Analytics'
interface MediaUserTableProps {
  data: {
    trackingSessionSlices: TrackingSessionSlicesType
  }[] | undefined,
  expandedRowRender: any,
  colWidth: AnalyticsColWidthType
}

const MediaAnalyticsTable = ({ data, expandedRowRender, colWidth }: MediaUserTableProps) => {
  const _dataSource = React.useMemo(
    () => {
      const mediaData = (data || []).filter((data: any) => data.trackable?.trackableType === 'Media')
      return sortBy(prop('interactionCount'))(mediaData)
    },
    [data]
  )

  return (
    <Table
      className={'media-analyticsTable-table'}
      dataSource={_dataSource }
      pagination={false}
      rowKey={'id'}
      size={'small'}
      bordered
      scroll={{ x: 'max-content' }}
      expandedRowRender={expandedRowRender}
    >
      <Table.Column
        title={'Content Type'}
        key={'bodyType'}
        width={colWidth.contentType}
        dataIndex={['trackable', 'bodyType']}
      />
      <Table.Column
        title={'Content Title'}
        key={'title'}
        width={colWidth.contentTitle}
        dataIndex={['trackable', 'title']}
      />
      <Table.Column
        title={'Url'}
        key={'url'}
        width={colWidth.url}
        dataIndex={['trackable', 'url']}
        render={(url) => (
          <a
            target={'#blank'}
            href={url}
            title={url}
          >
            {truncate(url, 60)}
          </a>
        )}
      />

      <Table.Column
        title={'Content View Time'}
        key={'durationSeconds'}
        width={colWidth.contentViewTime}
        dataIndex={'durationSeconds'}
        render={(durationSeconds) => {
          return <span>{getFormattedDuration(durationSeconds)}</span>
        }}
      />
      <Table.Column
        key={'interactionCount'}
        width={colWidth.contentViewCount}
        dataIndex={'interactionCount'}
        title={'Content View Count'}
      />
    </Table>
  )
}

export default MediaAnalyticsTable
