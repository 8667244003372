import { headerDefinitions } from '@EntitySauce/Api/ApiHeaders'

const routes = {
  sponsorBooth: {
    get: {
      url: '/admin_panel/sponsor_self_service/sponsor_booths'
    }
  },
  sponsor: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId'
    },
    patch: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId'
    }
  },
  sponsorMedia: {
    get: {
      url: '/admin_panel/media'
    },
    post: {
      url: '/admin_panel/media'
    },
    patch: {
      url: '/admin_panel/media/:mediaId'
    },
    delete: {
      url: '/admin_panel/media/:mediaId'
    }
  },
  sponsorStatistics: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/statistics'
    }
  },
  sponsorMeetings: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/meetings'
    },
    meta: {
      actual_collection: 'adminPanelMeeting',
      actualCollection: 'adminPanelMeeting'
    }
  },
  eventStatistics: {
    get: {
      url: '/admin_panel/events/:eventId/stats'
    }
  },
  eventAttendees: {
    get: {
      url: '/admin_panel/events/:eventId/attendees'
    },
    delete: {
      url: '/admin_panel/events/:eventId/attendees/:attendeeId'
    },
    meta: {
      actual_collection: 'adminPanelAttendee',
      actualCollection: 'adminPanelAttendee'
    }
  },
  visitor: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/visitors',
      header: headerDefinitions.v4
    }
  },
  sponsorVisitorStatistics: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/visitors/statistics'
    }
  },
  sponsorVisitorStatisticsDay: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/visitors/per_day'
    }
  },
  visitorsExport: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/visitors/export'
    },
    meta: {
      actual_collection: 'visitor',
      actualCollection: 'visitor'
    }
  },
  sponsorAttendees: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/attendees'
    },
    post: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/attendees'
    },
    delete: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/attendees/:attendeeId'
    },
    meta: {
      actual_collection: 'adminPanelAttendee',
      actualCollection: 'adminPanelAttendee'
    }
  },
  eventSummaryExport: {
    get: {
      url: '/admin_panel/export_event_summary'
    }
  },
  meUser: {
    get: {
      url: '/me/user'
    },
    post: {
      url: '/me/user'
    }
  },
  acceptSponsorTerms: {
    post: {
      url: '/me/user/accept_sponsor_terms'
    }
  },
  sponsorAdmins: {
    patch: {
      url: '/admin_panel/sponsor_self_service/sponsor_admins/?:token'
    }
  },
  lead: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/leads'
    },
    patch: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/leads/:id'
    },
    delete: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/leads/:id'
    }
  },
  products: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/products'
    },
    post: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/products'
    },
    patch: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/products/:id'
    },
    delete: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/products/?:id/?:extra'
    }
  },
  sponsorToggleUsesMeetAtBooth: {
    post: {
      url: '/admin_panel/sponsor_self_service/sponsors/:sponsorId/toggle_meet_at_booth'
    }
  },
  downloads: {
    get: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/downloads/?:downloadId'
    },
    post: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/downloads/?:downloadId/?:toggle'
    },
    patch: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/downloads/:downloadId'
    },
    delete: {
      url: '/admin_panel/events/:eventId/sponsors/:sponsorId/downloads/:downloadId'
    }
  }
}

export default routes
