/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext } from 'react'

export type SponsorContextType = {
  virtualBoothFieldsTouched: boolean,
  setVirtualBoothFieldsTouched: (loggedIn: boolean) => void,
  sponsorState: any,
  setSponsorState: any,
  updateSponsorState: (field: string, sponsorState: any) => void,
  eventAttendees: any,
  setEventAttendees: (eventAttendees: any) => void,
  updatingCarouselDeck: boolean,
  setUpdatingCarouselDeck: (updatingCarouselDeck: boolean) => void,
  sponsorTabsSortTouched: boolean,
  setSponsorTabsSortTouched: (sponsorTabsSortTouched: boolean) => void

}

export const SponsorContext = createContext<SponsorContextType>({
  virtualBoothFieldsTouched: false,
  setVirtualBoothFieldsTouched: () => {},
  sponsorState: {},
  setSponsorState: {},
  updateSponsorState: () => {},
  eventAttendees: [],
  setEventAttendees: () => {},
  updatingCarouselDeck: false,
  setUpdatingCarouselDeck: () => {},
  sponsorTabsSortTouched: false,
  setSponsorTabsSortTouched: () => {}
})

export const useSponsorContext = ():SponsorContextType => useContext(SponsorContext)
