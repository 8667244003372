import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { StyledMargin } from '@Reusable/Styled'

import Downloads from '../Modules/Downloads'
import Leads from '../Modules/Leads'
import Meetings from '../Modules/Meetings'
import Overview from '../Modules/Overview'
import Products from '../Modules/Products'
import Representatives from '../Modules/Representatives'
import TrafficAnalytics from '../Modules/TrafficAnalytics'
import VirtualBooth from '../Modules/VirtualBooth'

interface SponsorNavRoutesProps {
  usesAnalytics: boolean,
  usesLeadScanning: boolean
}

const SponsorNavRoutes = ({ usesAnalytics, usesLeadScanning }: SponsorNavRoutesProps) => {
  const match = useRouteMatch()
  const defaultPath = Overview
  return (
    <StyledMargin margin={'24px'}>
      <Switch>
        <Route path={`${match.path}${Overview.routeConfig.path}`}>
          <Overview />
        </Route>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}${defaultPath.routeConfig.path}`} />
        </Route>
        <Route path={`${match.path}${VirtualBooth.routeConfig.path}`}>
          <VirtualBooth />
        </Route>
        <Route path={`${match.path}${Representatives.routeConfig.path}`}>
          <Representatives />
        </Route>
        <Route path={`${match.path}${Downloads.routeConfig.path}`}>
          <Downloads />
        </Route>
        <Route path={`${match.path}${Products.routeConfig.path}`}>
          <Products />
        </Route>
        <Route path={`${match.path}${Meetings.routeConfig.path}`}>
          <Meetings />
        </Route>
        {
          usesLeadScanning &&
          <Route path={`${match.path}${Leads.routeConfig.path}`}>
            <Leads />
          </Route>
        }
        {usesAnalytics &&
        <>
          <Route path={`${match.path}${TrafficAnalytics.routeConfig.path}`}>
            <TrafficAnalytics />
          </Route>
        </>
        }
      </Switch>
    </StyledMargin>
  )
}

export default SponsorNavRoutes
