import React from 'react'
import { getFormattedDuration } from '@Helpers/DateTime'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import { Table } from 'antd'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'
import { prop, sortBy } from 'ramda'
import { TimeColWidthType } from 'src/Sponsor/Modules/TrafficAnalytics/Analytics'

interface MediaTimeTableProps {
  data: unknown[],
  timeFormat: string,
  colWidth: TimeColWidthType
}

const MediaTimeTable = ({ data, timeFormat, colWidth }: MediaTimeTableProps) => {
  const _dataSource = React.useMemo(
    () => {
      if (Array.isArray(data)) {
        return data.filter((entry: { trackingType: string }) => {
          return entry.trackingType === 'time_spent'
        })
      }
    },
    [data]
  )

  const { selectedEvent: event } = useSelectedEvent()

  const sortedData = sortBy(prop('startDate'))(_dataSource || [])

  return (
    <Table
      className={'media-time-table'}
      dataSource={sortedData}
      rowKey={'id'}
      bordered
      size={'small'}
      scroll={{ x: 'max-content' }}
      pagination={{
        total: _dataSource?.length ?? 0, // Local pagination
        pageSize: 20
      }}
    >
      <Table.Column
        title={'Start Time'}
        key={'startTime'}
        width={colWidth.startTime}
        dataIndex={'startTime'}
        render={(startTime) => {
          return <span>{moment.utc(startTime).tz(event?.timeZone)?.format(`YYYY-MM-DD ${timeFormat}`)}</span>
        }}
      />
      <Table.Column
        title={'End Time'}
        key={'endTime'}
        width={colWidth.endTime}
        dataIndex={'endTime'}
        render={(endTime) => {
          if (!endTime) return
          return <span>{moment.utc(endTime).tz(event?.timeZone)?.format(`YYYY-MM-DD ${timeFormat}`)}</span>
        }}
      />
      <Table.Column
        title={'Duration'}
        key={'durationSeconds'}
        width={colWidth.contentViewDuration}
        dataIndex={'durationSeconds'}
        render={(durationSeconds) => {
          return <span>{getFormattedDuration(durationSeconds)}</span>
        }}
      />
    </Table>
  )
}

export default MediaTimeTable
