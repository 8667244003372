import { useEffect } from 'react'
import AuthService from '@Auth'
import FullScreenLoader from '@Reusable/FullScreenLoader/FullScreenLoader'

/**
 * Safe place for user to wait for log out and cache purge.
 * Do not attach redux selectors to this.
 */
const LogoutContainer = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      AuthService.clearAll()
      window.location.href = '/login'
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <FullScreenLoader text={'Logging out'} />
  )
}

export default LogoutContainer
