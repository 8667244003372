import styled from '@emotion/styled'
import { Pagination } from 'antd'

import { PaginationType } from './EventsTable'

interface TableFooterProps {
  totalCount: number,
  setPagination: (pagination: PaginationType) => void,
  showSizeChanger: boolean
}

const TableFooter = ({ totalCount, setPagination, showSizeChanger }: TableFooterProps) => {
  const _onTableChange = (current: number, pageSize: number | undefined) => {
    setPagination({ current, pageSize })
    window.scrollTo(0, 0)
  }

  return (
    <TableFooterContainer>
      {
        totalCount > 0 &&
      <Pagination
        data-test={'pagination'}
        total={totalCount}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        showSizeChanger={showSizeChanger}
        pageSizeOptions={['10', '20', '30', '60', '90', '120']}
        onChange={_onTableChange}
      />
      }
    </TableFooterContainer>

  )
}

export default TableFooter

const TableFooterContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 30

})
