import { useEffect, useRef, useState } from 'react'

const useIntersect = ({ onInitialIntersect, onChange, options }) => {
  const ref = useRef()
  const [isSeen, setIsSeen] = useState(false)

  const _handleIntersect = ([entry]) => {
    if (!isSeen && entry.isIntersecting) {
      onInitialIntersect && onInitialIntersect()
      setIsSeen(true)
    }

    onChange && onChange(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(_handleIntersect, options)
    if (ref.current) observer.observe(ref.current)

    return () => {
      observer && observer.disconnect()
    }
  })

  return [ref]
}

export default useIntersect
