import React from 'react'
import { faCommentDots } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '@Theme'
import { Button } from 'antd'

import useSupportChat from './useSupportChat'

function IntercomChat() {
  const { show } = useSupportChat()

  return (
    <Button
      icon={(
        <FontAwesomeIcon
          icon={faCommentDots}
          className={'anticon'}
          style={{ fontSize: '20px', color: theme.colors.primary }}
        />
      )}
      onClick={show}
      type={'text'}
    >
      {'Support'}
    </Button>
  )
}

export default IntercomChat
