import { Button as AntdButton } from 'antd'

import { ButtonSizes, ButtonTargets, ButtonTypes } from './Types'

interface ButtonProps {
  block?: boolean,
  danger?: boolean,
  label?: string,
  href?: string,
  disabled?: boolean,
  type?: ButtonTypes,
  target?: ButtonTargets,
  loading?: boolean | { delay: number },
  onClick?: () => void,
  htmlType?: 'submit' | 'button' | 'reset' | undefined,
  children?: React.ReactNode,
  className?: string,
  id?: string,
  onKeyPress?: (event: React.KeyboardEvent) => void,
  icon?: React.ReactNode,
  style?: React.CSSProperties,
  size?: ButtonSizes
}

const Button = ({
  block = false,
  danger = false,
  loading = false,
  label,
  type,
  disabled,
  href,
  target,
  onClick,
  htmlType,
  children,
  className,
  id,
  onKeyPress,
  style,
  icon,
  size
}: ButtonProps) => {
  if (type === ButtonTypes.Link && !href) {
    throw new Error('Button with type link requires the use of the href prop')
  }
  if (!(htmlType === 'submit' || onClick)) {
    throw new Error(
      'Button must have an onClick function or Type === \'submit\''
    )
  }
  return (
    <AntdButton
      style={style}
      block={block}
      danger={danger}
      loading={loading}
      type={type || ButtonTypes.Primary}
      disabled={!!disabled}
      href={href}
      target={target || ButtonTargets.Self}
      onClick={onClick}
      htmlType={htmlType}
      className={className}
      id={id}
      onKeyPress={onKeyPress}
      icon={icon}
      size={size}
    >
      {label || children}
    </AntdButton>
  )
}

export default Button
