import {
  Redirect,
  Route,
  Switch,
  useRouteMatch
} from 'react-router-dom'

import Sponsor from '../../Sponsor/Sponsor'

function Event(): JSX.Element {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={match.path}>
        <Redirect to={'/'} />
      </Route>
      <Route exact path={`${match.path}/sponsor`}>
        <Redirect to={'/'} />
      </Route>
      <Route path={`${match.path}/sponsor/:sponsorId`}>
        <Sponsor />
      </Route>
    </Switch>
  )
}

Event.routeConfig = {
  path: '/events/:eventId'
}

export default Event
