import styled from '@emotion/styled'
import { Empty } from 'antd'

const EmptyPlaceHolder = () => {
  return (
    <EmptyPlaceHolderContainer>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={'No data'}
      />
    </EmptyPlaceHolderContainer>
  )
}

export default EmptyPlaceHolder

const EmptyPlaceHolderContainer = styled('div')({
  background: '#F5F5F5',
  padding: '15px 0 10px',
  borderRadius: 8,
  '.ant-empty-normal': {
    margin: 0
  }
})
