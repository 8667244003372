/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from 'react-router-dom'
import { sponsorAttendeesQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'

import { EventParamsTypes } from '../Types'

const useSponsorAttendees = () => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { data, ...rest } = entitySauce.useGet(
    sponsorAttendeesQuery(eventId, sponsorId),
    { staleTime: 50000, refetchOnWindowFocus: false, enabled: !!eventId && !!sponsorId }
  )
  const sponsorAttendees = data?.data ?? []
  const attendees = (sponsorAttendees || []).map((attendee: any) => {
    return (
      { ...attendee, ...attendee?.user }
    )
  })
  return { attendees, ...rest }
}

export default useSponsorAttendees
