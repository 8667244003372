import { PlayCircleFilled } from '@ant-design/icons'
import styled from '@emotion/styled'
import { StyledFlex, StyledSubtitleText } from '@Reusable/Styled'
import { useSponsorContext } from '@SponsorContext'
import { Card } from 'antd'
import { paramCase } from 'param-case'

import CompanyDetailsTabsNav from './CompanyDetails/CompanyDetailsTabsNav'
import SponsorCarousel from './CompanyDetails/SponsorCarousel'

const formatLink = (link: string, type: string) => {
  if (type === 'Twitter') {
    link = `https://twitter.com/${link.replace(/^@/, '')}`
  }

  if (type === 'Instagram') {
    link = `https://instagram.com/${link.replace(/^@/, '')}`
  }

  return link
}

const VirtualBoothPreview = () => {
  const { sponsorState, updatingCarouselDeck } = useSponsorContext()
  const { name, website, facebook, linkedin, twitter, instagram, category, logoUrl, deckMedia } = sponsorState || {}

  const socialLinks = [
    { label: 'Website', link: website },
    { label: 'Facebook', link: facebook },
    { label: 'LinkedIn', link: linkedin },
    { label: 'Twitter', link: twitter },
    { label: 'Instagram', link: instagram }
  ]

  const categoryName = category?.name

  return (
    <Wrapper>
      <StyledSubtitleText strong margin={'0 0 30px'} data-test={'preview-title'}>
        {'Preview booth'}
      </StyledSubtitleText>
      <FormPreviewCardWrapper>
        <Card data-test={'preview-card'}>
          <StyledFlex style={{ minHeight: 92 }}>
            <div>
              <StyledImagePreview
                key={'preview'}
                className={'logo-form-preview'}
                data-test={'preview-logo'}
                logoUrl={logoUrl}
              />
            </div>
            <StyledInfo>
              <StyledFlex justifyContent={'space-between'}>
                <div data-test={'preview-name'} className={'name'}>{name}</div>
                {categoryName && <StyledCategory data-test={'preview-category'}>{categoryName}</StyledCategory>}
              </StyledFlex>
              <div data-test={'preview-subtitle'} className={'subtitle'}>{sponsorState?.subtitle?.slice(0, 75)}</div>
              {socialLinks.map((item) => {
                if (!item.link) { return null }
                return (
                  <a
                    href={`${formatLink(item.link, item.label)}`} target={'#blank'} key={item.label}
                    data-test={`social-link-${paramCase(item.label)}`}
                    className={'social-link'}
                  >
                    {item.label}
                  </a>
                )
              })}
            </StyledInfo>
          </StyledFlex>

          <StyledSponsorCarouselWrapper>
            {deckMedia?.length && !updatingCarouselDeck
              ? <SponsorCarousel items={deckMedia || []} />
              : <div className={'placeholder'}>
                <PlayCircleFilled />
              </div>
            }
          </StyledSponsorCarouselWrapper>

          <CompanyDetailsTabsNav />
        </Card>
      </FormPreviewCardWrapper>
    </Wrapper>
  )
}

export default VirtualBoothPreview

const Wrapper = styled('div')({
  height: '100%'
})

type StyledImagePreviewProps = {
  logoUrl: string
}
const StyledImagePreview = styled('div')<StyledImagePreviewProps>({
}, (props) => ({
  height: 70,
  width: 70,
  borderRadius: 35,
  marginRight: 24,
  backgroundColor: props.logoUrl ? 'transparent' : `${props.theme.colors.previewPlaceHolderBackground}`,
  border: `1px solid ${props.theme.colors.previewPlaceHolderBackground}`,
  backgroundImage: `url(${props.logoUrl})`
}))

const StyledInfo = styled('div')({
}, (props) => ({
  textTransform: 'capitalize',
  width: '100%',
  '.name': {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '28px',
    color: props.theme.colors.title
  },
  '.subtitle': {
    fontSize: 14,
    lineHeight: '22px',
    color: props.theme.colors.secondaryText,
    marginRight: 65
  },
  '.social-link': {
    fontSize: 14,
    lineHeight: '22px',
    color: props.theme.colors.title,
    textDecorationLine: 'underline',
    marginRight: 12
  }
}))

const StyledSponsorCarouselWrapper = styled('div')({
}, (props) => ({
  width: '100%',
  borderRadius: 8,
  marginTop: 24,
  '.placeholder': {
    minHeight: 400,
    backgroundColor: props.theme.colors.lightGrayBackground,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '>span >svg': {
      width: 48,
      height: 48,
      color: '#E5E8EB'
    }
  }
}))

const StyledCategory = styled('div')({
}, (props) => ({
  backgroundColor: props.theme.colors.categoryTagBackground,
  borderRadius: 6,
  textAlign: 'center',
  padding: '2px 8px'
}))

export const FormPreviewCardWrapper = styled('div')({
}, (props) => ({
  backgroundColor: props.theme.colors.previewBackground,
  borderRadius: 8,
  padding: 32,
  height: 'calc(100% - 76px)'
}))
