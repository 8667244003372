/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'

export const DEFAULT_DATE_RANGE:[moment.Moment, moment.Moment] = [
  moment().subtract(7, 'days'),
  moment().add(7, 'days')
]

export type OverviewContextType = {
  boothVisitsActivityExpanded: boolean,
  setBoothVisitsActivityExpanded: (expanded: boolean) => void,
  boothVisitsActivityDateRange: [moment.Moment, moment.Moment],
  setBoothVisitsActivityDateRange: (dateRange: [moment.Moment, moment.Moment]) => void
}

export const OverviewContext = createContext<OverviewContextType>({
  boothVisitsActivityExpanded: false,
  setBoothVisitsActivityExpanded: () => {},
  boothVisitsActivityDateRange: DEFAULT_DATE_RANGE,
  setBoothVisitsActivityDateRange: () => {}
})

export const useOverviewContext = ():OverviewContextType => useContext(OverviewContext)
