/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { sponsorMediaQuery } from '@EntitySauce/Queries'
import { useMediaContext } from '@MediaContext'
import CustomMessage from '@Reusable/CustomMessage'
import { StyledFlex } from '@Reusable/Styled'
import { useSponsorContext } from '@SponsorContext'
import { entitySauce } from '@State'
import DragSvg from '@SVG/drag.svg'
import { Button, Col, Popconfirm, Row } from 'antd'

import { MediaDeckType } from '../MediaComponents'

import { ModalStatus, ModalTypes } from './MediaModal'
import { StyledOverFlow } from './Styled'

interface DraggableItemProps {
  mediaDeck?: MediaDeckType,
  showModal: (status: ModalStatus) => void,
  refetchSponsor: any,
  type: ModalTypes
}

type columnType = {
  type: string,
  value: React.ReactNode,
  span: number,
  offset: number
}

const DraggableItem = ({ mediaDeck, showModal, refetchSponsor, type }: DraggableItemProps) => {
  const { setMediaState } = useMediaContext()
  const { setUpdatingCarouselDeck } = useSponsorContext()
  const [remove, { status: removeStatus }] = entitySauce.useRemove(
    sponsorMediaQuery(mediaDeck?.id)
  )

  const titleColumn = (
    <StyledFlex>
      <img style={{ marginRight: 40 }} src={DragSvg} />
      <StyledImageColumn>
        {mediaDeck?.bodyType === 'iframe'
          ? (
            <img
              src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${mediaDeck?.url}`}
              alt={'Favicon'}
            />
          )
          : (
            <img
              src={mediaDeck?.url}
              width={30}
              alt={`for ${mediaDeck?.title}`}
            />
          )}
      </StyledImageColumn>
      <StyledTitleColumn>
        <div className={'title'}>{'Title'}</div>
        <StyledOverFlow>
          {mediaDeck?.title?.slice(0, 18)}
          {(mediaDeck?.title?.length ?? 0) > 18 ? '...' : ''}
        </StyledOverFlow>
      </StyledTitleColumn>
    </StyledFlex>
  )

  const urlColumn = (
    <StyledUrlColumn >
      <div className={'title'}>{'URL'}</div>
      <StyledOverFlow><a target={'_blank'} href={mediaDeck?.url} rel={'noreferrer'}>{mediaDeck?.url}</a></StyledOverFlow>
    </StyledUrlColumn>
  )

  const handleEditOpen = () => {
    showModal(ModalStatus.EDIT)
    setMediaState({ mediaDeckItem: mediaDeck })
  }

  const handleDelete = async(id: number | string | undefined) => {
    setUpdatingCarouselDeck(true)
    await remove({ id })
    await refetchSponsor()
    setUpdatingCarouselDeck(false)
  }
  const editButton = (
    <StyledCustomButton
      type={'link'} onClick={handleEditOpen} customtype={CustomButtonType.EDIT}
      data-test={`edit-${type}-button`}
    >
      <EditOutlined />
    </StyledCustomButton>
  )

  const deleteButton = (
    <StyledCustomButton type={'link'} customtype={CustomButtonType.DELETE} data-test={`delete-${type}-button`}>
      <DeleteOutlined />
    </StyledCustomButton>
  )
  const confirmDeleteButton = (
    <Popconfirm
      placement={'topLeft'}
      key={'cancel'}
      title={'Are you sure you want to remove this item?'}
      onConfirm={() => handleDelete(mediaDeck?.id)}
      icon={<QuestionCircleOutlined />}
    >
      {deleteButton}
    </Popconfirm>
  )

  const actionsColumn = (
    <StyledActionsColumn>
      {editButton}
      {confirmDeleteButton}
    </StyledActionsColumn>
  )

  const columns = [
    {
      type: 'title',
      value: titleColumn,
      span: 5,
      offset: 0
    },
    {
      type: 'url',
      value: urlColumn,
      span: 10,
      offset: 3
    },
    {
      type: 'edit',
      value: actionsColumn,
      span: 4,
      offset: 2
    }
  ]

  return (
    <DraggableItemContainer data-test={'draggable-media-item'}>
      {removeStatus &&
      <CustomMessage
        status={removeStatus}
        errorMessage={'Failed to remove item. Please try again.'}
        successMessage={'Item removed successfully'}
      />
      }
      <Row >
        {columns.map((column: columnType) => {
          return (
            <Col
              key={column.type}
              className={column.type}
              span={column.span}
              offset={column.offset}
            >
              {column.value}
            </Col>
          )
        })}
      </Row>
    </DraggableItemContainer>
  )
}

export default DraggableItem

const DraggableItemContainer = styled('div')({
  borderRadius: 8,
  marginBottom: 8,
  padding: '0 24px',
  height: 70,
  display: 'flex',
  '>div': {
    width: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    '.edit': {
      justifyContent: 'flex-end',
      display: 'flex'
    }
  }
})

enum CustomButtonType {
  DELETE = 'delete',
  EDIT = 'edit'
}

type StyledCustomButtonProps = {
  customtype: CustomButtonType
}
const StyledCustomButton = styled(Button)<StyledCustomButtonProps>({
}, (props) => ({
  marginLeft: 12,
  color: `${props.customtype === CustomButtonType.DELETE ? '#ff4d4f' : props.theme.colors.primary} !important`,
  '&:hover': {
    opacity: 0.7
  },
  '>span >svg': {
    width: 18,
    height: 18
  }
}))

const StyledTitleColumn = styled('div')({
}, (props) => ({
  paddingTop: 10,
  '.title': {
    fontWeight: 600,
    color: props.theme.colors.title,
    width: 'fit-content'
  }
}))

const StyledUrlColumn = styled(StyledTitleColumn)``
const StyledImageColumn = styled('div')({
  marginRight: 20,
  width: 50
})
const StyledActionsColumn = styled(StyledFlex)({
  paddingTop: 10
})
