export enum actionTypes {
  SET_TITLE = 'setTitle',
  SET_DESCRIPTION = 'setDescription',
  SET_URL = 'setURL',
  SET_ACTIVE = 'setActive'
}

export enum FileInsertMethods {
  UPLOAD = 'file',
  URL = 'url'
}

export enum DownloadsFormType {
  NEW = 'new',
  EDIT = 'edit'
}

export type DownloadItemType = {
  id: string,
  title: string,
  description: string,
  attachmentType: string,
  attachmentUrl: string,
  fileUrl: string,
  enabled: boolean,
  position: number
}

export type DownloadItemActionType =
 {
   type:
   actionTypes.SET_TITLE |
   actionTypes.SET_DESCRIPTION |
   actionTypes.SET_URL,
   payload: string
 }
 | { type: actionTypes.SET_ACTIVE, payload: boolean }

export type FormValues = {
  title: string,
  description: string,
  // eslint-disable-next-line camelcase
  attachment_url: string,
  // eslint-disable-next-line camelcase
  attachment_type: string
}
