import { matchPath } from 'react-router'
import snakeCase from 'lodash.snakecase'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { defaultTo, head, keys, path, pipe, sort as Rsort } from 'ramda'

// Utility functions

/**
 * Truncates string to max length & gives ellipsis
 * @param   {String}     text        String to truncate
 * @param   {Number}     maxLength   Maximum length of string
 * @return  {String}                 Truncated text with ellipsis
 */
export const truncate = (text, maxLength, showEllipsis = true) => {
  const ellipsis = '…'
  let result = text

  if (!text) return ellipsis

  if (text.length > maxLength) {
    result = text.substr(0, maxLength)

    if (showEllipsis) {
      result = result + ellipsis
    }
  }

  return result
}

export const getBase64Image = (image, callback) => {
  const reader = new window.FileReader()

  reader.addEventListener(
    'load',
    () => callback(reader.result),
    false
  )

  if (image) {
    reader.readAsDataURL(image)
  }
}

export const isBase64String = (entry) => {
  if (!entry) {
    return false
  }

  return /^data:.*\/.*;base64,/.test(entry.substring(0, 50))
}

export const getQueryParams = (search) => {
  return queryString.parse(search)
}

export const stringifyQueryParams = (obj) => {
  return queryString.stringify(obj)
}

export const stringSorter = (a, b, key) => {
  return defaultTo('', String(a[key])).localeCompare(defaultTo('', String(b[key])))
}

export const numberSorter = (a, b, key) => {
  return defaultTo(0, a[key]) - defaultTo(0, b[key])
}

/**
 * Debounces function calls
 * @param   {Function}   func        Function to call if not bounced
 * @param   {Number}     wait        Milliseconds to wait
 * @param   {Boolean}    immediate   Call immidiatly without bounce?
 */
export const debounce = (func, wait, immediate) => {
  let timeout

  return (...args) => {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      timeout = null

      if (!immediate) {
        func.apply(this, args)
      }
    }, wait)

    if (immediate && !timeout) {
      func.apply(this, [...args])
    }
  }
}

export const TO_CONSTANT_KEY = (string) => snakeCase(string).toUpperCase()

export const getEventIdFromPath = (path) => {
  const eventRoutes = ['/dashboard/events/:eventId']

  const match = matchPath(path, {
    path: eventRoutes,
    exact: false,
    strict: false
  })

  return match && match.params.eventId
}

export const getOrganizationIdFromPath = (path) => {
  const organizationRoutes = ['/dashboard/organizations/:organizationId']

  const match = matchPath(path, {
    path: organizationRoutes,
    exact: false,
    strict: false
  })

  return match && match.params.organizationId
}

export const rx = {
  substring: (string, regexp, or) => {
    const startsAt = string.search(regexp)

    if (startsAt === -1) return or !== undefined ? or : ''

    const substring = string.match(regexp)[0]

    return substring
  },
  startsWithSubstring: (string, startsWithRegexp, or) => {
    const startsAt = string.search(startsWithRegexp)

    if (startsAt !== 0) return or !== undefined ? or : ''

    const substring = string.match(startsWithRegexp)[0]

    return substring
  },
  matchAtOr: (object, objectPath, regExp, or) => {
    const string = path(objectPath, object)

    if (!object || !string || string.match(regExp) === null) return or !== undefined ? or : null

    return string.match(regExp)[0]
  }
}

export const sort = {
  asc: (arr) => {
    const sortAsc = (a, b) => String(a).localeCompare(String(b))

    return Rsort(sortAsc, arr)
  },
  desc: (arr) => {
    const sortDesc = (a, b) => String(b).localeCompare(String(a))

    return Rsort(sortDesc, arr)
  }
}

export const capitalizeFirstLetter = (str) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const matchPropTypes = PropTypes.shape({
  path: PropTypes.string,
  url: PropTypes.string
})

export const historyPropTypes = PropTypes.shape({
  push: PropTypes.func
})

export const setUriParameter = (history, attr, value) => {
  if (!history) return

  const current = history.location.search || ''

  const params = getQueryParams(current)

  const result = {
    ...params,
    [attr]: value
  }

  history.push(`?${stringifyQueryParams(result)}`)
}

export const scrollToFirstFormError = ({ errorFields }) => {
  if (!errorFields) return

  const takeFirstElementId = pipe(
    keys,
    head
  )

  document.getElementById(takeFirstElementId(errorFields)).scrollIntoView({ behavior: 'smooth', block: 'center' })
}

export const getEnvVariable = (variable) => {
  if (import.meta.env.VITE_COMMIT_REF === 'staging') {
    return import.meta.env[`${variable}_STAGING`]
  }

  return import.meta.env[variable]
}

export const removeAccentsFromString = (str) => {
  const accentMap = {
    from: 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;',
    to: 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
  }

  return str.split('')
    .map((char) => {
      const accentIndex = accentMap.from.indexOf(char)
      if (accentIndex === -1) return char
      return accentMap.to[accentIndex]
    })
    .join('')
}

export const slugify = (str) => {
  return removeAccentsFromString(str)
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

/**
 * Tells if Cypress running the app outside or inside the app.
 * Useful when the two test suites differ some much that we need to conditionally
 * do things.
 */
export const cypressRunner = {
  isExternal: window.Cypress?.originalConfig?.projectName === 'brella-e2e-automation',
  isInternal: window.Cypress?.originalConfig?.projectName === 'brella-manager'
}

export const isUsingCypress = window?.Cypress ?? false
