import { useEffect } from 'react'
import { entitySauce } from '@State'

interface ExportDataProps {
  query: unknown,
  handleExportStatus: (status: string) => void,
  handleFetching: (isFetching: boolean) => void
}

const ExportData: React.FC<ExportDataProps> = ({ query, handleExportStatus, handleFetching }) => {
  const { status: exportStatus, isFetching } = entitySauce.useGet(
    query
  )
  useEffect(() => {
    handleExportStatus(exportStatus)
    handleFetching(isFetching)
  }, [exportStatus, isFetching])

  return null
}

export default ExportData
