import useSponsorStatistics from '@Hooks/useSponsorStatistics/useSponsorStatistics'
import Card from '@Reusable/Card/Card'
import { StyledFlex } from '@Reusable/Styled'
import { Empty } from 'antd'

import ChartInfo from '../Reusable/ChartInfo'
import DonutChart from '../Reusable/DonutChart'

const _renderData = (
  sponsorStatistics: {
    acceptedMeetingsCount: number,
    pendingMeetingsCount: number,
    rescheduledMeetingsCount: number,
    cancelledMeetingsCount: number,
    declinedMeetingsWhereChatOpenCount: number,
    declinedMeetingsCount: number
  }
) => {
  const {
    acceptedMeetingsCount = 0,
    pendingMeetingsCount = 0,
    rescheduledMeetingsCount = 0,
    cancelledMeetingsCount = 0,
    declinedMeetingsWhereChatOpenCount = 0,
    declinedMeetingsCount = 0
  } = sponsorStatistics || {}
  return ([
    { key: 1, label: 'Accepted', value: acceptedMeetingsCount },
    { key: 2, label: 'Pending', value: pendingMeetingsCount },
    { key: 3, label: 'Rescheduled', value: rescheduledMeetingsCount, nested: 1 },
    { key: 4, label: 'Cancelled', value: cancelledMeetingsCount },
    { key: 5, label: 'Declined (chat left open)', value: declinedMeetingsWhereChatOpenCount },
    { key: 6, label: 'Declined (chat closed)', value: declinedMeetingsCount - declinedMeetingsWhereChatOpenCount }
  ])
}

const _renderOuterSeries = (totalMeetingsCount: number, acceptedMeetingsCount: number) => {
  return ({
    type: 'pie',
    color: ['#40A9FF', 'white'],
    radius: ['73%', '80%'],
    avoidLabelOverlap: false,
    label: {
      normal: {
        show: false
      }
    },
    labelLine: {
      show: true
    },
    data: [{ value: acceptedMeetingsCount, name: 'Accepted' },
      { value: totalMeetingsCount - acceptedMeetingsCount }]
  })
}

const TooltipNode = (
  <span>
    {'See total inbound and outbound meeting current status. '}
    <a href={'https://help.brella.io/en/sponsors/meeting-requests-chat-activity'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
  </span>
)

const MeetingRequestActivity = () => {
  const { sponsorStatistics, isFetching } = useSponsorStatistics()
  const {
    totalReceivedMeetingsCount,
    totalRequestedMeetingsCount,
    receivedAcceptedMeetingsCount,
    requestedAcceptedMeetingsCount,
    receivedPendingMeetingsCount,
    requestedPendingMeetingsCount,
    receivedRescheduledMeetingsCount,
    requestedRescheduledMeetingsCount,
    receivedCancelledMeetingsCount,
    requestedCancelledMeetingsCount,
    receivedDeclinedMeetingsCount,
    requestedDeclinedMeetingsCount,
    declinedMeetingsWhereChatOpenCount
  } = sponsorStatistics
  const totalMeetingsCount: number = totalReceivedMeetingsCount + totalRequestedMeetingsCount
  const acceptedMeetingsCount: number = receivedAcceptedMeetingsCount + requestedAcceptedMeetingsCount
  const pendingMeetingsCount: number = receivedPendingMeetingsCount + requestedPendingMeetingsCount
  const rescheduledMeetingsCount: number = receivedRescheduledMeetingsCount + requestedRescheduledMeetingsCount
  const cancelledMeetingsCount: number = receivedCancelledMeetingsCount + requestedCancelledMeetingsCount
  const declinedMeetingsCount: number = receivedDeclinedMeetingsCount + requestedDeclinedMeetingsCount

  return (
    <Card
      title={'Meeting request activity'}
      TooltipNode={TooltipNode}
      testId={'meeting-request-activity'}
      testTotal={`${totalMeetingsCount}`}
      paddingTop={0}
      paddingBottom={totalMeetingsCount ? 0 : ''}
      loading={isFetching}
      empty={!totalMeetingsCount}
    >
      {!totalMeetingsCount
        ? <Empty description={'Meeting request activity data is not available'}/>
        : <StyledFlex justifyContent={'space-between'}>
          <DonutChart
            type={'requests'}
            name= {'Meeting request activity'}
            data={[
              { value: totalRequestedMeetingsCount, name: 'Outbound' },
              { value: totalReceivedMeetingsCount, name: 'Inbound' }
            ]}
            colors={[
              '#00AF66',
              '#BB16A3'
            ]}
            width= {'50%'}
            outerSeries={_renderOuterSeries(totalMeetingsCount, acceptedMeetingsCount)}
          />
          <ChartInfo testId={'meeting-request-activity'} data={_renderData({ acceptedMeetingsCount, pendingMeetingsCount, rescheduledMeetingsCount, cancelledMeetingsCount, declinedMeetingsWhereChatOpenCount, declinedMeetingsCount })} width={'45%'}/>
        </StyledFlex>
      }
    </Card>
  )
}

export default MeetingRequestActivity
