import PropTypes from 'prop-types'

import useIntersect from '../../Hooks/useIntersect'

const IntersectionDetector = ({ onInitialIntersect, onChange, options, children }) => {
  const [ref] = useIntersect({ onInitialIntersect, onChange, options })

  return (
    <div ref={ref}>
      {children}
    </div>
  )
}

IntersectionDetector.propTypes = {
  children: PropTypes.node,
  onInitialIntersect: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.shape({
    root: PropTypes.element,
    rootMargin: PropTypes.string,
    threshold: PropTypes.number
  })
}

export default IntersectionDetector
