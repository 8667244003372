import { InfoCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import DataExport from '@Lib/DataExport/DataExport'
import { numberSorter } from '@Lib/Util'
import Card from '@Reusable/Card/Card'
import DownloadButton from '@Reusable/DownloadButton'
import { StyledLightInnerTooltip, StyledTable } from '@Reusable/Styled'
import theme from '@Theme'
import { message, Table, Tooltip } from 'antd'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'

const exportSchema = {
  '': 'key',
  Total: 'total',
  Inbound: 'inbound',
  Outbound: 'outbound'
}
interface MeetingsSummaryTableProps {
  data: Record<string, unknown>[] | undefined,
  loading: boolean
}

const MeetingsSummaryTable = ({ data, loading }: MeetingsSummaryTableProps) => {
  const _onExportError = (error: {message: string}) => {
    message.error(error.message)
  }

  const _TooltipNode = (
    <span>
      {'See list of representatives with inbound and outbound meetings. '}
      <a href={'https://help.brella.io/en/sponsors/understand-11-meetings'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
    </span>
  )

  return (
    <Card
      style={{ borderRadius: '0px 0px 8px 8px' }}
      data-test={'meetings-summary-card'}
      title={'Meetings and open chat summary'}
      TooltipNode={_TooltipNode}
      testId={'meetings-summary'}
      loading={loading}
      extra={
        <DownloadButton
          tableName={'meetings-summary'}
          filename={`meetings-summary-${moment().format('YYYY-MM-DD')}.csv`}
          data={data}
          exporter={DataExport.csv}
          exportSchema={exportSchema}
          onError={_onExportError}
        >
          {'Export (.csv)'}
        </DownloadButton>
      }
    >
      <StyledTable
        dataSource={data}
        rowKey={'key'}
        size={'small'}
        bordered
        scroll={{ x: 'max-content' }}
        pagination={false}
      >
        <Table.Column
          dataIndex={'key'}
          render={(value, record: {key: string, label?: string}) => {
            if (record?.label) {
              return record.label
            }
            if (record?.key?.toLowerCase() !== 'total messages') {
              return value
            }

            return (
              <ValueWithTooltip>
                {value}
                <Tooltip
                  placement={'topRight'}
                  title={<StyledLightInnerTooltip>{'Chat messages sent in both open chats and meetings.'}</StyledLightInnerTooltip>}
                  color={theme.colors.primaryBackground}
                >
                  <InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: 5 }}/>
                </Tooltip>
              </ValueWithTooltip>
            )
          }}
        />
        <Table.Column
          title={'Total'}
          dataIndex={'total'}
          sorter={(a, b) => numberSorter(a, b, 'total')}
        />
        <Table.Column
          title={'Inbound'}
          dataIndex={'inbound'}
          sorter={(a, b) => numberSorter(a, b, 'inbound')}
        />
        <Table.Column
          title={'Outbound'}
          dataIndex={'outbound'}
          sorter={(a, b) => numberSorter(a, b, 'outbound')}
        />
      </StyledTable>
    </Card>
  )
}

export default MeetingsSummaryTable

const ValueWithTooltip = styled.span`
  display: flex;
  align-items: center;
  > div {
    margin-left: 8px;
  }
`
