/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type CompanyDetailsTabsNavContextType = {
  attendeesLength: number,
  setAttendeesLength: (attendeesLength: number) => void
}

export const CompanyDetailsTabsNavContext = createContext<CompanyDetailsTabsNavContextType>({
  attendeesLength: 0,
  setAttendeesLength: () => {}
})

export const useCompanyDetailsTabsNavContext = ():CompanyDetailsTabsNavContextType => useContext(CompanyDetailsTabsNavContext)
