/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from 'react-router-dom'
import { visitorsQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'

import { EventParamsTypes } from '../Types'

const useVisitors = (queryParams?: {page: { number: number, size: number} }) => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { data: visitorsData, ...rest } = entitySauce.useGet(visitorsQuery(
    eventId,
    sponsorId,
    queryParams
  ), { staleTime: 50000, refetchOnWindowFocus: false, enabled: !!eventId && !!sponsorId })

  const visitors = (visitorsData?.data ?? []).map((visitor: any) => {
    return (
      { ...visitor, attendee: { ...visitor?.attendee, ...visitor?.attendee?.user, durationSeconds: visitor.durationSeconds } }
    )
  })

  const { meta } = visitorsData

  return { visitors, eventId, meta, ...rest }
}

export default useVisitors
