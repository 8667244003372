import { useState } from 'react'
import styled from '@emotion/styled'
import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { all, compose, identity, values } from 'ramda'

const ErrorContainer = styled.div`
  margin: 16px 0px;
`

const ErrorText = styled.p`
  margin-bottom: 0;
  color: ${({ isValid }) => isValid ? '#097969' : '#900C3F'};
  text-align: left;
`

const checkAllTrue = compose(all(identity), values)
const MIN_PASSWORD_LENGTH = 12
const withPasswordValidation = (WrappedComponent) => {
  const WithPasswordValidation = (props) => {
    const [passwordErrors, setPasswordErrors] = useState({
      hasMinLength: false,
      hasLowercase: false,
      hasUppercase: false,
      hasNumber: false,
      hasSpecialCharacter: false
    })

    const [password, setPassword] = useState('')

    const validatePasswordError = (value) => {
      setPasswordErrors({
        hasMinLength: value.length >= MIN_PASSWORD_LENGTH,
        hasLowercase: /^(?=.*[a-z]).+$/.test(value),
        hasUppercase: /^(?=.*[A-Z]).+$/.test(value),
        hasNumber: /\d+/.test(value),
        hasSpecialCharacter: /^(?=.*[\W_]).+$/.test(value)
      })
      setPassword(value)
    }

    const renderPasswordErrors = () => {
      if (password.length === 0) return
      return (
        <ErrorContainer>
          {renderPasswordError(passwordErrors.hasMinLength, ` Password must be at least ${MIN_PASSWORD_LENGTH} characters`)}
          {renderPasswordError(passwordErrors.hasLowercase, ' 1 lowercase letter required')}
          {renderPasswordError(passwordErrors.hasUppercase, ' 1 uppercase letter required')}
          {renderPasswordError(passwordErrors.hasNumber, ' 1 number required')}
          {renderPasswordError(passwordErrors.hasSpecialCharacter, ' 1 special character required')}
        </ErrorContainer>
      )
    }

    const renderPasswordError = (isValid, requirement) => {
      return (
        <ErrorText isValid={isValid}>
          <FontAwesomeIcon icon={isValid ? faCheck : faXmark} size={'sm'} color={isValid ? '#097969' : '#900C3F' }/>
          {requirement}
        </ErrorText>
      )
    }
    return (
      <WrappedComponent
        {...props}
        validatePasswordError={validatePasswordError}
        isPasswordValid={checkAllTrue(passwordErrors)}
        renderPasswordErrors={renderPasswordErrors()}
      />
    )
  }

  return WithPasswordValidation
}

export default withPasswordValidation
