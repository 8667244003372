import { Dispatch } from 'react'
import { Icon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import ImageUpload from '@Reusable/ImageUpload/ImageUpload'
import InfoSubTitle from '@SponsorCommon/InfoSubTitle'
import { colors } from '@Theme'
import { Form, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import { actionTypes, ProductActionType, ProductType } from '../Types'

import { DESCRIPTION_MAX_COUNT, TITLE_MAX_COUNT } from '.'

const formItemLayout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  }
}

type ChangeEvent = { target: { value: string } }

interface ModalFormProps {
  state: ProductType,
  dispatch: Dispatch<ProductActionType>
}

const ModalForm = ({ state, dispatch }: ModalFormProps) => {
  const handleTitleChange = (event: ChangeEvent) => {
    const { value } = event.target
    dispatch({
      type: actionTypes.SET_TITLE,
      payload: value
    })
  }

  const handleUrlChange = (event: ChangeEvent) => {
    const { value } = event.target
    dispatch({
      type: actionTypes.SET_LINK,
      payload: value
    })
  }

  const handleDescriptionChange = (event: ChangeEvent) => {
    const { value } = event.target
    dispatch({
      type: actionTypes.SET_DESCRIPTION,
      payload: value
    })
  }

  const handleImageUrlChange = (value: string) => {
    dispatch({
      type: actionTypes.SET_IMAGE_URL,
      payload: value
    })
  }

  return (
    <DraggableItemFormContainer data-test={'modal-form'}>
      <Form
        style={{ maxWidth: 600 }}
        onFieldsChange={ () => dispatch({
          type: actionTypes.SET_FIELDS_TOUCHED,
          payload: true
        })}
        initialValues={{
          title: state?.title,
          url: state?.url,
          description: state?.description,
          imageUrl: state?.imageUrl
        }}
        layout={'vertical'}
      >

        <Form.Item
          label={`Title (${state.title?.length ?? 0} / ${TITLE_MAX_COUNT})`}
          hasFeedback
          name={'title'}
          rules={[
            {
              required: true,
              message: 'Title is required!'
            },
            {
              max: TITLE_MAX_COUNT,
              message: 'Title is too long!'
            }
          ]}
          data-test={'title-form-item'}
          {...formItemLayout}
        >
          <Input
            type={'text'}
            placeholder={'Add title'}
            onChange={handleTitleChange}
            value={state?.title}
            data-test={'title-input'}
          />
        </Form.Item>

        <>
          <Form.Item
            label={`Description (${state.description?.length ?? 0} / ${DESCRIPTION_MAX_COUNT})`}
            hasFeedback
            name={'description'}
            rules={[
              {
                required: true,
                message: 'Description is required!'
              },
              {
                max: DESCRIPTION_MAX_COUNT,
                message: 'Description is too long!'
              }
            ]}
            data-test={'description-form-item'}
            {...formItemLayout}

          >
            <TextArea
              onChange={handleDescriptionChange}
              placeholder={'Add description'}
              data-test={'description-input'}
            />
          </Form.Item>
          <InfoSubTitle testId={'description-subtitle'} margin={'-8px 0 16px'}>
            {`Maximum ${DESCRIPTION_MAX_COUNT} characters. The description will be adjusted dynamically to match the user screen sizes. which may result in truncation of text.`}
          </InfoSubTitle>
        </>

        <Form.Item
          label={'Link'}
          hasFeedback
          name={'url'}
          rules={[
            {
              required: true,
              message: 'Link is required!'
            },
            {
              type: 'url',
              message: 'This field must be a valid url.'
            }
          ]}
          data-test={'link-form-item'}
          {...formItemLayout}
        >
          <StyledInput
            type={'url'}
            data-test={'link-input'}
            placeholder={'Add link'}
            onChange={handleUrlChange}
            prefix={<Icon type={'link'} />}
          />
        </Form.Item>
        <Form.Item
          className={'image-upload-item'}
          label={'Image'}
          name={'image-url'}
          rules={[
            {
              required: true,
              message: 'Image is required!'
            }
          ]}
          {...formItemLayout}
        >
          <ImageUpload
            width={'100%'}
            height={'465px'}
            cropperEnabled
            aspectRatio={1}
            helperText = {[
              { id: 1, text: 'Recommended ratio 1:1' }
            ]}
            onChange={handleImageUrlChange}
            preview={state?.imageUrl}
          />
        </Form.Item>
      </Form>
    </DraggableItemFormContainer>
  )
}

export default ModalForm

const DraggableItemFormContainer = styled('div')({
  '.image-upload-item': {
    display: 'block',
    '.ant-form-item-control': {
      maxWidth: '100%'
    }
  }
})

const StyledInput = styled(Input)({
  backgroundColor: `${colors.lightGrayBackground} !important`
})
