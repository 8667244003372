import './SponsorProductsTable.css'

import React, { useEffect, useState } from 'react'
import { queryCache } from 'react-query'
import { useParams } from 'react-router-dom'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { DeleteOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { productsQuery } from '@EntitySauce/Queries'
import useSponsorProducts from '@Hooks/useSponsorProducts'
import Card from '@Reusable/Card/Card'
import { StyledTable } from '@Reusable/Styled'
import { useSponsorContext } from '@SponsorContext'
import { entitySauce } from '@State'
import { Button, message, Popconfirm, Space, Table } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import { isNil, move, prop, sortBy, unless } from 'ramda'
import { EventParamsTypes } from 'src/Types'

import { ReactComponent as DNDIcon } from '../../../../Images/SVG/dnd.svg'
import ProductModal from '../ProductModal'
import { useProductsContext } from '../ProductsContext'
import ProductsSearch from '../ProductsSearch'
import { actionTypes, ProductModalType, ProductType } from '../Types'

import DeletePopUpConfirm from './DeletePopUpConfirm'
import TableSizeChanger from './TableSizeChanger'
import { assignIndexes } from './Utils'

const INITIAL_PAGE_SIZE = 10

type DraggableProductType = ProductType & {index: number, key :number}

type SortParams = {
  oldIndex: number,
  newIndex: number
}

const DragHandle = SortableHandle(() => (
  <DNDIcon
    style={{
      cursor: 'grab'
    }}
  />
))

const SortableItem = SortableElement((props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableRowElement> & React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />)
const SortableBody = SortableContainer((props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableSectionElement> & React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />)

const sortItemsByPosition = unless(isNil, sortBy(prop('position')))

const ProductsTable = () => {
  const params = useParams<EventParamsTypes>()

  const invalidateCache = () => queryCache.invalidateQueries('products')

  const { activeProductStateDispatch, tableLoading, setTableLoading } = useProductsContext()
  const { products, isLoading: isLoadingProducts } = useSponsorProducts()
  const [productsState, setProductsState] = useState<DraggableProductType[]>([])
  const [isNewModalVisible, setIsNewModalVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE)
  const { sponsorState, setSponsorState } = useSponsorContext()

  useEffect(
    function activateProductsTab() {
      if (!products.length) {
        setSponsorState({ ...sponsorState, productsTabDisabled: true })
      } else {
        setSponsorState({ ...sponsorState, productsTabDisabled: false })
      }
    },
    [products.length]
  )
  useEffect(function reAssignProductsIndexes() {
    const formattedProducts: DraggableProductType[] = assignIndexes(products)
    setProductsState((sortItemsByPosition(formattedProducts)) as DraggableProductType[])
  }, [products])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const [deleteProduct] = entitySauce.useRemove(
    productsQuery(params.eventId, params.sponsorId),
    {
      onSuccess: () => {
        message.success('Product deleted successfully.')
        invalidateCache()
        setTableLoading(false)
      },
      onError: () => {
        message.error('Couldn\'t delete the product. Please try again.')
        setTableLoading(false)
      }
    }
  )

  const deleteManyQueryParams = {
    product_ids: selectedRowKeys.map((key: React.Key) => productsState[key as number - 1]?.id)
  }

  const [deleteManyProducts] = entitySauce.useRemove(
    productsQuery(params.eventId, params.sponsorId, deleteManyQueryParams),
    {
      onSuccess: () => {
        message.success('Products deleted successfully.')
        invalidateCache()
        setSelectedRowKeys([])
        setTableLoading(false)
      },
      onError: () => {
        message.error('Couldn\'t delete the product. Please try again.')
        setTableLoading(false)
      }
    }
  )

  const [patchProduct] = entitySauce.usePatch(
    productsQuery(params.eventId, params.sponsorId),
    {
      onSuccess: () => {
        message.success('Changes saved successfully.')
        invalidateCache()
        setTableLoading(false)
      },
      onError: () => {
        message.error('Couldn\'t save the changes. Please try again.')
        invalidateCache()
        setTableLoading(false)
      }
    }
  )

  const onDelete = (id: number) => {
    setTableLoading(true)
    deleteProduct({
      id: id
    })
  }

  const deleteMultipleProducts = () => {
    setTableLoading(true)
    deleteManyProducts({
      extra: 'delete_many'
    })
  }

  const onEditOpen = (id: number) => {
    const productToEdit = productsState.find((product: ProductType) => product.id === id) || {}
    activeProductStateDispatch({
      type: actionTypes.SET_INITIAL_VALUES,
      payload: productToEdit
    })
    setIsModalVisible(true)
  }

  const rowSelection: TableRowSelection<ProductType> = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const onAddNewOpen = () => {
    setIsNewModalVisible(true)
  }

  const onSortEnd = ({ oldIndex, newIndex }: SortParams) => {
    if (oldIndex !== newIndex) {
      setTableLoading(true)
      const newData = move(oldIndex, newIndex, [].concat(productsState as unknown as never)).filter((el: DraggableProductType) => !!el) as DraggableProductType[]
      setProductsState(newData)
      const ProductId = newData[newIndex].id
      patchProduct({
        id: ProductId,
        data: {
          product: {
            position: newIndex + 1
          }
        }
      })
    }
  }

  const DraggableContainer = (props: JSX.IntrinsicAttributes) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass={'products-table-row-dragging'}
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = productsState.findIndex((row) => row.index === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }

  const onSearch = (value: string) => {
    setSelectedRowKeys([])
    setSearchInput(value)
    const searchFilteredProducts = assignIndexes(products.filter(
      (i: ProductType) => i.title?.toLowerCase().includes(value.toLocaleLowerCase())
    ))
    setProductsState(searchFilteredProducts)
  }

  // TODO fix the tooltip info
  const TooltipNode = (
    <span>
      {'Add products and services to prominently display your top product listings and drive direct sales traffic to your e-commerce funnels. '}
      {/* <a href={'https://help.brella.io/en/sponsors/'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a> */}
    </span>
  )

  return (
    <>
      <ProductModal
        visible={isNewModalVisible}
        setVisible={setIsNewModalVisible}
        modalType={ProductModalType.NEW}
      />
      <ProductModal
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        modalType={ProductModalType.EDIT}
      />
      <Card
        title={'Products'}
        testId={'products'}
        loading={isLoadingProducts}
        TooltipNode={TooltipNode}
      >
        <ToolGroup>
          <ProductsSearch onSearch={onSearch}/>
          <Popconfirm
            icon={<DeleteOutlined style={{ color: 'red', fontSize: 24 }} />}
            title={<DeletePopUpConfirm bulk/>}
            onConfirm={deleteMultipleProducts}
            okText={'Confirm'}
            cancelText={'Cancel'}
            okButtonProps={{
              className: 'bulk-delete-button-confirm',
              type: 'primary',
              danger: true,
              size: 'middle',
              style: { borderRadius: 4 }
            }}
            cancelButtonProps={{
              size: 'middle',
              style: { borderRadius: 4 }
            }}
            placement={'topLeft'}
          >
            <StyledButton
              danger
              type={'primary'}
              disabled={!selectedRowKeys.length}
              data-test={'products-bulk-delete-button'}
            >
              {'Delete'}
            </StyledButton>
          </Popconfirm>
          <StyledButton
            type={'primary'}
            data-test={'add-product-button'}
            onClick={onAddNewOpen}
          >
            {'Add new'}
          </StyledButton>
        </ToolGroup>

        <StyledTable
          bordered
          loading={tableLoading}
          // eslint-disable-next-line @typescript-eslint/ban-types
          rowSelection={rowSelection as TableRowSelection<object>}
          dataSource={productsState}
          rowKey={'index'}
          className={'products-table'}
          pagination={{ pageSize }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        >
          {!searchInput &&
          <Table.Column
            dataIndex= {'sort'}
            className= {'drag-visible'}
            width={30}
            render= {() => <div data-test={'row-drag-handle'}><DragHandle/></div>}
          />
          }
          <Table.Column
            title={'Name'}
            dataIndex={'title'}
            className={'name-column'}
          />
          <Table.Column
            title={'Actions'}
            className={'actions-column'}
            key={'actions'}
            render={(value) => (
              <Space size={'middle'}>
                <Popconfirm
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: 24 }} />}
                  title={<DeletePopUpConfirm />}
                  onConfirm={() => onDelete(value.id)}
                  okText={'Confirm'}
                  cancelText={'Cancel'}
                  okButtonProps={{
                    className: 'delete-button-confirm',
                    type: 'primary',
                    danger: true,
                    size: 'middle',
                    style: { borderRadius: 4 }
                  }}
                  cancelButtonProps={{
                    size: 'middle',
                    style: { borderRadius: 4 }
                  }}
                  placement={'topLeft'}
                >
                  <a
                    id={'delete-action-anchor'}
                    data-test={'delete-product-action-button'}
                  >
                    {'Delete'}
                  </a>
                </Popconfirm>
                <StyledDivider>{'|'}</StyledDivider>
                <a
                  data-test={'edit-product'}
                  onClick={() => onEditOpen(value.id)}
                >
                  {'Edit'}
                </a>
              </Space>
            )}
          />
        </StyledTable>
        {productsState.length > INITIAL_PAGE_SIZE &&
        <TableSizeChanger
          size={pageSize}
          setSize={setPageSize}
          defaultValue={INITIAL_PAGE_SIZE}
          sizeOptions={[10, 20, 30, 40, 50]}
        />}
      </Card>
    </>
  )
}

export default ProductsTable

const StyledButton = styled(Button)({
  borderRadius: '4px !important'
})

const ToolGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
  '> button': {
    marginLeft: 10
  }
})

const StyledDivider = styled('span')({
  color: '#ccc'
})
