import { Icon } from '@ant-design/compatible'
import { Input } from 'antd'

interface ProductsSearchProps {
  onSearch: (value: string) => void
}

const ProductsSearch = ({ onSearch }: ProductsSearchProps) => {
  const onSearchBlur = (e: { target: { value: string } }) => {
    if (e.target.value === '') {
      onSearch('')
    }
  }

  return (
    <Input
      prefix={<Icon type={'search'} />}
      allowClear
      placeholder={'Type to filter by products'}
      onChange={(e) => onSearch(e.target.value)}
      onBlur={onSearchBlur}
      data-test={'products-list-search-input'}
    />
  )
}

export default ProductsSearch
