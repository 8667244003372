import { FileIconProps } from 'react-file-icon'

import FileColors from './Colors'
import ExtList from './ExtList'

type FileExtList = string[]

const styleDef = Object.keys(ExtList).map((key: string) => ([
  {
    glyphColor: FileColors[key],
    labelColor: FileColors[key],
    labelUppercase: false,
    foldColor: '#dbdbdb',
    color: 'whitesmoke'
  },
  ExtList[key]])) as unknown as [FileIconProps, FileExtList][]

const defaultStyle: Record<string, FileIconProps> = {}

function createStyleObj(
  styleObj: Partial<FileIconProps>,
  extList: FileExtList
) {
  // CAUTION: https://stackoverflow.com/q/62201724
  // Add compilerOptions.lib.esnext
  return Object.fromEntries(extList.map((ext) => {
    return [ext, styleObj]
  }))
}

export const styleDefObj = styleDef?.reduce((acc, [fileStyle, fileExtList]) => {
  return { ...acc, ...createStyleObj(fileStyle, fileExtList) }
}, defaultStyle)
