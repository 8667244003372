
import { useState } from 'react'
import { DEFAULT_DATE_RANGE, OverviewContext } from '@OverviewContext'
import Modal from 'antd/lib/modal/Modal'

import BoothVisitsActivity from './Modules/BoothVisitsActivity/BoothVisitsActivity'
import { EXPANDED_MODAL_TOP, EXPANDED_MODAL_WIDTH } from './Modules/BoothVisitsActivity/Common'
import StatCards from './Modules/StatCards'

function Overview(): JSX.Element {
  const [boothVisitsActivityExpanded, setBoothVisitsActivityExpanded] = useState<boolean>(false)
  const [boothVisitsActivityDateRange, setBoothVisitsActivityDateRange] = useState<[moment.Moment, moment.Moment]>(DEFAULT_DATE_RANGE)

  const overviewContextProviderValue = {
    boothVisitsActivityExpanded,
    setBoothVisitsActivityExpanded,
    boothVisitsActivityDateRange,
    setBoothVisitsActivityDateRange
  }
  const toggleBoothVisitsActivityExpanded = () => {
    setBoothVisitsActivityExpanded(!boothVisitsActivityExpanded)
  }

  return (
    <OverviewContext.Provider value={overviewContextProviderValue}>
      <Modal
        data-test={'booth-visits-activity-modal'}
        visible={boothVisitsActivityExpanded}
        onOk={toggleBoothVisitsActivityExpanded}
        onCancel={toggleBoothVisitsActivityExpanded}
        footer={null}
        centered
        width={EXPANDED_MODAL_WIDTH}
        style={{ top: EXPANDED_MODAL_TOP }}
      >
        <BoothVisitsActivity expanded/>
      </Modal>
      <StatCards />
    </OverviewContext.Provider>
  )
}

export const OverviewRouteConfig = {
  path: '/overview'
}

Overview.routeConfig = OverviewRouteConfig

export default Overview
