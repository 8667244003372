import React from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Tabs } from 'antd'

import { NavMenuProps } from './Types'

type NavTabMenuProps = NavMenuProps
function NavTabMenu({ defaultSelectedKey, children }: NavTabMenuProps) : JSX.Element {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  const [currentKey, setCurrentKey] = React.useState(defaultSelectedKey)

  function _onTabClick(key: string) {
    history.push(`${match.url}/${key}`)
  }

  React.useEffect(() => {
    // Handle menu item click and browser back
    setCurrentKey(location?.pathname?.split('/')?.pop() ?? currentKey)
  }, [location.pathname, currentKey])

  return (
    <Tabs activeKey={currentKey} onTabClick={_onTabClick} data-test={'tabs-container'}>
      {children}
    </Tabs>
  )
}

NavTabMenu.Item = Tabs.TabPane

export default NavTabMenu
