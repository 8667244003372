import { userQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'

const useUser = () => {
  const { data, ...rest } = entitySauce.useGet(
    userQuery()
    , { staleTime: 50000, refetchOnWindowFocus: false }
  )

  return { user: data?.data?.[0], ...rest }
}

export default useUser
