export const TermsOfUsePolicy = `
Latest update 18 November 2021.

Welcome to use Brella service (hereinafter “Service”), which is provided to you as an event sponsor (hereinafter “Sponsor” or “you”) by Brella Ltd.

Brella Ltd. (business ID 2765076-7) is a limited liability company formed under Finnish law, which has its principal office at c/o Maria01 Lapinlahdenkatu 16, 00180 Helsinki Finland (hereinafter “we” or “Company”).

An attendee user of the Service (hereinafter “Attendee”) may use the Service to participate in professional events (hereinafter “Event” or “Events”) arranged by organizer users of the Service (hereinafter “Organizer”). The Sponsors may use the Service to sponsor Events and use related functions of the Service.

These terms (hereinafter “Sponsor Terms”) apply to the legal relationship between the Sponsor and the Company when the Sponsor uses the Service. When the Organizers use the Service to arrange Events, the legal relationship between them and the Company is governed by the Organizer Terms. When the Attendees use the Service to participate in Events, the legal relationship between them and the Company is governed by the Attendee Terms.

The Sponsor accepts these Sponsor Terms as legally binding (hereinafter “Agreement”) by registering into the Service or by using the Service. The Agreement is therefore a contract, which creates legally binding terms between the Sponsor and the Company when the Sponsor uses the Service. The Sponsor may not use the Service if it does not enter into an Agreement with the Company. Hereinafter the Sponsor may also be referred to as the “User”.
GENERAL

The Company reserves the right to change the Agreement. The Company shall notify the Sponsor through email or the Service of such changes. The changes shall take effect 14 days after the notification. By using the Service after the changes, the Sponsor accepts the changes as legally binding.

The Sponsor must be legally competent and a legal entity established according to applicable laws in order to be entitled to use the Service as a Sponsor. By creating an account into the Service (hereinafter “Account”) and by using the Service, the Sponsor warrants that it is legally qualified and entitled to enter into a binding legal relationship with the Company in the form of the Agreement and in compliance with applicable legislation.
CREATING AN ACCOUNT

The Sponsor may create an Account through the Service.

When registering, the Sponsor must truthfully provide the information required by the Service. The accuracy of the information provided by the Sponsor can be reviewed by the Company during the registration process.

The Sponsors are solely responsible for all activities performed through the Account and for the safe use of the password, maintenance, confidentiality and all other such matters in regard to their Account.

If the Sponsor becomes aware that someone else has used the Sponsor’s Account, the Sponsor must notify us immediately of the matter through email at support@brella.io.
LICENSE AND PROVISION OF EVENTS

Subject to the terms and conditions of this Agreement, we hereby grant the Sponsor a personal, revocable, non-exclusive, non-sublicensable, non-assignable and non-transferable license (“License”) to download, install and use the Service in accordance with this Agreement on a mobile telephone, tablet, computer or other device (each, a “Device”) that the Sponsor owns or controls.

The Sponsor agrees not to, and shall not permit any third party to: (i) sublicense, redistribute, sell, lease, lend or rent the Service; (ii) reverse engineer, decompile, dissemble, or otherwise attempt to derive the source code for the platform; (iii) copy (except for back-up purposes), modify, adapt, alter, improve or create derivative works of the software or any part thereof; (iv) use the Company’s name, logo or trademarks in any other context except for using the Service without our prior written consent; (v) use the Service or software to violate any applicable laws, rules or regulations, or for any unlawful, harmful, irresponsible or inappropriate purpose, or in any manner that breaches this Agreement; and/or engage in any activity that interferes with or disrupts the Service.

The Sponsor may use the Service to sponsor Events, communicate with Attendees, host sessions and roundtables in Events and receive data of Attendees. Please find more information on how you may use the Service from our website: https://www.brella.io/sponsors.
RIGHTS AND RESPONSIBILITIES OF THE SPONSOR

The Sponsor is solely responsible for its actions in the Service. The Sponsor must act accordingly, lawfully and truthfully in the Service.

The Sponsor is solely responsible for his/her electronic devices, communication devices and other such devices and matters such as hardware condition, internet connection, antivirus, backup, and other similar issues.

The Sponsor agrees to use the Service only for the purposes permitted by the Agreement and any applicable laws, regulations or generally accepted policies or guidelines in the relevant jurisdiction.

It is forbidden to choose a username for the Account that violates good practice and/or violates the rights of others. We have the right to delete an Account if the Sponsor violates this Agreement, law or good practice or has not signed into the Service for 12 months.

The Sponsor agrees not to take up any actions that disturbs or in any other way hinders the Service or its servers or networks.

The Service may contain links to third party websites. When the Sponsor visits third party websites, the Sponsor does so on its own responsibility and risk.

The Sponsor agrees not to send, transmit or store material through the Service that is in violation of good practice or law. the Sponsor also agrees not to incite others to engage in any such activities. Furthermore, the Sponsor agrees not violate anyone’s intellectual property rights through the Service.

The Company owns and retains all proprietary rights in the Service, and in all content, trademarks, trade names, service marks and other intellectual property rights related thereto. The Service contains the copyrighted material, trademarks, and other proprietary information of the Company and its licensors. the Sponsor agrees to not copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, trademarks, trade names, service marks, or other intellectual property or proprietary information accessible through the Service, without first obtaining the prior written consent of the Company. the Sponsor agrees to not remove, obscure or otherwise alter any proprietary notices appearing on any content, including copyright, trademark and other intellectual property notices.

If the Sponsor does not comply with this Agreement, the Company may terminate the Agreement with immediate legal effect, cancel the Account of the Sponsor and implement any other similar procedure.
RIGHTS AND RESPONSIBILITIES OF THE COMPANY

The Company operates only and solely as the administrator of the Service. The Company is not responsible for the availability, redemption, marketing or any related obligations of the Events or liabilities, errors, reliability, damages or any other matters related to the Events. The Company is also not responsible for the use, obstruction or content of the Events, nor for the actions of the Organizers, the Sponsors or the Attendees in the Events or before the Events, or for the activities of the Organizers, the Sponsors or the Attendees in the Service.

Under no circumstances shall the Company be liable for accidents, damages or crimes related to the Service. Under no circumstances shall the Company be liable for any direct or indirect damages in any manner in connection with the Service or the use, information and notices available through the Service. The Company is also not liable for any damages or other disadvantages that may be caused to the Sponsor or third parties by incorrect or insufficient user information.

The Company is not responsible for any losses arising from the use of the Service. The Sponsors agree to be solely responsible for any failure to comply with their obligations under this Agreement and for their consequences.

The Company is not responsible for any payments the Organizer may impose on the Sponsor.

The Company does not express or imply warranties or representations about the operation of features of the Service, and the Company does not promise that the Service will function without interruptions or errors. The Company is not responsible for any damages to the Sponsor or third parties caused by the use, malfunctions, technical defects or malicious software of the Service or third-party links or any other such causes.

The Company has a right to disable the Service or a part of it due to maintenance, installation, modification, public order and safety, system overload or other similar cause. The Company may terminate (permanently or temporarily) the provision of the Service (or any part of it), either to the Sponsor or the Sponsor s generally, entirely at the Companies own discretion and without prior notice.

The Service is provided on an “as is” and “as available” basis without warranties of any kind including, without limitation, representations, warranties and conditions of merchantability, fitness for a particular purpose, title, non-infringement, and those arising by statute or from a course of dealing or usage of trade.

The Service may also enable the Sponsor to view, access, communicate and interact with third party sources, meaning e.g. third party websites and services. We do not assume any responsibility for the content, actions or practices of, any such sources. the Sponsor’s interaction with such a source and the Sponsor’s use of, and reliance upon, any content provided by such sources is at the Sponsor’s sole discretion and risk.

The Company is not responsible for the content of the Service or its correctness, except for the content generated by The Company. Thus, the Company is not responsible for e.g. the information of the Sponsors or the Sponsors disclose through the Service.

The Company has the right to remove material (i) that has been denied or reported by the Company in the Service, or (ii) which, according to the Company is unlawful, contrary to good practice or this Agreement, inappropriate or erroneous, or (iii) harmful to the Company, the Attendees, the Organizers, the Sponsors or third parties. The Company also has the right to remove material from the Service if the material contains any legally questionable or offensive information or content that is inappropriate for the Company.

The Company agrees to transfer personal data of an Attendee via the Service to the Sponsor if the Organizer in whose Event the Attendee participates via the Service enables the sharing of personal data with the Sponsor via the Service.

The Company has the right to use the co-operation between the Sponsor and the Company in its own marketing and as a reference, in accordance with good practice.
LIMITATION OF LIABILITY

To the fullest extent allowed by applicable law, in no event will the Company, its affiliates, business partners, licensors or service providers be liable to the Sponsor or any third person for any indirect, reliance, consequential, exemplary, incidental, special or punitive damages, including without limitation, loss of profits, loss of goodwill, damages for loss, corruption or breaches of data or programs, service interruptions and procurement of substitute services, even if the Company has been advised of the possibility of such damages.

Notwithstanding anything to the contrary contained herein, the Company’s liability to the Sponsor for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by the Sponsor to the Company for the Service within the three (3) months preceding the date of bringing a claim.

Some jurisdictions do not allow the exclusion or limitation of certain damages, so some or all of the exclusions and limitations in this Section may not apply to the Sponsor.
INDEMNIFICATION

The Sponsor agrees to defend, indemnify and hold harmless us and our affiliates, and our respective officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs and expenses (including but not limited to attorney’s fees) arising from: (i) the Sponsor’s use of, or inability to use, the Service; (ii) the Sponsor’s violation of this Agreement; and (iii) the Sponsor’s violation of any third party right, including without limitation any intellectual property rights or data protection right.
TERM AND TERMINATION

This Agreement enters into force once the Sponsor accepts this Agreement or uses the Service.

This Agreement is valid for indefinitely. the Sponsor may terminate this Agreement with immediate effect by email at support@brella.io. By terminating this Agreement, the Sponsor is not however exempt from the obligations he/she has undertaken under this Agreement prior to the termination.

Upon the termination of this Agreement, the Company shall have a right to close the Sponsor’s Account and remove any material the Sponsor has posted through the Service.
GOVERNING LAW AND DISPUTES

This Agreement and the relationship between the Sponsor and the Company shall be governed by and construed and interpreted in accordance with the laws of Finland without regard to its principles and rules on conflict of laws.

Any dispute, arising out of or relating to the Agreement, shall be finally settled by arbitration in accordance with the Arbitration Rules of the Finland Chamber of Commerce. The number of arbitrators shall be one (1). The seat of arbitration shall be Helsinki, Finland. The language of the arbitration shall be Finnish. However, evidence may be submitted and witnesses may be heard in English, to the extent the arbitral tribunal deems it appropriate.
MISCELLANEOUS

The Sponsor does not have the permission to surrender, transfer or sublicense this Agreement unless the Sponsor obtains prior written consent from the Company. The Company has the unilateral right to assign, transfer or delegate any or all of its rights and obligations under the Agreement.

The Sponsor agrees that if the Company does not exercise or enforce any legal rights under the Agreement (e.g. the right to compensation), it does not imply that the Company formally waives its rights, nevertheless the Company still has the right to exercise its rights.

The Sponsor agrees that any cause of action that the Sponsor may have arising out of or related to this Agreement must commence within one (1) year after the cause of action accrues, otherwise, such cause of action is permanently barred.

If, by a court decision, any provision of this Agreement is declared void, then only that invalid provision will be removed from the Agreement, in which case the Agreement will continue to be valid.

Sections 4 – 7 and 9 – 10 shall prevail and stay in force even after the termination of the Agreement.
USAGE RULES

If you (meaning the Sponsor) are downloading the software from a third party mobile device platform or service provider (“Distributor”), please be aware that the Distributor may have established usage rules which also govern your use of the software and the Service (“Usage Rules.”). We specifically refer to the Usage Rules of certain Distributors in the Section 14 below entitled ‘Distributor Requirements and Usage Rules’, but other Usage Rules may be applicable depending on where the software has been downloaded from.

You acknowledge that, prior to downloading the Service from a Distributor or using the Service, you have had the opportunity to review and understand, and will comply with, its Usage Rules. The Usage Rules that are applicable to your use of the software and the Service are incorporated into this Agreement by this reference. You represent that you are not prohibited by any applicable Usage Rules and/or applicable law from using the Service; if you are unable to make such a representation you are prohibited from installing and/or using the Service. You agree that any right to refund under the Usage Rules shall not apply to Payments you pay for the Service under Section 4.
DISTRIBUTOR REQUIREMENTS AND USAGE RULES

If you (meaning the Sponsor) download the Service from the Apple, Inc. (“Apple”) App Store (or in any event if you download an Apple iOS app) then, without derogating from the warranty disclaimers and limitation of liability as set forth in the Agreement:

You acknowledge and agree that:

This Agreement is concluded between the Company and you only, and not with Apple, and the Company and its licensors, and not Apple, is solely responsible for the software and the content thereof.
Your use of the Service is also subject to the Usage Rules established by Apple, including those set forth in the Apple App Store Terms of Service, effective as of the date that you enter into this Agreement.
The License granted herein is limited to a non-transferable right to use the Service on an Apple iPhone, iPod Touch, iPad, or other Apple-branded product that you own or control and that runs the iOS;
The Company is solely responsible for providing any maintenance and support services with respect to the Service, as specified in this Agreement, or as required under applicable law. Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Service;
The Company is solely responsible for any product warranties, whether express or implied by law, to the extent not effectively disclaimed. In the event of any failure of the Service to conform to any applicable warranty, you may notify Apple, and Apple will, to the extent applicable, refund any purchase price paid (if any) by you for the Service to you. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Service, and, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the Company’s sole responsibility;
The Company, and not Apple, is responsible for addressing any product claims you, or any third party, may have relating to the Service or your possession and/or use of the Service;
In the event of any third party claim that the Service or your possession and use of the Service infringes that third party’s intellectual property rights, Apple shall not be responsible for the investigation defense, settlement and discharge of any such intellectual property infringement claim;
Apple, and its subsidiaries, are third party beneficiaries of this Agreement, and that, upon your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third party beneficiary thereof.

You represent and warrant that: (a) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (b) you are not listed on any U.S. Government list of prohibited or restricted parties.
`
