import { useState } from 'react'
import { sponsorMediaQuery } from '@EntitySauce/Queries'
import useSponsor from '@Hooks/useSponsor'
import { MediaContext } from '@MediaContext'
import CustomMessage from '@Reusable/CustomMessage'
import DragAndDropList from '@Reusable/DragAndDropList/DragAndDropList'
import Spinner from '@Reusable/Spinner'
import { useSponsorContext } from '@SponsorContext'
import { entitySauce } from '@State'
import { find, prop, propEq, sortBy } from 'ramda' // check out ramda's find and sortBy
import snakecaseKeys from 'snakecase-keys'

import DraggableItem from '../Common/DraggableItem'
import EmptyPlaceHolder from '../Common/EmptyPlaceHolder'
import Header from '../Common/Header'
import MediaModal, { ModalStatus, ModalTypes } from '../Common/MediaModal'
import { StyledColumnWrapper } from '../Common/Styled'

const CarouselDeck = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalStatus, setModalStatus] = useState(ModalStatus.NEW)
  const { sponsor, isFetching: isFetchingSponsor, refetch: refetchSponsor } = useSponsor()
  const { setUpdatingCarouselDeck } = useSponsorContext()

  // media-context
  const [mediaState, setMediaState] = useState({})
  const [sideBarPreviewVisible, setSideBarPreviewVisible] = useState<boolean>(false)
  const [patchItemId, setPatchItemId] = useState<string | number | null>(null)

  const showModal = (status: ModalStatus) => {
    setIsModalVisible(true)
    setModalStatus(status)
  }

  const [patch, { status: patchStatus }] = entitySauce.usePatch(
    sponsorMediaQuery()
  )

  const _onPositionChange = async({ draggedId, draggedPosition }: { draggedId: number, draggedPosition: number }) => {
    const item = find(propEq('id', draggedId))(deckMedia)
    if (!item) {
      return null
    }
    setUpdatingCarouselDeck(true)
    const payload = {
      ...item,
      position: draggedPosition,
      mediableId: sponsor?.id,
      mediableType: 'sponsor',
      mediableSecondaryType: 'deck'
    }
    const media = snakecaseKeys(payload)
    await patch({ mediaId: item.id, data: { media } })
    refetchSponsor()
    setUpdatingCarouselDeck(false)
  }

  const deckMedia = sponsor?.deckMedia ?? []

  const _renderListItem = (item: { id: string | number | null }) => {
    const mediaItem = find(propEq('id', item.id), deckMedia)
    const isLoading = isFetchingSponsor && item.id === patchItemId
    return (
      isLoading
        ? <Spinner height={72}/>
        : <DraggableItem
          showModal={showModal} mediaDeck={mediaItem} refetchSponsor={refetchSponsor}
          type={ModalTypes.CAROUSEL_SLIDE}
        />
    )
  }
  const _TooltipNode = (
    <span>
      {'Embed images, videos, web pages and other materials in the carousel section to increase interest towards your booth. '}
      <a href={'https://help.brella.io/en/sponsors/sponsor-panel-carousel-setup'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
    </span>
  )

  const MediaContextProviderValue = {
    mediaState,
    setMediaState,
    patchItemId,
    setPatchItemId,
    sideBarPreviewVisible,
    setSideBarPreviewVisible
  }
  return (
    <MediaContext.Provider value={MediaContextProviderValue}>
      <StyledColumnWrapper
        data-test={'carousel-container'}
        margin={'24px 0 0'}
      >
        <CustomMessage status={patchStatus}/>
        {isModalVisible &&
        <MediaModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          type={ModalTypes.CAROUSEL_SLIDE}
          modalStatus={modalStatus}
          refetchSponsor={refetchSponsor}
        />
        }
        <Header
          TooltipNode ={_TooltipNode}
          title={'Carousel Deck'}
          showModal={showModal}
          type={ModalTypes.CAROUSEL_SLIDE}
          testId={'carousel-deck'}
        />
        {
          !deckMedia.length
            ? <EmptyPlaceHolder />
            : <DragAndDropList
              list={sortBy(prop('position'))(deckMedia)}
              listItemRenderer={_renderListItem}
              onChange={_onPositionChange}
            />
        }
      </StyledColumnWrapper>
    </MediaContext.Provider>
  )
}

export default CarouselDeck
