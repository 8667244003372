/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export type MediaContextType = {
  mediaState: any,
  setMediaState: (mediaState: any) => void,
  patchItemId: string | number | null,
  setPatchItemId: Dispatch<SetStateAction<string | number | null>>,
  sideBarPreviewVisible: boolean,
  setSideBarPreviewVisible: Dispatch<SetStateAction<boolean>>
}

export const MediaContext = createContext<MediaContextType>({
  mediaState: {},
  setMediaState: () => {},
  patchItemId: null,
  setPatchItemId: () => {},
  sideBarPreviewVisible: false,
  setSideBarPreviewVisible: () => {}

})

export const useMediaContext = ():MediaContextType => useContext(MediaContext)
