import React, { useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import DataExport from '@Lib/DataExport/DataExport'
import { numberSorter, stringSorter } from '@Lib/Util'
import Card from '@Reusable/Card/Card'
import DownloadButton from '@Reusable/DownloadButton'
import { StyledLightInnerTooltip, StyledTable } from '@Reusable/Styled'
import theme from '@Theme'
import { message, Table, Tooltip } from 'antd'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'

import RemoveRepButton from './RemoveRepButton'

const exportSchema = {
  Name: 'name',
  'Joined the event': 'joinedAt',
  'Added as a Representative': 'sponsorRepAddedAt',
  'Meetings: Accepted': 'acceptedMeetingsCount',
  'Meetings: Pending inbound': 'receivedPendingMeetingsCount',
  'Meetings: Pending outbound': 'requestedPendingMeetingsCount',
  'Meetings: Cancelled': 'cancelledMeetingsCount',
  'Meetings: Declined (chat open)': 'declinedMeetingsCount',
  'Meetings: Declined (chat closed)': 'declinedMeetingsWhereChatOpenCount',
  'Total Meetings outbound': 'totalRequestedMeetingsCount',
  'Open chat: Answered inbound': 'receivedAnsweredOpenChatCount',
  'Open chat: Answered outbound': 'requestedAnsweredOpenChatCount',
  'Open chat: Pending inbound': 'receivedPendingOpenChatCount',
  'Open chat: Pending outbound': 'requestedPendingOpenChatCount',
  'Open chat: Converted to meeting inbound': 'receivedConvertedOpenChatCount',
  'Open chat: Converted to meeting outbound': 'requestedConvertedOpenChatCount',
  'Total Messages': 'chatMessagesCount'
}
interface RepresentativeTableProps {
  attendeeStats: never[],
  loading: boolean,
  refetchSponsorStatistics: () => void
}
const RepresentativeTable = ({ attendeeStats, loading, refetchSponsorStatistics }: RepresentativeTableProps) => {
  const [pageSize, setPageSize] = useState<number | undefined>(10)

  const _onExportError = (error: { message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }) => {
    message.error(error.message)
  }

  const _renderRemoveColumn = (id: number) => {
    return (
      <RemoveRepButton id={id} refetchData={refetchSponsorStatistics}/>
    )
  }

  const _TooltipNode = (
    <span>
      {'Brella will link your representatives automatically. If you have problems with it, contact your event organizer. '}
      <a href={'https://help.brella.io/en/sponsors/get-linked-to-your-virtual-booth'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
    </span>
  )

  return (
    <Card
      title={'Representatives'}
      TooltipNode={_TooltipNode}
      testId={'representatives'}
      data-test={'representatives-card'}
      loading={loading}
      extra={
        <React.Fragment>
          {/* remove Add reps button temporarily */}
          {/* <Button
            data-test={'add-representatives-button'}
            onClick={openRepresentativeModal}
            style={{ marginRight: 8 }}
            icon={<PlusOutlined />}
          >
            {'Add representatives'}
          </Button> */}
          <DownloadButton
            filename={`representatives-${moment().format('YYYY-MM-DD')}.csv`}
            tableName={'representatives'}
            data={attendeeStats}
            exporter={DataExport.csv}
            exportSchema={exportSchema}
            onError={_onExportError}
          >
            {'Export (.csv)'}
          </DownloadButton>
        </React.Fragment>
      }
    >
      <StyledTable
        data-test={'representatives-table'}
        dataSource={(attendeeStats)}
        rowKey={'id'}
        scroll={{ x: 'max-content' }}
        bordered
        size={'small'}
        pagination={{
          total: attendeeStats?.length ?? 0,
          pageSize,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showSizeChanger: attendeeStats.length > 10,
          pageSizeOptions: ['10', '20', '30', '60', '90', '120']
        }}
        onChange={({ pageSize }) => setPageSize(pageSize)}
      >
        <Table.Column
          title={'Name'}
          dataIndex={'name'}
          width={'7%'}
          sorter={(a, b) => stringSorter(a, b, 'name')}
          render= {(value) => <div data-test={'name'}>{value}</div>}
        />
        <Table.Column
          title={'Joined the event'}
          dataIndex={'joinedAt'}
          width={180}
          sorter={(a, b) => numberSorter(a, b, 'joinedAt')}
        />
        <Table.Column
          title={'Added as a Representative'}
          dataIndex={'sponsorRepAddedAt'}
          sorter={(a, b) => numberSorter(a, b, 'sponsorRepAddedAt')}
        />
        <Table.Column
          title={'Meetings: Accepted'}
          dataIndex={'acceptedMeetingsCount'}
          sorter={(a, b) => numberSorter(a, b, 'acceptedMeetingsCount')}
        />
        <Table.Column
          title={'Meetings: Pending inbound'}
          dataIndex={'receivedPendingMeetingsCount'}
          sorter={(a, b) => numberSorter(a, b, 'receivedPendingMeetingsCount')}
        />
        <Table.Column
          title={'Meetings: Pending outbound'}
          dataIndex={'requestedPendingMeetingsCount'}
          sorter={(a, b) => numberSorter(a, b, 'requestedPendingMeetingsCount')}
        />
        <Table.Column
          title={'Meetings: Cancelled'}
          dataIndex={'cancelledMeetingsCount'}
          sorter={(a, b) => numberSorter(a, b, 'cancelledMeetingsCount')}
        />
        <Table.Column
          title={'Meetings: Declined (chat open)'}
          dataIndex={'declinedMeetingsWhereChatOpenCount'}
          sorter={(a, b) => numberSorter(a, b, 'declinedMeetingsWhereChatOpenCount')}
        />
        <Table.Column
          title={'Meetings: Declined (chat closed)'}
          dataIndex={'declinedMeetingsWhereChatClosedCount'}
          sorter={(a, b) => numberSorter(a, b, 'declinedMeetingsWhereChatClosedCount')}
        />
        <Table.Column
          title={'Total Meetings outbound'}
          dataIndex={'totalRequestedMeetingsCount'}
          sorter={(a, b) => numberSorter(a, b, 'totalRequestedMeetingsCount')}
        />
        <Table.Column
          title={'Open chat: Answered inbound'}
          dataIndex={'receivedAnsweredOpenChatCount'}
          sorter={(a, b) => numberSorter(a, b, 'receivedAnsweredOpenChatCount')}
        />
        <Table.Column
          title={'Open chat: Answered outbound'}
          dataIndex={'requestedAnsweredOpenChatCount'}
          sorter={(a, b) => numberSorter(a, b, 'requestedAnsweredOpenChatCount')}
        />
        <Table.Column
          title={'Open chat: Pending inbound'}
          dataIndex={'receivedPendingOpenChatCount'}
          sorter={(a, b) => numberSorter(a, b, 'receivedPendingOpenChatCount')}
        />
        <Table.Column
          title={'Open chat: Pending outbound'}
          dataIndex={'requestedPendingOpenChatCount'}
          sorter={(a, b) => numberSorter(a, b, 'requestedPendingOpenChatCount')}
        />
        <Table.Column
          title={'Open chat: Converted to meeting inbound'}
          dataIndex={'receivedConvertedOpenChatCount'}
          sorter={(a, b) => numberSorter(a, b, 'receivedConvertedOpenChatCount')}
        />
        <Table.Column
          title={'Open chat: Converted to meeting outbound'}
          dataIndex={'requestedConvertedOpenChatCount'}
          sorter={(a, b) => numberSorter(a, b, 'requestedConvertedOpenChatCount')}
        />
        <Table.Column
          title={() => {
            return (
              <ValueWithTooltip>
                {'Total Messages'}
                <Tooltip
                  placement={'topRight'}
                  title={<StyledLightInnerTooltip>{'Chat messages sent in both open chats and meetings.'}</StyledLightInnerTooltip>}
                  color={theme.colors.primaryBackground}
                >
                  <InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: 5 }}/>
                </Tooltip>
              </ValueWithTooltip>
            )
          }}
          dataIndex={'chatMessagesCount'}
          sorter={(a, b) => numberSorter(a, b, 'chatMessagesCount')}
        />
        <Table.Column
          title={'Actions'}
          dataIndex={'id'}
          fixed={'right'}
          render={_renderRemoveColumn}
        />
      </StyledTable>
    </Card>
  )
}

export default RepresentativeTable

const ValueWithTooltip = styled.span`
  display: flex;
  align-items: center;
  > div {
    margin-left: 8px;
  }
`
