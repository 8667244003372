import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { removeSponsorAttendeesQuery } from '@EntitySauce/Queries'
import LinkButton from '@Reusable/LinkButton'
import { entitySauce } from '@State'
import { Button, message, Popconfirm, Spin } from 'antd'
import { EventParamsTypes } from 'src/Types'

interface RemoveRepButtonProps {
  id: number,
  refetchData: () => void
}

const RemoveRepButton = ({ id, refetchData }: RemoveRepButtonProps) => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const [remove, { isLoading, status: postStatus }] = entitySauce.useRemove(
    removeSponsorAttendeesQuery(eventId, sponsorId, id)
  )

  const success = () => {
    message.success('Representative was removed successfully.')
  }

  const error = () => {
    message.error('We couldn\'t remove representative. Please try again.')
  }

  useEffect(() => {
    if (postStatus === 'success') {
      success()
      refetchData()
    }
    if (postStatus === 'error') {
      error()
    }
  }, [postStatus])

  const deleteButton = (
    <StyledDeleteButton type={'link'}>
      { isLoading ? <Spin size={'small'}/> : 'Remove'}
    </StyledDeleteButton>
  )

  return (
    <Popconfirm
      title={'Are you sure you want to remove this representative'}
      placement={'topRight'}
      onConfirm={remove}
    >
      <LinkButton style={{ color: '#C80F14' }} data-test={'remove-representative-link'}>{deleteButton}</LinkButton>
    </Popconfirm>
  )
}

export default RemoveRepButton

const StyledDeleteButton = styled(Button)({
  minWidth: 84,
  color: '#ff4d4f !important',
  '&:hover': {
    opacity: 0.7
  },
  '>span >svg': {
    width: 18,
    height: 18
  }
})
