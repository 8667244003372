import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import Spinner from '@Reusable/Spinner'
import PropTypes from 'prop-types'

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.95 }
})

const Container = styled('div')({
  textAlign: 'center',
  background: 'white',
  position: 'fixed',
  top: '0px',
  right: '0px',
  left: '0px',
  bottom: '0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100vh',
  zIndex: 1500,
  animation: `${fadeIn} 0.5s`
}, ({ transparent }) => ({
  opacity: transparent ? 0.95 : 1
}))

const FullScreenLoader = ({ visible, transparent }) => {
  if (!visible) return null

  return (
    <Container transparent={transparent}>
      <Spinner />
    </Container>
  )
}

FullScreenLoader.propTypes = {
  visible: PropTypes.bool,
  transparent: PropTypes.bool
}

FullScreenLoader.defaultProps = {
  visible: true,
  transparent: false
}

export default FullScreenLoader
