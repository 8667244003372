import { useState } from 'react'
import { Checkbox, Dropdown, Menu } from 'antd'
import { paramCase } from 'param-case'
import PropTypes from 'prop-types'

const CheckboxDropdown = (props) => {
  const { children, checkboxes, onChange, selected, ...dropdownProps } = props
  const [visible, setVisible] = useState(false)

  const _onCheck = (e) => {
    if (e.target.checked) {
      const newSelected = [...selected, e.target.value]
      onChange(newSelected)
    } else {
      const newSelected = selected.filter((value) => value !== e.target.value)
      onChange(newSelected)
    }
  }

  return (
    <div>
      <Dropdown
        trigger={['click']}
        visible={visible}
        onVisibleChange={setVisible}
        overlay={(
          <Menu>
            {checkboxes.map((checkbox) => (
              <Menu.Item
                key={checkbox.value}
              >
                <Checkbox
                  checked={selected.includes(checkbox.value)}
                  value={checkbox.value}
                  onChange={_onCheck}
                  data-test={`checkbox-dropdown-checkbox-${paramCase(checkbox.value)}`}
                >
                  {checkbox.title}
                </Checkbox>
              </Menu.Item>
            ))}
          </Menu>
        )}
        {...dropdownProps}
      >
        <div onClick={() => setVisible(!visible)}>
          {children}
        </div>
      </Dropdown>
    </div>
  )
}

CheckboxDropdown.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string
    })
  ),
  selected: PropTypes.arrayOf(
    PropTypes.string
  ),
  checkboxProps: PropTypes.object
}

export default CheckboxDropdown
