import { useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { MediaContext } from '@MediaContext'
import Card from '@Reusable/Card/Card'
import { Col, Row } from 'antd'

import { virtualBoothRouteConfig } from '../VirtualBooth'

import SideBarIntegrations from './SideBarIntegrations'
export type MediaDeckType = {
  bodyType: string,
  id: string,
  position: number,
  title: string,
  url: string
}

const MediaComponents = () => {
  const match = useRouteMatch()

  const [mediaState, setMediaState] = useState({})
  const [sideBarPreviewVisible, setSideBarPreviewVisible] = useState<boolean>(false)
  const [patchItemId, setPatchItemId] = useState<string | number | null>(null)

  const MediaContextProviderValue = {
    mediaState,
    setMediaState,
    patchItemId,
    setPatchItemId,
    sideBarPreviewVisible,
    setSideBarPreviewVisible
  }

  return (
    <MediaContext.Provider value={MediaContextProviderValue}>
      <Route path={`${match.path}${virtualBoothRouteConfig.path}`}>
        <Card testId={'side-bar-integrations'} margin={'24px'} padding={0}>
          <Row justify={'space-around'} gutter={16} >
            <Col className={'gutter-row'} span={24}>
              <SideBarIntegrations />
            </Col>
          </Row>
        </Card>
      </Route>
    </MediaContext.Provider>
  )
}

export default MediaComponents
