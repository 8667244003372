import styled from '@emotion/styled'
import useSponsorVisitorStatisticsDay from '@Hooks/useSponsorVisitorStatisticsDay'
import { DEFAULT_DATE_RANGE, useOverviewContext } from '@OverviewContext'
import Card from '@Reusable/Card/Card'
import DateRangePicker from '@Reusable/DateRangePicker/DateRangePicker'
import { getDaysBetweenDates } from '@Reusable/DateRangePicker/helpers'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment, { Moment } from 'moment-timezone'

import BarChart from './BarChart'
import { ExpandButton } from './ExpandButton'

const TooltipNode = (
  <span>
    {'See the number of unique booth visitors versus total visits by day. '}
    <a href={'https://help.brella.io/en/sponsors/overall-performance-booths-activity'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
  </span>
)

const DATE_FORMAT = 'YYYY-MM-DD'

const BoothVisitsActivity = ({ expanded }: {expanded?: boolean}) => {
  const {
    boothVisitsActivityDateRange: dateRange,
    setBoothVisitsActivityDateRange: setDateRange
  } = useOverviewContext()

  const onDateSelectChange = (dateRangeInput: [moment.Moment, moment.Moment]) => {
    if (!dateRangeInput) { setDateRange(DEFAULT_DATE_RANGE) }
    setDateRange(dateRangeInput)
  }
  const xAxisDays = dateRange ? getDaysBetweenDates(dateRange[0], dateRange[1]) : getDaysBetweenDates(DEFAULT_DATE_RANGE[0], DEFAULT_DATE_RANGE[1])

  function disabledDate(current: Moment) {
    return current && (current > moment().add(1, 'years') || current < moment().subtract(1, 'years'))
  }

  const screens = useBreakpoint()
  const shouldRenderExpandButton = screens.xl

  const {
    boothVisits,
    isFetching
  } = useSponsorVisitorStatisticsDay({
    startDate: moment(dateRange[0]).format('YYYY-MM-DD'),
    endDate: moment(dateRange[1]).format('YYYY-MM-DD')
  })

  return (
    <Card
      title={'Booth visits activity'}
      TooltipNode={TooltipNode}
      testId={'booth-visits-activity'}
      paddingTop={0}
      loading={isFetching}
    >
      <>
        {shouldRenderExpandButton && <ExpandButton />}
        <BarChart
          name= {'Booth visits activity'}
          totalVisits ={boothVisits.map((data) => data.totalBoothVisits)}
          uniqueVisitors={boothVisits.map((data) => data.totalUniqueVisitors)}
          xAxisValues = {xAxisDays}
          colors={[
            '#00AF66',
            '#BB16A3'
          ]}
          expanded={expanded}
        />
        <StyledDatePickerWrapper expanded={expanded}>
          <DateRangePicker
            value={dateRange}
            dateFormat={DATE_FORMAT}
            onChange={onDateSelectChange}
            allowClear={false}
            disabledDate={disabledDate}
            ranges={{
              'Event Duration': DEFAULT_DATE_RANGE,
              Today: [moment(), moment()]
            }}
          />
        </StyledDatePickerWrapper>
      </>
    </Card>
  )
}

export default BoothVisitsActivity

type StyledDatePickerWrapperProps = {
  expanded?: boolean
}

const StyledDatePickerWrapper = styled('div')<StyledDatePickerWrapperProps>({
}, ({ expanded }) => ({
  position: 'absolute',
  bottom: expanded ? 0 : 20,
  width: '100%',
  textAlign: 'center'
}))
