import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { EyeOutlined, PlusOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { useMediaContext } from '@MediaContext'
import { StyledFlex } from '@Reusable/Styled'
import TableTitle from '@SponsorCommon/TableTitle'
import { Button } from 'antd'
import { EventParamsTypes } from 'src/Types'

import { ModalStatus, ModalTypes } from './MediaModal'

interface HeaderProps {
  title: string,
  showModal: (status: ModalStatus) => void,
  type: ModalTypes,
  TooltipNode: ReactNode,
  showSideBarDrawer? : () => void,
  sideBarPreviewDisabled?: boolean,
  testId: string
}

const Header = ({ title, showModal, type, TooltipNode, showSideBarDrawer, sideBarPreviewDisabled, testId }: HeaderProps) => {
  const params = useParams<EventParamsTypes>()
  const { sponsorId } = params
  const { setMediaState } = useMediaContext()
  const _showModal = () => {
    showModal(ModalStatus.NEW)
    if (type === ModalTypes.SIDE_BAR_ITEM) {
      setMediaState({ mediaDeckItem: { bodyType: 'iframe', mediable_id: sponsorId } })
    }
    if (type === ModalTypes.CAROUSEL_SLIDE) {
      setMediaState({ mediaDeckItem: { mediable_id: sponsorId } })
    }
  }
  return (
    <StyledFlex justifyContent={'space-between'} margin={'0 0 16px'}>
      <TableTitle
        testId={testId}
        title={title}
        TooltipNode={TooltipNode}
      />
      <div>
        {showSideBarDrawer &&
        <Button onClick={showSideBarDrawer} data-test={'side-bar-preview-button'} disabled={sideBarPreviewDisabled}>
          <EyeOutlined style={{ marginTop: 4 }}/>
          <span style={{ marginLeft: 5 }}>{'Preview'}</span>
        </Button>}
        <StyledAddButton
          data-test={`new-${type}-modal-open-button`}
          onClick={_showModal}
          type={'primary'}
        >
          <PlusOutlined />
        </StyledAddButton>
      </div>
    </StyledFlex>
  )
}

export default Header

const StyledAddButton = styled(Button)({
  margin: '0 10px',
  width: 32,
  height: 32,
  padding: 0
})
