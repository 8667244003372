import styled from '@emotion/styled'
import LogOutSvg from '@SVG/logout.svg'
import { colors } from '@Theme'
import { Button } from 'antd'

interface LogOutButtonProps {
  noIcon?: boolean,
  red?: boolean
}
const LogOutButton = ({ noIcon, red } : LogOutButtonProps) => {
  const logOut = async() => {
    window.location.assign('/logging-out')
  }

  return (
    <StyledButton
      red={red}
      type={'text'} onClick={logOut}
      data-test={'logout-button'}
    >
      <p id={'logout'}>
        {!noIcon && <img src={LogOutSvg} alt={'logout'} />}
        {'Log out'}
      </p>
    </StyledButton>
  )
}

export default LogOutButton

type StyledButtonProps = {
  red?: boolean
}
const StyledButton = styled(Button)<StyledButtonProps>({
}, (props) => ({
  background: 'transparent !important',
  '&:hover': {
    '>p': {
      color: '#CF1322'
    }
  },
  '>p': {
    margin: 0,
    color: props.red ? '#CF1322' : colors.title,
    fontSize: 13,
    cursor: 'pointer',
    '>img': {
      marginRight: 5
    }
  }
}))
