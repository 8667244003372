import { useParams } from 'react-router-dom'
import { sponsorMeetingsQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'

import { EventParamsTypes } from '../Types'

const useSponsorMeetings = () => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { data, ...rest } = entitySauce.useGet(
    sponsorMeetingsQuery(eventId, sponsorId),
    { staleTime: 50000, refetchOnWindowFocus: false, enabled: !!eventId && !!sponsorId }
  )

  const { meta } = data
  const sponsorMeetings = data?.data ?? []
  return {
    sponsorMeetings: sponsorMeetings || [],
    meta,
    totalMeetings: meta?.totalCount,
    ...rest
  }
}

export default useSponsorMeetings
