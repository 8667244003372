import './Root.css'

import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Layout } from 'antd'
import useAdminInvite from 'src/Modules/Invite/Hooks/useAdminInvite'

import { GlobalContext } from '../GlobalContext/GlobalContext'
import Header from '../Modules/Header/Header'
import TermsOfUse from '../Modules/TermsOfUse/TermsOfUse'

import RoutesComponent from './Routes/RoutesComponent'

const Root = () => {
  const { acceptInvite } = useAdminInvite()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [pastChecked, setPastChecked] = useState<boolean>(true)
  const [filteredEvents, setFilteredEvents] = useState([])

  const contextValue = {
    searchTerm,
    setSearchTerm,
    pastChecked,
    setPastChecked,
    filteredEvents,
    setFilteredEvents
  }

  useEffect(() => {
    document.title = 'Brella | Sponsor Panel'
  }, [])

  useEffect(function acceptInviteOnRootLoad() {
    acceptInvite()
  }, [])

  return (
    <GlobalContext.Provider value={contextValue}>
      <Layout>
        <TermsOfUse />
        <Header />
        <StyledLayoutContent>
          <RoutesComponent />
        </StyledLayoutContent>
      </Layout>
    </GlobalContext.Provider>
  )
}

export default Root

const StyledLayoutContent = styled('div')({
  marginTop: 64,
  marginBottom: 64,
  height: '100%'
})
