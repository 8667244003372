import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import AuthService from '@Auth'
import Spinner from '@Reusable/Spinner'
import { message } from 'antd'

import { AUTH_STATE } from './Constants'
import { useAuth } from './useAuth'

function SocialLoginRedirect() {
  const history = useHistory()
  const auth = useAuth()
  const from = localStorage.getItem('redirect-url-after-login')

  useEffect(() => {
    async function waitForOauth() {
      try {
        const redirectResult = await AuthService.waitForRedirectResult()

        if (redirectResult) {
          AuthService.setOauthUser(redirectResult.oauth?.userInfo, redirectResult.oauth?.provider)

          const token = redirectResult.magic.idToken
          const { email } = redirectResult.oauth.userInfo

          const res = await auth.getProviderByEmail({ email, token })

          if (res.user) {
            auth.setAuthState(AUTH_STATE.AUTHENTICATED)
            history.replace(from)
          } else {
            history.replace('/create-account', { email, from })
          }
        }
      } catch (error) {
        console.error(error)
        message.loading('Couldn\'t get user details from social login provider. Redirecting back to login page.', 8, () => {
          window.location.href = '/login'
        })
      }
    }

    waitForOauth()
  }, [])

  return (
    <Spinner text={'Loading authentication details...'} />
  )
}

export default SocialLoginRedirect
