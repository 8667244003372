/* eslint-disable @typescript-eslint/no-explicit-any */
import Highlighter from 'react-highlight-words'
import { MoreOutlined } from '@ant-design/icons'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { eventSummaryExportQuery } from '@EntitySauce/Queries'
import CheckboxDropdown from '@Reusable/CheckboxDropdown'
import ExportCsvButtonGet from '@Reusable/ExportCsvButton/ExportCsvButtonGet'
import IntersectionDetector from '@Reusable/IntersectionDetector'
import LinkButton from '@Reusable/LinkButton'
import { Badge, Dropdown, Menu } from 'antd'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'
import { MenuInfo } from 'rc-menu/lib/interface'

import { useGlobalContext } from '../../../GlobalContext/GlobalContext'

import { ColumnToolButton } from './EventsTable'
import { TOGGLEABLE_COLUMNS } from './helpers'

const _renderStatus = (
  record: {
    timeZone: string,
    startDate: moment.MomentInput,
    endDate: moment.MomentInput,
    past: never
  }
) => {
  const isOngoing = moment().tz(record.timeZone).isBetween(
    moment(record.startDate),
    moment(record.endDate).add(1, 'days')
  )

  if (isOngoing) {
    return (
      <AnimatedBadge
        data-test={'event-status'}
        status={'success'}
        text={'Live'}
      />
    )
  }

  return (
    <Badge
      data-test={'event-status'}
      status={record.past ? 'default' : 'success'}
      text={record.past ? 'Ended' : 'Upcoming'}
    />
  )
}

export const renderDateColumn = (
  startDate: moment.MomentInput,
  record:
  {
    endDate: moment.MomentInput,
    startDate: moment.MomentInput
  }
) => {
  const duration = moment.utc(record.endDate).diff(moment.utc(record.startDate), 'days') + 1
  const displayDate = moment.parseZone(startDate).format('D MMM YYYY')

  return (
    <>
      <Date data-test={'event-table-date'}>{displayDate}</Date>
      <SmallText data-test={'event-table-duration'}>
        {duration}
        {duration === 1 ? ' day' : ' days'}
      </SmallText>
    </>
  )
}

export const renderNameColumn = (name: string, record: any) => {
  return (
    <NameColumn name={name} record={record} />
  )
}

const NameColumn = ({ name, record }: any) => {
  const { searchTerm } = useGlobalContext()
  return (
    <IntersectionDetector >
      <EventName data-test={'event-table-event-name'}>
        <Highlighter
          highlightClassName={'search-highlight'}
          searchWords={[searchTerm]}
          autoEscape={true}
          textToHighlight={name}
        />
      </EventName>
      {record?.organization?.name && (
        <SmallText>{record.organization.name}</SmallText>
      )}
      {_renderStatus(record)}
    </IntersectionDetector>
  )
}

export const renderSponsorColumn = (sponsor: any) => {
  return (
    <SponsorColumn sponsor={sponsor} />
  )
}

const SponsorColumn = ({ sponsor }: any) => {
  const { name } = sponsor
  const { searchTerm } = useGlobalContext()
  return (
    <IntersectionDetector >
      <span data-test={'event-table-sponsor-name'}>
        <Highlighter
          highlightClassName={'search-highlight'}
          searchWords={[searchTerm]}
          autoEscape={true}
          textToHighlight={name}
        />
      </span>
    </IntersectionDetector>
  )
}

export const renderCountryColumn = (country: string) => {
  return (
    <div data-test={'events-list-country'}>
      {country}
    </div>

  )
}

const _onAction = ({ domEvent }: MenuInfo) => {
  domEvent.stopPropagation()
}

const renderExportCSV = (eventId: number) => {
  return (
    <Menu>
      <Menu.Item
        key={'test'}
        onClick={(e) => _onAction(e)}
        data-test={'event-table-action-export-csv'}
      >
        <ExportCsvButtonGet
          buttonType={'text'}
          title={'Export to CSV'}
          query={eventSummaryExportQuery([eventId])}
        />
      </Menu.Item>
    </Menu>
  )
}

export const renderActionsColumn = (eventId: number) => {
  return (
    <Dropdown
      overlay={() => renderExportCSV(eventId)}
      trigger={['click']}
      placement={'topCenter'}
    >
      <ActionButton
        onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
        data-test={'event-table-action-menu-button'}
      >
        <MoreOutlined type={'more'} />
      </ActionButton>
    </Dropdown>
  )
}

interface RenderTableToolsProps {
  visibleColumns: string[],
  setVisibleColumns: (visibleEventTableColumns: string[]) => void
}

export const RenderTableTools = ({ visibleColumns, setVisibleColumns }: RenderTableToolsProps) => {
  return (
    <CheckboxDropdown
      checkboxes={TOGGLEABLE_COLUMNS}
      selected={visibleColumns}
      onChange={setVisibleColumns}
    >
      <ColumnToolButton
        tabIndex={-1}
        data-test={'event-table-tools-button'}
      />
    </CheckboxDropdown>
  )
}

const Date = styled('div')({
}, (props) => ({
  color: props.theme.colors.secondaryText
}))

const SmallText = styled('div')({
}, (props) => ({
  color: props.theme.colors.secondaryText,
  fontSize: 12
}))

const EventName = styled('h2')({
  fontSize: 16,
  color: 'inherit',
  marginBottom: 0,
  lineHeight: 1.5
})

const waveKeyframes = keyframes`
  from {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.8);
  },
  to {
    box-shadow: 0 0 0 6px rgba(82, 196, 26, 0);
  }
`

const AnimatedBadge = styled(Badge)({
  '.ant-badge-status-dot': {
    animation: `${waveKeyframes} 2s ease infinite`
  }
})

const ActionButton = styled(LinkButton)({
  padding: 10,
  height: 'auto',
  color: 'inherit',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 0,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.06)'
  }
})
