export enum EventTypes {
  OWN = 'my',
  ALL = 'all'
}

export enum FilterEventsActionType {
  SEARCH = 'search',
  SHOW_PAST = 'showPast'
}

export type FilterEventsAction =
  | { type: FilterEventsActionType.SEARCH, payload: string }
  | { type: FilterEventsActionType.SHOW_PAST, payload: boolean }
