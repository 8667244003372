import { useReducer } from 'react'
import useVisitors from '@Hooks/useVisitors'

import Analytics from './Analytics'

type State = {
  pageSize: number,
  pageNumber: number
}

const initialState: State = {
  pageSize: 20,
  pageNumber: 1
}

enum actionTypes {
  SET_PAGE_SIZE = 'setPageSize',
  SET_PAGE_NUMBER = 'setPageNumber'
}

type Action =
 { type: actionTypes.SET_PAGE_SIZE | actionTypes.SET_PAGE_NUMBER, payload: number }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionTypes.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload
      }
    case actionTypes.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload
      }
    default:
      return { ...state }
  }
}

function TrafficAnalytics(): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { visitors, isFetching, meta } = useVisitors({
    page: { number: state.pageNumber, size: state.pageSize }
  })

  const _setPageNumber = (pageNumber: number) => {
    dispatch({
      type: actionTypes.SET_PAGE_NUMBER,
      payload: pageNumber
    })
  }

  const _setPageSize = (pageSize: number) => {
    dispatch({
      type: actionTypes.SET_PAGE_SIZE,
      payload: pageSize
    })
  }

  return (
    <Analytics
      loading={isFetching}
      visitorsInfo={visitors}
      setPageSize={_setPageSize}
      setPageNumber={_setPageNumber}
      pageProps={state}
      visitorsMeta={meta}
    />
  )
}

TrafficAnalytics.routeConfig = {
  path: '/traffic-analytics'
}

export default TrafficAnalytics
