import { ArrowLeftOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import theme from '@Theme'
import { Tooltip } from 'antd'

import ActionButtons from '../Modules/VirtualBooth/SponsorForm/ActionButtons'

interface BackButtonProps {
  onClick: () => void,
  style?: React.CSSProperties,
  disabled?: boolean
}

const BackButton = ({ onClick, style, disabled }: BackButtonProps) => {
  const tooltipTitle = (
    <StyledToolTipContainer>
      <p>{'Unsaved changes in Virtual booth'}</p>
      <ActionButtons/>
    </StyledToolTipContainer>

  )
  return (
    <div style={style}>
      {disabled
        ? <Tooltip color={theme.colors.primaryBackground} placement={'topLeft'} title={tooltipTitle}>
          <StyledBack onClick={disabled ? undefined : onClick} disabled={disabled}>
            <ArrowLeftOutlined />
          </StyledBack>
        </Tooltip>
        : <StyledBack onClick={disabled ? undefined : onClick} disabled={disabled}>
          <ArrowLeftOutlined />
        </StyledBack>
      }
    </div>
  )
}

export default BackButton

const StyledToolTipContainer = styled.div({
}, (props) => ({
  padding: 5,
  color: props.theme.colors.text
}))

type StyledBackProps = {
  disabled?: boolean
}
const StyledBack = styled.div<StyledBackProps>({
}, (props) => ({
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 16,
  color: 'gray',
  ':hover': {
    color: props.disabled ? 'gray' : 'black'
  }
}))
