import { DatePicker as antDDatePicker } from 'antd'
/* eslint-disable no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment'
import { Moment } from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface'

const { RangePicker } = antDDatePicker

export type DateRangeType = [moment.Moment, moment.Moment]
interface DatePickerProps {
  value: DateRangeType,
  dateFormat: string,
  onChange: (values: [moment.Moment, moment.Moment]) => void,
  allowClear: boolean,
  disabledDate?: (current: Moment) => boolean,
  ranges?:
  Record<string, Exclude<RangeValue<Moment>, null> |
  (() => Exclude<RangeValue<Moment>, null>)>
}

const DateRangePicker = ({
  value,
  dateFormat,
  onChange,
  allowClear,
  disabledDate,
  ranges
}: DatePickerProps) => {
  return (
    <RangePicker
      value={value}
      onChange={onChange as (values: RangeValue<Moment>) => void}
      format={dateFormat}
      allowClear={allowClear}
      disabledDate={disabledDate}
      ranges={ranges}
    />
  )
}

export default DateRangePicker
