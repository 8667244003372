import { OAuthExtension } from '@magic-ext/oauth'
import { Magic } from 'magic-sdk'

/**
 * @module
 * This module uses {@link https://docs.magic.link/|Magic Link} to handle user authentication.
 */

const magic = new Magic(import.meta.env.VITE_MAGIC_API_KEY, {
  extensions: [new OAuthExtension()],
  testMode: !!window.Cypress
})

// Expose magic to window so we can generate tokens from console
window.magic = magic

/**
 * Starts the OAuth login flow with a selected provider.
 *
 * @param {string} provider
 * Auth provider: 'google', 'facebook', 'apple', or 'github'.
 *
 * @param {string} redirectUri
 * Uri to redirect after successful login.
 *
 * @example
 * try {
 *   await loginWithRedirect('google', 'http://localhost:19006/login')
 *   console.log('Login success!')
 * } catch {
 *  console.log('Login failed!')
 * }
 */
async function loginWithRedirect(provider, redirectUri) {
  return magic.oauth.loginWithRedirect({
    provider,
    redirectURI: redirectUri
  })
}

/**
 * Wait for an OAuth result after a login with {@link loginWithRedirect}
 *
 * @param {func} callback
 * Callback to retrieve the auth result: <code>(result) => ...</code>
 *
 * @example
 * const result = await auth.waitForRedirectResult()
 * // Set auth token, user data, etc.
 * setAuthToken(result.magic.idToken)
 */
async function waitForRedirectResult() {
  return magic.oauth.getRedirectResult()
}

function setOauthUser(user, provider) {
  if (!user) return
  if (!provider) return

  // Need to check like this. localStorage writes "undefined" string if value is not available.
  user.email && localStorage.setItem('email', user.email)
  user.givenName && localStorage.setItem('first-name', user.givenName)
  user.familyName && localStorage.setItem('last-name', user.familyName)
  user.picture && localStorage.setItem('avatar', user.picture)
}

function getOauthUser() {
  return {
    email: localStorage.getItem('email'),
    'first-name': localStorage.getItem('first-name'),
    'last-name': localStorage.getItem('last-name'),
    avatar: localStorage.getItem('avatar')
  }
}

function clearAll() {
  localStorage.removeItem('email')
  localStorage.removeItem('first-name')
  localStorage.removeItem('last-name')
  localStorage.removeItem('avatar')
  localStorage.removeItem('jwt')
  localStorage.removeItem('redirect-url-after-login')
  localStorage.removeItem('no-account')
  localStorage.removeItem('authHeaders')
}

function clearOauthUser() {
  localStorage.removeItem('first-name')
  localStorage.removeItem('last-name')
  localStorage.removeItem('avatar')
}

const auth = {
  magic,
  loginWithRedirect,
  waitForRedirectResult,
  getOauthUser,
  setOauthUser,
  clearAll,
  clearOauthUser
}

export default auth
