import { useRef } from 'react'
import ReactECharts from 'echarts-for-react'

import { grid, legend, tooltip } from '../Reusable/Common'
import { SaveChartButton } from '../Reusable/SaveChartButton'
import { ChartProps } from '../Reusable/types'

import { EXPANDED_MODAL_HEIGHT } from './Common'

interface BarChartProps extends ChartProps {
  uniqueVisitors: number[],
  totalVisits: number[],
  xAxisValues: string[],
  expanded?: boolean
}

const BarChart = ({ xAxisValues, totalVisits, uniqueVisitors, colors, name, expanded }: BarChartProps) => {
  const instance = useRef(null)
  const option = {
    backgroundColor: '#ffffff',
    tooltip,
    color: colors,
    grid: { ...grid, top: 60, bottom: 50 },
    legend: {
      ...legend,
      data: ['Total visits', 'Unique visits']
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        data: xAxisValues
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Unique visits',
        position: 'right',
        minInterval: 1
      },
      {
        type: 'value',
        name: 'Total visits',
        position: 'left',
        minInterval: 1
      }
    ],
    series: [
      {
        name: 'Unique visits',
        type: 'bar',
        data: uniqueVisitors,
        barMaxWidth: 40,
        yAxisIndex: 0
      },
      {
        name: 'Total visits',
        type: 'line',
        data: totalVisits,
        yAxisIndex: 1

      }
    ]
  }

  return (
    <>
      <SaveChartButton instance={instance} name={name}/>
      <ReactECharts
        ref={instance}
        option={option}
        style={{ height: expanded ? EXPANDED_MODAL_HEIGHT : 450, marginTop: 12, width: '100%' }}
      />
    </>
  )
}

export default BarChart
