import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CloudDownloadOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { headerDefinitions } from '@EntitySauce/Api/ApiHeaders'
import { leadQuery } from '@EntitySauce/Queries'
import useEvents from '@Hooks/useEvents/useEvents'
import Card from '@Reusable/Card/Card'
import { entitySauce } from '@State'
import theme from '@Theme'
import { Button, message, Popconfirm, Radio, Space, Table, Tag, Tooltip } from 'antd'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'
import { EventParamsTypes } from 'src/Types'

import { LeadData, LeadEditData } from './interfaces'
import LeadEditForm from './LeadEditForm'

import type { ColumnsType, TablePaginationConfig } from 'antd/es/table'

type LeadColumnsType = ColumnsType<LeadData>
type ScanningDirection = 'outbound' | 'inbound'

const DEFAULT_PAGINATION_CONFIG = { page: { number: 1, size: 10 } }

function Leads(): JSX.Element {
  const [showEditModal, setShowEditModal] = useState(false)
  const [editLeadId, setEditLeadId] = useState<string | null>(null)
  const [editLeadName, setEditLeadName] = useState('')
  const [editLeadNotes, setEditLeadNotes] = useState('')
  const [scanningDirection, setScanningDirection] = useState<ScanningDirection>('outbound')
  const [paginationConfig, setPaginationConfig] = useState(DEFAULT_PAGINATION_CONFIG)
  const { events } = useEvents()
  const params = useParams<EventParamsTypes>()

  const { data: { data: leads, meta: leadsMeta }, isFetching: isFetchingLeads, refetch: refetchLeads } = entitySauce.useGet(
    leadQuery(params.eventId, params.sponsorId, {
      page: {
        size: paginationConfig.page.size,
        number: paginationConfig.page.number
      },
      scanned_by: scanningDirection === 'inbound' ? 'others' : 'self'
    }),
    { refetchOnWindowFocus: false }
  )

  const leadsCount = leadsMeta?.totalCount || 0

  const [deleteLead] = entitySauce.useRemove(
    leadQuery(params.eventId, params.sponsorId),
    {
      onSuccess: () => {
        message.success('Lead deleted successfully.')
        refetchLeads()
      },
      onError: () => {
        message.error('Couldn\'t delete the lead. Please try again.')
      }
    }
  )

  const [patchLead] = entitySauce.usePatch(leadQuery(params.eventId, params.sponsorId), {
    onSuccess: () => {
      message.success('Changes saved successfully.')
      refetchLeads()
    },
    onError: () => {
      message.error('Couldn\'t save changes. Please try again.')
    },
    onSettled: () => {
      onEditCancel()
    }
  })

  const onEditCancel = () => {
    setShowEditModal(false)
    setEditLeadId(null)
    setEditLeadName('')
    setEditLeadNotes('')
  }

  const onEditSubmit = (data: LeadEditData) => {
    patchLead({
      id: editLeadId,
      data: data
    })
  }

  const onDelete = (id: string) => {
    deleteLead({
      id: id
    })
  }

  const onTableChange = (pagination: TablePaginationConfig) => {
    setPaginationConfig((prevState) => ({
      ...prevState,
      page: {
        size: pagination.pageSize ?? prevState.page.size,
        number: pagination.current ?? prevState.page.number
      }
    }))
  }

  const isLoading = isFetchingLeads

  const columnsBase: LeadColumnsType = [
    {
      title: 'Prospect name',
      key: 'prospect-name',
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection)

        if (attendee) {
          return `${attendee.firstName} ${attendee.lastName}`
        }

        return <Tag>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Prospect email',
      key: 'prospect-email',
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection)

        if (attendee) {
          return attendee.email
        }

        return <Tag>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Prospect company',
      key: 'prospect-company',
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection)

        if (attendee) {
          return attendee.companyName
        }

        return <Tag>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Prospect title',
      key: 'prospect-title',
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection)

        if (attendee) {
          return attendee.companyTitle
        }

        return <Tag>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Scanning time',
      dataIndex: 'createdAt',
      render: (value: string) => {
        return moment.tz(value, events[0]?.timeZone).format('DD.MM.YYYY HH:mm Z z')
      }
    }
  ]

  const columnsOutbound: LeadColumnsType = [
    {
      title: 'Who scanned',
      key: 'who-scanned',
      render: (value: LeadData) => {
        if (value.createdBy) {
          return `${value.createdBy.firstName} ${value.createdBy.lastName}`
        }

        return <Tag>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      className: 'notes',
      width: '300px'
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (value: LeadData) => (
        <>
          <Popconfirm
            title={'Are you sure you want to delete this prospect?'}
            onConfirm={() => onDelete(value.id)}
            okText={'Yes'}
            cancelText={'No'}
          >
            <Button type={'link'} danger data-test={'delete-lead'}>{'Delete'}</Button>
          </Popconfirm>
          <Button
            type={'link'}
            data-test={'edit-lead'}
            onClick={() => {
              setEditLeadId(value.id)
              setEditLeadName(getEditLeadName(value))
              setEditLeadNotes(value.notes)
              setShowEditModal(true)
            }}
          >
            {'Edit'}
          </Button>
        </>
      )
    }
  ]

  const columnsInbound: LeadColumnsType = [
    {
      title: 'Scanned representative',
      key: 'scanned-rep',
      render: (value: LeadData) => {
        if (value.attendee) {
          return `${value.attendee.firstName} ${value.attendee.lastName}`
        }

        if (value.invite) {
          return `${value.invite.firstName} ${value.invite.lastName}`
        }

        return <Tag>{'Deleted user'}</Tag>
      }
    }
  ]

  const columns = scanningDirection === 'outbound' ? [...columnsBase, ...columnsOutbound] : [...columnsBase, ...columnsInbound]

  return (
    <Card
      title={'Prospects'}
      testId={'prospects'}
      loading={isLoading}
      extra={

        <Button
          className={'export'}
          data-test={'export-data-button'}
          onClick={() => exportCSV(params.eventId, params.sponsorId, scanningDirection)}
          icon={<CloudDownloadOutlined />}
        >
          {`Export ${scanningDirection === 'outbound' ? 'Outbound' : 'Inbound'} CSV`}
        </Button>
      }
    >
      <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
        <Radio.Group value={scanningDirection} onChange={(e) => setScanningDirection(e.target.value)}>
          <Tooltip
            title={'Attendees scanned by your representatives'}
            mouseEnterDelay={1}
            color={'rgba(0,0,0,0.95)'}
            overlayInnerStyle={{ color: '#fff' }}
          >
            <Radio.Button value={'outbound'}>{'Outbound leads'}</Radio.Button>
          </Tooltip>
          <Tooltip
            title={'Attendees who scanned your representatives'}
            mouseEnterDelay={1}
            color={'rgba(0,0,0,0.95)'}
            overlayInnerStyle={{ color: '#fff' }}
          >
            <Radio.Button value={'inbound'}>{'Inbound leads'}</Radio.Button>
          </Tooltip>
        </Radio.Group>
        <StyledTable
          data-test={'leads-table'}
          dataSource={leads}
          columns={columns as ColumnsType<object>}
          rowKey={'id'}
          rowClassName={'scanned-lead-row'}
          style={{ width: '100%' }}
          loading={isLoading}
          bordered
          pagination={{
            current: paginationConfig.page.number,
            pageSize: paginationConfig.page.size,
            total: leadsCount
          }}
          scroll={{ x: 'max-content' }}
          onChange={onTableChange}
        />
      </Space>
      <LeadEditForm
        showEditModal={showEditModal}
        editLeadName={editLeadName}
        editLeadNotes={editLeadNotes}
        onEditCancel={onEditCancel}
        onEditSubmit={onEditSubmit}
      />
    </Card>
  )
}

const getProspectAttendee = (rowData: LeadData, scanningDirection: ScanningDirection): LeadData['attendee'] | LeadData['invite'] | LeadData['createdBy'] | undefined => {
  // createdBy, attendee, and invite are all attendee data, but with different key

  if (scanningDirection === 'inbound') {
    return rowData.createdBy
  }

  if (rowData.attendee) {
    return rowData.attendee
  }

  if (rowData.invite) {
    if (rowData.invite.firstName && rowData.invite.lastName) {
      return rowData.invite
    }
  }
}

const getEditLeadName = (value: LeadData) => {
  if (value.attendee?.firstName && value.attendee?.lastName) {
    return `${value.attendee.firstName} ${value.attendee.lastName}`
  }

  if (value.attendee?.email) {
    return value.attendee.email
  }

  if (value.invite?.firstName && value.invite?.lastName) {
    return `${value.invite.firstName} ${value.invite.lastName}`
  }

  if (value.invite?.email) {
    return value.invite.email
  }

  return 'Deleted'
}

const exportCSV = async(eventId: number, sponsorId: number, scanningDirection: ScanningDirection) => {
  const url = `${import.meta.env.VITE_API_URL}/admin_panel/events/${eventId}/sponsors/${sponsorId}/leads/export?scanned_by=${scanningDirection === 'inbound' ? 'others' : 'self'}`
  const authHeadersString = window.localStorage.getItem('authHeaders')
  const authHeadersObj = JSON.parse(authHeadersString ?? '{}')

  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        ...authHeadersObj,
        'Cache-Control': 'no-cache',
        ...headerDefinitions.v4.headers
      }
    })

    if (response.ok) {
      message.success('Your CSV export will be delivered by email shortly')
    } else {
      message.error('Error when trying to export CSV')
    }
  } catch (error) {
    console.error(error)
    message.error('Error when trying to export CSV')
  }
}

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: ${theme.colors.lightGrayBackground} !important;
  }

  .ant-table-cell a[class="delete-lead"] {
    color: red !important;
  }
`

Leads.routeConfig = {
  path: '/prospects'
}

export default Leads
