import { PlaySquareOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { colors } from '@Theme'
import { Switch } from 'antd'

interface AlertToggleProps {
  title: string,
  description: string,
  learnMoreHref?: string,
  enabled: boolean,
  onChange: (enabled: boolean) => void,
  loading: boolean,
  testId: string
}

const AlertToggle = ({ title, description, learnMoreHref, enabled, onChange, loading, testId }: AlertToggleProps) => {
  return (
    <AlertToggleContainer enabled={enabled}>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {learnMoreHref &&
        <LearnMore>
          <PlaySquareOutlined />
          <a href={learnMoreHref} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
        </LearnMore>
        }
      </div>
      <Switch
        checked={enabled} onChange={onChange} disabled={loading}
        data-test={`${testId}-alert-toggle`}
      />
    </AlertToggleContainer>
  )
}

export default AlertToggle

type AlertToggleContainerProps = {
  enabled: boolean
}

const AlertToggleContainer = styled('div')<AlertToggleContainerProps>({
}, ({ enabled }) => ({
  background: enabled ? colors.dewBackground : colors.disabledBackground,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '20px 34px',
  textAlign: 'left'
}))

const Title = styled('h3')({
  color: colors.lightGrayText,
  fontWeight: 600,
  fontSize: 16
})

const Description = styled('p')({
  color: colors.secondaryText
})

const LearnMore = styled('div')({
  display: 'flex',
  alignContent: 'center',
  '&:hover': {
    a: {
      color: colors.primary,
      textDecoration: 'underline'
    }
  },
  a: {
    color: colors.primary,
    marginLeft: 9
  },
  span: {
    margin: 'auto 0',
    svg: {
      color: colors.primary

    },
    alignSelf: 'center'
  }
})
