/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'

export const DateTimeFormat = {
  '%-d.%-m.%Y': 'D.M.YYYY', // EU
  '%-m.%-d.%Y': 'M.D.YYYY', // NA
  '%H:%M': 'HH:mm',
  '%I:%M %p': 'hh:mm A'
}

export const backendDateFormat = 'YYYY-MM-DD'

export const formatDateToTitle = (date: moment.MomentInput, dateFormat?: string | undefined) => {
  if (!date) return

  if (dateFormat === DateTimeFormat['%-d.%-m.%Y']) {
    return moment.utc(date).format('dddd Do of MMM YYYY')
  } else {
    return moment.utc(date).format('dddd MMM Do YYYY')
  }
}

export const formatDateToXsMobileTitle = (date: moment.MomentInput, dateFormat: string) => {
  if (!date) return

  if (dateFormat === DateTimeFormat['%-d.%-m.%Y']) {
    return moment.utc(date).format('ddd Do of MMM YYYY')
  } else {
    return moment.utc(date).format('ddd MMM Do YYYY')
  }
}

export const formatDateToXxsMobileTitle = (date: moment.MomentInput, dateFormat: string) => {
  if (!date) return

  if (dateFormat === DateTimeFormat['%-d.%-m.%Y']) {
    return moment.utc(date).format('Do of MMMM')
  } else {
    return moment.utc(date).format('MMMM Do')
  }
}

export const strfTimeHoursToMoment = (timeFormat: string | string[]) => timeFormat.includes('%p') ? 'hh:mm A' : 'HH:mm'
export const strfTimeHas12Hours = (timeFormat: string | string[]) => timeFormat.includes('%p')

export const getFormattedDuration = (durationSeconds: moment.DurationInputArg1) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDuration = (result: any, entry: { value: any, postfix: any }) => {
    if (!entry.value) {
      return result
    }

    return `${result ? `${result} ` : ''}${entry.value}${entry.postfix}`
  }

  const duration = moment.duration(durationSeconds, 'seconds')
  const days = duration.days()
  const hours = duration.subtract(days, 'days').hours()
  const minutes = duration.subtract(hours, 'hours').minutes()
  const seconds = duration.subtract(minutes, 'minutes').seconds()

  const entries = [
    { value: days, postfix: 'd' },
    { value: hours, postfix: 'h' },
    { value: minutes, postfix: 'm' },
    { value: seconds, postfix: 's' }
  ]

  return entries.reduce(getDuration, '')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDatesBetween = (startDate: { clone: () => any }, endDate: any) => {
  const now = startDate.clone()
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(backendDateFormat))
    now.add(1, 'days')
  }

  return dates
}

export const convertValueToTimeZone = (value: moment.MomentInput, timeZone: string, format = '') => {
  return moment(value, format).tz(timeZone)
}
