/**
 * Triggers a file download from the browser.
 * @param   {string}  filename  A name for the downloaded file.
 * @param   {object}  data      Content of the file.
 */
const donwload = (filename, data) => {
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(data, filename)
  }

  // Create a hidden link
  const element = document.createElement('a')
  document.body.appendChild(element)
  element.setAttribute('href', window.URL.createObjectURL(data))
  element.setAttribute('download', filename)
  element.style.display = 'none'

  element.click()
  document.body.removeChild(element)
}

export default donwload
