import { useEffect } from 'react'
import styled from '@emotion/styled'
import useSponsorAttendees from '@Hooks/useSponsorAttendees'

import { useCompanyDetailsTabsNavContext } from './CompanyDetailsTabsNavContext'
import PartnerAttendeeList from './PartnerAttendeeList'

const CompanyRepresentatives = () => {
  const { attendees: sponsorAttendees, isFetching } = useSponsorAttendees()
  const { setAttendeesLength } = useCompanyDetailsTabsNavContext()

  useEffect(() => {
    setAttendeesLength(sponsorAttendees.length)
  }, [sponsorAttendees.length])

  return (
    <CompanyRepresentativesContainer>
      <PartnerAttendeeList
        sponsorAttendees={sponsorAttendees}
        fetchingAttendees={isFetching}
      />
    </CompanyRepresentativesContainer>
  )
}

export default CompanyRepresentatives

const CompanyRepresentativesContainer = styled('div')({
  maxHeight: 800,
  overflow: 'auto'
})
