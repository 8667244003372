import * as R from 'ramda'

const DEFAULT_DELIMETER = ','

/**
 * Converts array of data objects to a csv blob.
 * @param   {array}   data      Data objects to be exported.
 * @param   {object}  schema    Data field mapping to csv columns.
 *                              e.g. const schema = { columnHeader: 'dataFieldName' }
 *                              If undefined, data object keys will be used as column headers.
 * @returns {Blob}              Csv data as a blob
 */
const csv = (data, schema) => {
  if (!data || data.length === 0) {
    throw new Error('No data to be exported!')
  }

  const headers = schema
    ? R.keys(schema)
    : R.keys(data[0]).map((key) => `"${key}"`)

  const lines = [headers.join(DEFAULT_DELIMETER)]
  const dataFields = schema ? R.values(schema) : R.keys(data[0])

  data.forEach((entry) => {
    const line = dataFields.map((field) => {
      const value = entry[field]

      // Put string values in quotes
      if (typeof value === 'string') {
        return `"${value}"`
      }

      return value
    })

    lines.push(line.join(DEFAULT_DELIMETER))
  })

  return new Blob([lines.join('\n')], { type: 'text/csv;charset=utf-8;' })
}

export default csv
