import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { Button, Form, Input, message } from 'antd'
import PropTypes from 'prop-types'

import { AUTH_STATE } from './Constants'
import { useAuth } from './useAuth'

export const PASSWORD_RESET_URL =
  import.meta.env.VITE_APP_ENVIRONMENT === 'sandbox'
    ? 'https://password-reset-sandbox.brella.ninja'
    : 'https://password-reset.brella.io'

const AUTH_SCREEN_STATE = {
  INITIAL: 'INITIAL',
  HAS_PASSWORD: 'HAS_PASSWORD',
  NO_PASSWORD: 'NO_PASSWORD',
  NO_USER: 'NO_USER',
  MAGIC_LINK_USER: 'MAGIC_LINK_USER'
}

const STATE = {
  [AUTH_SCREEN_STATE.INITIAL]: {
    footerButtonText: 'Continue'
  },
  [AUTH_SCREEN_STATE.HAS_PASSWORD]: {
    footerButtonText: 'Sign In'
  },
  [AUTH_SCREEN_STATE.NO_USER]: {
    footerButtonText: 'Create Account'
  },
  [AUTH_SCREEN_STATE.MAGIC_LINK_USER]: {
    footerButtonText: 'Sign In with Password'
  }
}

const SignInWithEmail = ({ initialPath }) => {
  const [form] = Form.useForm()
  const passwordInputRef = useRef(null)
  const history = useHistory()
  const searchParams = (new URL(document.location)).searchParams
  const [state, setState] = useState(AUTH_SCREEN_STATE.INITIAL)
  const from = searchParams.get('from') || initialPath || '/'
  const auth = useAuth()

  async function onSubmit({ email, password }) {
    if (!email || !email.length) return

    switch (state) {
      case AUTH_SCREEN_STATE.INITIAL:
        onEmailSubmit({ email })
        break

      case AUTH_SCREEN_STATE.MAGIC_LINK_USER:
      case AUTH_SCREEN_STATE.HAS_PASSWORD:
        await auth.signIn({
          email,
          password,
          onSuccess: () => {
            history.replace({ pathname: from })
          },
          onError: (errorMessage) => message.error('Sign in failed: ' + errorMessage)
        })
        break

      default:
        break
    }
  }

  async function onEmailSubmit({ email }) {
    try {
      const res = await auth.getProviderByEmail({ email })

      // If we have user and password, we ask for password
      if (res.user && res.password) {
        setState(AUTH_SCREEN_STATE.HAS_PASSWORD)
        passwordInputRef.current?.focus()
        return
      }

      // If we have user and no password, we ask for password reset
      if (res.user && !res.password) {
        setState(AUTH_SCREEN_STATE.MAGIC_LINK_USER)
        window.open(`${PASSWORD_RESET_URL}?email=${encodeURIComponent(form.getFieldValue('email'))}&type=magic`, '_blank')
        return
      }

      // If we don't have user, we ask to create account
      if (!res.user) {
        setState(AUTH_SCREEN_STATE.NO_USER)
        history.push('/create-account', { email, from })
      }
    } catch (error) {
      console.error(error)
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Tab') {
      e.preventDefault()
      const storedEmail = localStorage.getItem('email')
      const val = form.getFieldValue('email')

      if (!val && storedEmail) {
        form.setFieldsValue({
          email: storedEmail
        })
      }
    }
  }

  return (
    <div>
      <Form
        name={'sign-in'}
        onFinish={onSubmit}
        initialValues={{ remember: true }}
        form={form}
        layout={'vertical'}
      >
        <FormItem
          name={'email'}
          label={'Email ID'}
          extra={state === AUTH_SCREEN_STATE.INITIAL && 'We’ll check if you have an account in the next step'}
          rules={[
            {
              type: 'email',
              message: 'Please type in a valid email!'
            }, {
              required: true,
              message: 'Please type in your email!'
            }
          ]}
        >
          <LargeInput
            placeholder={localStorage.getItem('email') || 'name@company.com'}
            size={'large'}
            autoFocus
            autoComplete={'on'}
            name={'email'}
            type={'email'}
            onKeyDown={handleKeyDown}
            data-test={'email-input'}
          />
        </FormItem>

        {(state === AUTH_SCREEN_STATE.HAS_PASSWORD || state === AUTH_SCREEN_STATE.MAGIC_LINK_USER) && (
          <>
            <FormItem
              label={'Enter your password'}
              name={'password'}
              rules={[
                {
                  required: true,
                  message: 'Please type in your password!'
                }
              ]}
            >
              <LargeInputPassword
                ref={passwordInputRef}
                placeholder={'Password'}
                size={'large'}
                autoFocus
                type={'password'}
                onKeyDown={handleKeyDown}
                autoComplete={'current-password'}
                data-test={'password-input'}
              />
            </FormItem>
            <ForgotPassword
              target={'_blank'}
              href={`${PASSWORD_RESET_URL}?email=${encodeURIComponent(form.getFieldValue('email'))}`}
            >
              {'Forgot password?'}
            </ForgotPassword>
          </>
        )}

        <LargeButton
          data-test={'submit-button'}
          type={'primary'}
          size={'large'}
          htmlType={'submit'}
          loading={auth.authState === AUTH_STATE.FETCHING}
          disabled={(state === AUTH_SCREEN_STATE.HAS_PASSWORD && (form.getFieldValue('password') === null || form.getFieldValue('password') === ''))}
        >
          {STATE[state].footerButtonText}
        </LargeButton>
      </Form>
    </div>
  )
}

SignInWithEmail.propTypes = {
  initialPath: PropTypes.string
}

export default SignInWithEmail

const inputStyle = {
  padding: '12px 18px',
  borderRadius: '8px!important'
}

const FormItem = styled(Form.Item)({
  textAlign: 'left',
  '.ant-form-item-extra': {
    fontStyle: 'italic',
    fontSize: '0.8em',
    paddingTop: '0.5em'
  }
})

const LargeInput = styled(Input)(inputStyle)

const LargeInputPassword = styled(Input.Password)(inputStyle)

const LargeButton = styled(Button)({
  width: '100%',
  height: '50px!important'
})

const ForgotPassword = styled.a({
  float: 'right',
  fontSize: '0.8em',
  paddingBottom: '20px'
})
