import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { SponsorMeeting } from '@Hooks/useSponsorStatistics/useSponsorStatistics'
import theme from '@Theme'
import { Button, Divider, Spin, Tooltip } from 'antd'
import { groupBy, prop } from 'ramda' // check out ramda's groupBy

interface RenderMeetingStatusProps {
  meetings: SponsorMeeting[],
  loading: boolean
}

type StatusButtonProps = {
  label: string,
  color?: string,
  background?: string,
  meetings: SponsorMeeting[]
}

const formatMeetings = (status: string, meetings: SponsorMeeting[]) => ((meetings).filter((meeting: { status: string }) => meeting.status === status))

const StatusButton = ({ color, background, label, meetings }: StatusButtonProps) => {
  const meetingsGroupedByDirection = groupBy(prop('direction'), meetings as SponsorMeeting[])

  return (
    <Tooltip
      placement={'topLeft'}
      title={<StyledInnerTooltip>
        {(Object.keys(meetingsGroupedByDirection)).map((directionKey, index) => {
          const groupedByRep = groupBy(prop('representative'), meetingsGroupedByDirection[directionKey])
          return (
            <Fragment key={index}>
              <span className={'direction'}>{ directionKey }</span>
              <Divider className={'divider'} style={{ width: 20 }}/>
              {Object.keys(groupedByRep).map((key, index) => {
                const meeting = groupedByRep[key][0]
                return (
                  <div key={meeting.id}>
                    <b>{groupedByRep[key].length}</b>
                    {' '}
                    <span style={{ color }}>{meeting.status}</span>
                    {' '}
                    <b>{directionKey}</b>
                    {' '}
                    {'meeting request with'}
                    {' '}
                    <b>{meeting.representative}</b>
                    {'.'}
                    {meetingsGroupedByDirection[directionKey].length !== index + 1 ? <Divider className={'divider'}/> : <br/>}
                  </div>
                )
              })}
            </Fragment>
          )
        })}

      </StyledInnerTooltip>}
      color={theme.colors.primaryBackground}
    >
      <Button style={{ color, borderColor: color, background, margin: 5 }}>{`${meetings.length} ${label}`}</Button>
    </Tooltip>
  )
}

const _renderStatus = (status: string, meetings: SponsorMeeting[]) => {
  formatMeetings(status, meetings)
  const meetingGroupedByStatus = groupBy(prop('status'), meetings)
  if (!meetings.length) {
    return <Button disabled>{'No meetings'}</Button>
  }
  return (
    <>
      { meetingGroupedByStatus?.accepted && <StatusButton
        label={'Accepted'} color={theme.colors.primary} background={theme.colors.primaryLight2}
        meetings={meetingGroupedByStatus.accepted}
      />
      }
      { meetingGroupedByStatus?.pending && <StatusButton
        label={'Pending'} color={theme.colors.infoYellow} background={theme.colors.infoYellowBackground}
        meetings={meetingGroupedByStatus.pending}
      />}
      { meetingGroupedByStatus?.rescheduled && <StatusButton
        label={'Rescheduled'}color={theme.colors.infoYellow} background={theme.colors.infoYellowBackground}
        meetings={meetingGroupedByStatus.rescheduled}
      />}
      { meetingGroupedByStatus?.canceled && <StatusButton
        label={'Canceled'} color={theme.colors.dangerRed} background={theme.colors.dangerRedBackground}
        meetings={meetingGroupedByStatus.canceled}
      />}
    </>
  )
}

const MeetingStatus = ({ meetings, loading }: RenderMeetingStatusProps) => {
  return (
    <div data-test={'meeting-status'}>
      {loading
        ? <Spin />
        : _renderStatus('accepted', meetings)}
    </div>
  )
}

export default MeetingStatus

const StyledInnerTooltip = styled('div')({
}, (props) => ({
  color: props.theme.colors.text,
  '.divider': {
    margin: '4px 0 4px'
  },
  '.direction': {
    color: props.theme.colors.lightGrayText,
    fontWeight: 500
  }
}))
