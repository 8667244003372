import { EditorState } from 'draft-js'

export enum updateSponsorActionTypes {
  SET_NAME = 'setName',
  SET_SUBTITLE = 'setSubtitle',
  SET_LOGO_URL = 'setLogoURL',
  SET_COVER_IMAGE_URL= 'setCoverImage',
  SET_WEBSITE = 'setWebsite',
  SET_FACEBOOK = 'setFacebook',
  SET_TWITTER = 'setTwitter',
  SET_INSTAGRAM = 'setInstagram',
  SET_LINKEDIN = 'setLinkedIn',
  SET_CONTENT = 'setContent',
  SET_INDUSTRY = 'setIndustry',
  SET_CTA_TEXT = 'setCtaText'
}

export type UpdateSponsorAction =
| { type: updateSponsorActionTypes.SET_NAME |
updateSponsorActionTypes.SET_SUBTITLE |
updateSponsorActionTypes.SET_CTA_TEXT |
updateSponsorActionTypes.SET_LOGO_URL |
updateSponsorActionTypes.SET_COVER_IMAGE_URL |
updateSponsorActionTypes.SET_WEBSITE |
updateSponsorActionTypes.SET_FACEBOOK |
updateSponsorActionTypes.SET_TWITTER |
updateSponsorActionTypes.SET_INSTAGRAM |
updateSponsorActionTypes.SET_LINKEDIN,
payload: string }
| { type: updateSponsorActionTypes.SET_CONTENT, payload: EditorState }
| { type: updateSponsorActionTypes.SET_INDUSTRY, payload: number }
