/* eslint-disable @typescript-eslint/no-explicit-any */
import './index.css'

import { getFormattedDuration } from '@Helpers/DateTime'
import { Avatar, Table } from 'antd'
import { sum } from 'ramda'
import { UserColWidthType } from 'src/Sponsor/Modules/TrafficAnalytics/Analytics'
interface MediaUserTableProps {
  data: {
    trackingSessions: unknown[] | null | undefined
  }[] | undefined,
  loading?: boolean,
  onTableChange: () => void,
  expandedRowRender: any,
  colWidth: UserColWidthType
}

enum DurationSecondsType {
  Sponsor = 'Sponsor',
  Media = 'Media'
}

function getDurationSeconds(trackingSessions: any, type: DurationSecondsType) {
  if (!trackingSessions) {
    return
  }
  const trackingSessionsDurationSum = (type: DurationSecondsType) =>
    sum(
      trackingSessions
        .filter((session: { trackable: { trackableType: string } }) => session.trackable?.trackableType === type)
        .map((session: { durationSeconds: number }) => session.durationSeconds || 0)
    )
  // Can be removed when back-end is fixed
  // Media tracking session should always be less or equal than the Sponsor booth tracking
  if (type === DurationSecondsType.Sponsor && (
    trackingSessionsDurationSum(DurationSecondsType.Media) > trackingSessionsDurationSum(DurationSecondsType.Sponsor)
  )) {
    return (
      trackingSessionsDurationSum(DurationSecondsType.Media)
    )
  }

  return trackingSessionsDurationSum(type)
}

const MediaUserTable = ({ data, loading, onTableChange, expandedRowRender, colWidth }: MediaUserTableProps) => {
  const extendedData = (data || []).map((data) => {
    return {
      ...data,
      contentDurationSeconds: getDurationSeconds(data.trackingSessions, DurationSecondsType.Media),
      sponsorDurationSeconds: getDurationSeconds(data.trackingSessions, DurationSecondsType.Sponsor)
    }
  })

  return (
    <Table
      data-test={'visitors-table'}
      className={'visitors-table'}
      dataSource={extendedData}
      loading={loading}
      rowKey={'id'}
      size={'middle'}
      scroll={{ x: 'max-content' }}
      bordered
      onChange={onTableChange}
      pagination={false}
      expandable={{ expandedRowRender }}
    >
      <Table.Column
        key={'avatar'}
        dataIndex={'attendee'}
        width={colWidth.avatar}
        render={({ imageUrl, firstName }) => (
          <div style={{ textAlign: 'center' }}>
            <Avatar
              src={imageUrl}
              size={'small'}
            >
              {firstName?.slice(0, 1)}
            </Avatar>
          </div>
        )}
      />
      <Table.Column
        title={'Name'}
        key={'name'}
        width={colWidth.name}
        dataIndex={'attendee'}
        render={({ firstName, lastName }) => (
          <div data-test={'name'}>
            {firstName}
            {' '}
            {lastName}
          </div>
        )}
      />
      <Table.Column
        title={'Job Title'}
        key={'jobTitle'}
        width={colWidth.jobTitle}
        dataIndex={['attendee', 'companyTitle']}
      />
      <Table.Column
        title={'Company'}
        key={'jobName'}
        width={colWidth.company}
        dataIndex={['attendee', 'companyName']}
      />
      <Table.Column
        title={'Total Time Spent in Booth'}
        key={'sponsorDurationSeconds'}
        width={colWidth.timeSpentInBooth}
        dataIndex={'sponsorDurationSeconds'}
        render={(durationSeconds) => {
          return <span>{getFormattedDuration(durationSeconds)}</span>
        }}
      />
      <Table.Column
        title={'Total Content View Time'}
        key={'contentDurationSeconds'}
        width={colWidth.contentViewTime}
        dataIndex={'contentDurationSeconds'}
        render={(durationSeconds) => {
          return <span>{getFormattedDuration(durationSeconds)}</span>
        }}
      />
      <Table.Column
        title={'Total Content View Count'}
        key={'interactionCount'}
        width={colWidth.contentViewCount}
        dataIndex={'interactionCount'}
      />
    </Table>
  )
}

export default MediaUserTable
