import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import theme from '../Themes/BrellaTheme'

const DEFAULT_SIZE = 64

export const Avatar = ({ url, matchPercentage, size, firstName, lastName, styles, onClick, company, bgColor, renderBadge, withBorder }) => {
  const Container = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: size,
    height: size,
    borderRadius: theme.radius.large,
    backgroundColor: url ? null : '#333',
    position: 'relative',
    border: matchPercentage ? '2px solid white' : '0px',
    ...styles
  }))

  const InitialsText = styled('span')({
    ...theme.text.body,
    fontSize: size * 0.30,
    letterSpacing: '-1px',
    color: theme.colors.primaryBackground,
    fontWeight: (size < DEFAULT_SIZE) ? '600' : '700'
  })

  const BadgeHolder = styled('div')({
    width: size,
    textAlign: 'center',
    position: 'absolute',
    bottom: '-10px',
    left: '-2px' // IE11 Fix
  })

  const BadgeContainer = styled('div')((props) => ({
    padding: '2px',
    background: `linear-gradient(125deg, ${props.theme.colors['primary-7']}, ${props.theme.colors['secondary-7']})`,
    borderRadius: '3px'
  }))

  const BadgeText = styled('div')({
    ...theme.text.tinyCaps,
    whiteSpace: 'nowrap',
    padding: (size < DEFAULT_SIZE) ? '1' : '2px 5px',
    letterSpacing: '-0.2px',
    borderRadius: theme.radius.small,
    backgroundColor: theme.colors.primaryBackground,
    fontSize: (size < DEFAULT_SIZE) ? '8px' : '9px'
  })

  const Picture = styled('div')({
    width: '100%',
    height: '100%',
    borderRadius: theme.radius.large,
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    backgroundColor: bgColor || '#191919'
  }, ({ withBorder }) => ({
    border: withBorder ? '2px solid #fff' : '0px'
  }))

  const Border = styled('div')((props) => ({
    background: matchPercentage > 0 ? `linear-gradient(125deg, ${props.theme.colors['primary-7']}, ${props.theme.colors['secondary-7']})` : 'transparent',
    width: size + 4,
    height: size + 4,
    borderRadius: theme.radius.large,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: props.withBorder ? '-4px' : 'auto'
  }))

  const Initials = () => {
    if (!firstName || !lastName) return null
    const initials = `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`
    return (
      <InitialsText>{initials}</InitialsText>
    )
  }

  const Badge = () => {
    const badgeText = company
      ? 'SPONSORED'
      : 'MATCH'

    return (
      <BadgeHolder>
        <BadgeContainer>
          <BadgeText>{badgeText}</BadgeText>
        </BadgeContainer>
      </BadgeHolder>
    )
  }

  return (
    <Border withBorder>
      <Container onClick={onClick}>
        {url ? <Picture withBorder={withBorder} /> : <Initials />}
        {(matchPercentage > 0 && renderBadge) && <Badge />}
      </Container>
    </Border>
  )
}

Avatar.defaultProps = {
  size: DEFAULT_SIZE,
  renderBadge: true
}

Avatar.propTypes = {
  url: PropTypes.string,
  matchPercentage: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
  styles: PropTypes.object,
  onClick: PropTypes.func,
  company: PropTypes.bool,
  bgColor: PropTypes.string,
  renderBadge: PropTypes.bool,
  withBorder: PropTypes.bool
}

export const avatarFactory = (size) => {
  // eslint-disable-next-line react/prefer-stateless-function -- Cannot make a factory with functional component
  return (WrappedComponent) => (class FactoryAvatar extends React.Component {
    render() {
      const newProps = { size }
      return <WrappedComponent {...this.props} {...newProps} />
    }
  })
}

const UserAvatar = {
  Xtiny: avatarFactory(30)(Avatar),
  Tiny: avatarFactory(34)(Avatar),
  Small: avatarFactory(48)(Avatar),
  Mobile: avatarFactory(60)(Avatar),
  Medium: avatarFactory(75)(Avatar),
  Large: avatarFactory(120)(Avatar)
}

export default UserAvatar
