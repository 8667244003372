import 'react-multi-carousel/lib/styles.css'
import './CarouselOverrides.css'

import { useState } from 'react'
import Carousel from 'react-multi-carousel'
import styled from '@emotion/styled'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import * as R from 'ramda'

const mapIndexed = R.addIndex(R.map)

const sortItemsByPosition = R.unless(
  R.isEmpty,
  R.sortBy(R.prop('position'))
)

const MediaFrame = 'iframe'

const Deck = styled(Carousel)({
  marginBottom: 16
})

const SlideContainer = styled('div')({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  aspectRatio: '16/9',
  borderRadius: 8,
  backgroundColor: '#f0f0f0',
  overflow: 'hidden'
})

const ImageSlide = styled('div')({
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat'
}, ({ source }) => ({
  backgroundImage: `url(${source})`
}))

const EmptySlide = styled('div')({
  width: '100%',
  height: '100%',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
})

const Slide = ({ url, title, bodyType, active }) => {
  switch (bodyType) {
    case 'link':
      return (
        <SlideContainer>
          <Button href={url} title={title}>
            {title}
          </Button>
        </SlideContainer>
      )

    case 'iframe':
      return (
        <SlideContainer>
          <MediaFrame
            width={'100%'}
            height={'100%'}
            title={title}
            src={active ? url : null}
            allow={'accelerometer; encrypted-media; gyroscope; picture-in-picture; camera; microphone; display-capture; autoplay; fullscreen'}
            allowFullScreen
            autoPlay={false}
            frameBorder={0}
          />
        </SlideContainer>
      )

    case 'image':
      return (
        <SlideContainer>
          <ImageSlide source={url} />
        </SlideContainer>
      )

    default:
      return (
        <SlideContainer>
          <EmptySlide>
            <p>{`Slide "${title}" can't be shown`}</p>
          </EmptySlide>
        </SlideContainer>
      )
  }
}

Slide.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  bodyType: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
}

const SponsorCarousel = (props) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const _sortedItems = sortItemsByPosition(props.items)
  const _onChange = (__, { currentSlide }) => {
    setCurrentSlideIndex(
      currentSlide
    )
  }

  if (R.isEmpty(_sortedItems)) {
    return null
  }

  return (
    <Deck
      draggable
      partialVisible
      showDots
      centerMode={false}
      removeArrowOnDeviceType={'mobile'}
      afterChange={_onChange}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 1
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 1
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 1
        }
      }}
    >
      {
        mapIndexed((item, i) => (
          <Slide
            key={item.id}
            {...item}
            active={currentSlideIndex === i}
          />
        ), _sortedItems)
      }
    </Deck>
  )
}

SponsorCarousel.defaultProps = {
  items: []
}

SponsorCarousel.propTypes = {
  items: PropTypes.array
}

export default SponsorCarousel
