import { Redirect, Route, Switch } from 'react-router-dom'

import Routes from './Routes'

const RoutesComponent = () :JSX.Element => {
  return (
    <Switch>
      {Routes.map((route) => {
        if (route.type === 'redirect') {
          return (
            <Route key={route.id} exact path={route.from}>
              <Redirect to={route.to} />
            </Route>
          )
        }
        return (
          <Route
            key={route.id}
            exact={route.exact}
            path={route.to}
            component={route.component}
          />
        )
      })}
    </Switch>
  )
}

export default RoutesComponent
