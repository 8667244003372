import styled from '@emotion/styled'
import useSponsorProducts from '@Hooks/useSponsorProducts'
import { colors } from '@Theme'
import { ProductType } from 'src/Sponsor/Modules/Products/Types'

const SponsorProductsPreview = () => {
  const { products } = useSponsorProducts()
  return (
    <SponsorProductsPreviewContainer>
      {products.slice(0, 10).map((product: ProductType) => (
        <SponsorProduct
          key={product.id}
          data-test={`product-card-${product.id}`}
        >
          <img
            data-test={'product-image'}
            src={product.imageUrl}
            alt={product.title}
          />
          <SponsorInfo>
            <h3 data-test={'product-title'}>{product.title}</h3>
            <p data-test={'product-description'}>{product.description}</p>
            <a data-test={'product-url'} href={product.url} target={'#blank'}>
              {'More details ↗'}
            </a>
          </SponsorInfo>
        </SponsorProduct>
      ))}
    </SponsorProductsPreviewContainer>
  )
}

export default SponsorProductsPreview

const SponsorProductsPreviewContainer = styled('div')({
  maxHeight: 800,
  overflow: 'auto',
  display: 'flex',
  flexWrap: 'wrap'
})

const SponsorProduct = styled('div')({
  flexGrow: 1,
  width: '25%',
  maxWidth: 200,
  position: 'relative',
  paddingBottom: 24,
  border: '1px solid #f0f0f0',
  margin: '0 24px 24px 0',
  '>img': {
    width: '100%',
    aspectRatio: '1'
  }
})

const SponsorInfo = styled('div')({
  fontStyle: 'normal',
  padding: 24,
  '>h3': {
    fontWeight: 600,
    fontSize: 16
  },
  '>p': {
    fontWeight: 300,
    fontSize: 14,
    color: '#818283',
    lineHeight: '1.7',
    lineBreak: 'anywhere'
  },
  '>a': {
    fontWeight: 600,
    color: colors.primary,
    fontSize: 16,
    position: 'absolute',
    bottom: 24,
    '&:hover': {
      textDecoration: 'underline',
      color: colors.primary
    }
  }
})
