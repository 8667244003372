import { queryCache } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { productsQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'
import { Button, message, Popconfirm, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { EventParamsTypes } from 'src/Types'

import { useProductsContext } from '../ProductsContext'
import { actionTypes, ProductModalType } from '../Types'

import ProductModalForm from './ProductModalForm'

export const TITLE_MAX_COUNT = 50
export const DESCRIPTION_MAX_COUNT = 100

interface ProductModalProps {
  visible?: boolean,
  setVisible: (isModalVisible: boolean) => void,
  modalType: ProductModalType
}

const ProductModal = ({
  visible,
  setVisible,
  modalType
}: ProductModalProps) => {
  const params = useParams<EventParamsTypes>()

  const invalidateCache = () => queryCache.invalidateQueries('products')
  const {
    activeProductState,
    activeProductStateDispatch,
    newProductState,
    newProductStateDispatch,
    setTableLoading
  } = useProductsContext()

  const state = modalType === ProductModalType.NEW ? newProductState : activeProductState
  const dispatch = modalType === ProductModalType.NEW ? newProductStateDispatch : activeProductStateDispatch

  const [patchProduct, { isLoading: patchLoading }] = entitySauce.usePatch(
    productsQuery(params.eventId, params.sponsorId),
    {
      onSuccess: () => {
        message.success('Changes saved successfully.')
        invalidateCache()
        handleCancel()
        setTableLoading(false)
      },
      onError: (res: { data: { errors: { title: string }[] } }) => {
        message.error(`${res?.data?.errors?.[0].title ?? 'Couldn\'t save the changes'}. Please try again.`)
        setTableLoading(false)
      }
    }
  )

  const [postProduct, { isLoading: postLoading }] = entitySauce.usePost(
    productsQuery(params.eventId, params.sponsorId),
    {
      onSuccess: () => {
        message.success('Product added successfully.')
        dispatch({
          type: actionTypes.SET_INITIAL_VALUES,
          payload: {}
        })
        invalidateCache()
        handleCancel()
        setTableLoading(false)
      },
      onError: (res: { data: { errors: { title: string }[] } }) => {
        message.error(`${res?.data?.errors?.[0].title ?? 'Couldn\'t save the changes'}. Please try again.`)
        setTableLoading(false)
      }
    }
  )

  const handleSubmit = async() => {
    const payload = {
      title: state?.title,
      url: state?.url,
      description: state?.description,
      image: state?.imageUrl?.startsWith('data:image') ? state?.imageUrl : undefined
    }

    const data = payload

    const saveProduct = modalType === ProductModalType.NEW ? postProduct : patchProduct
    saveProduct({
      id: state.id,
      data
    })
    setTableLoading(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const loading = patchLoading || postLoading

  const isModalClosable = !(modalType === ProductModalType.EDIT && state.fieldsTouched)

  const createButtonDisabled =
    !state.url ||
     !state.title ||
    (state?.title?.length ?? 0) > TITLE_MAX_COUNT ||
    !state.description ||
    (state?.description?.length ?? 0) > DESCRIPTION_MAX_COUNT ||
    !state.imageUrl ||
    !state.fieldsTouched ||
    postLoading

  const renderCancelButton = (
    isModalClosable
      ? (
        <StyledButton key={'cancel'} onClick={handleCancel} data-test={'products-cancel-button'}>
          {'Cancel'}
        </StyledButton>
      )
      : (
        <Popconfirm
          key={'cancel'}
          title={'Are you sure you want to discard changes'}
          onConfirm={handleCancel}
        >
          <StyledButton data-test={'products-cancel-button'}>
            {'Cancel'}
          </StyledButton>
        </Popconfirm>
      )
  )

  const renderSubmitButton = (
    <StyledButton
      data-test={`products-submit-button-${modalType}`}
      key={'submit'}
      type={'primary'}
      onClick={handleSubmit}
      disabled={createButtonDisabled}
    >
      {loading ? <Spin/> : modalType === ProductModalType.EDIT ? 'Save changes' : 'Add'}
    </StyledButton>
  )

  return (
    <Modal
      title={modalType === ProductModalType.EDIT ? 'Edit product' : 'Add new product'}
      visible={visible}
      onOk={handleSubmit}
      onCancel={isModalClosable ? handleCancel : () => { return null } }
      centered={true}
      data-test={'modal'}
      closable={isModalClosable}
      destroyOnClose={true}
      footer={[
        renderCancelButton,
        renderSubmitButton
      ]}
    >
      <ProductModalForm
        state={state}
        dispatch={dispatch}
      />
    </Modal>
  )
}

export default ProductModal

const StyledButton = styled(Button)({
  borderRadius: '4px !important'
})
