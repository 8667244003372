import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Button, Result } from 'antd'

export default function NoRouteMatch() {
  return (
    <NoRouteMatchContainer>
      <Result
        status={'404'}
        title={'404'}
        subTitle={'Sorry, the page you visited does not exist.'}
        extra={(
          <Link to={'/'}>
            <Button type={'primary'}>{'Back Home'}</Button>
          </Link>
        )}
      />
    </NoRouteMatchContainer>
  )
}

const NoRouteMatchContainer = styled('div')({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})
