import './SponsorForm.css'

import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import TableTitle from '@SponsorCommon/TableTitle'
import { useSponsorContext } from '@SponsorContext'
import { Form } from 'antd'
import { find, propEq } from 'ramda'

import { EventParamsTypes, SponsorTabType } from '../../../../Types'

import { parseContentFromBackend } from './helpers/Sponsors'
import FormItems from './FormItems'

const SponsorForm = () => {
  const params = useParams<EventParamsTypes>()
  const { eventId } = params
  const _onSelectIndustryFilter = (input: string, option: { props: { children: string } }) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

  const { setVirtualBoothFieldsTouched, sponsorState } = useSponsorContext()

  const _onSetFieldsTouched = () => {
    setVirtualBoothFieldsTouched(true)
  }

  const _TooltipNode = (
    <span>
      {'Customize your booth and make it look perfect. '}
      <a href={'https://help.brella.io/en/sponsors/how-to-create-your-virtual-booth'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
    </span>
  )

  const boothTabs = (key: string) => find(propEq('key', key))(sponsorState?.boothTabs ?? []) as SponsorTabType

  return (
    <div style={{ marginRight: 24 }}>
      <TableTitle
        title={'Virtual Booth'}
        TooltipNode={_TooltipNode}
        margin={'0 0 24px'}
        testId={'booth-details'}
      />
      <StyledForm
        onFieldsChange={_onSetFieldsTouched}
        initialValues={{
          name: sponsorState?.name,
          subtitle: sponsorState?.subtitle,
          ctaText: sponsorState?.ctaText ?? 'Visit booth',
          industry: sponsorState?.industry,
          categoryId: sponsorState?.category?.id,
          usesChat: sponsorState?.usesChat,
          website: sponsorState?.website,
          facebook: sponsorState?.facebook,
          twitter: sponsorState?.twitter,
          instagram: sponsorState?.instagram,
          linkedin: sponsorState?.linkedin,
          content: parseContentFromBackend(sponsorState?.content),
          logo: sponsorState?.logoUrl,
          companyDetails: boothTabs('details')?.title,
          companyRepresentatives: boothTabs('representatives')?.title,
          products: boothTabs('products')?.title
        }}
      >
        <FormItems
          onSelectIndustryFilter={_onSelectIndustryFilter}
          eventId={eventId}
          subtitleLength={sponsorState?.subtitle?.length ?? 0}
          ctaTextLength={sponsorState?.ctaText?.length ?? 0}
          logoUrl={sponsorState?.logoUrl ?? ''}
          coverImageUrl={sponsorState?.coverImageUrl ?? ''}
          isLarge={sponsorState?.category?.level === 'large' }
        />
      </StyledForm>
    </div>

  )
}

export default SponsorForm

const StyledForm = styled(Form)({
  '.ant-form-item': {
    marginBottom: 18
  },
  '.ant-form-item:last-child': {
    marginBottom: 0
  }
})
