/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type GlobalContentType = {
  searchTerm: string,
  setSearchTerm: (searchTerm: string) => void,
  pastChecked: boolean,
  setPastChecked: (pastChecked: boolean) => void,
  filteredEvents: any,
  setFilteredEvents: (filteredEvents:any) => void
}

export const GlobalContext = createContext<GlobalContentType>({
  searchTerm: '',
  setSearchTerm: () => {},
  pastChecked: true,
  setPastChecked: () => {},
  filteredEvents: [],
  setFilteredEvents: () => {}
})

export const useGlobalContext = ():GlobalContentType => useContext(GlobalContext)
