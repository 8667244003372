/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownloadOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button } from 'antd'

interface SaveChartButtonProps {
  instance: any,
  name: string
}
export const SaveChartButton = ({ instance, name }: SaveChartButtonProps) => {
  function saveChartAsImage() {
    const link = document.createElement('a')
    link.download = `${name?.toLowerCase()?.replace(/ /g, '-')}-${new Date().toISOString().split('T')[0]}.png`
    link.href = instance.current.getEchartsInstance().getDataURL()
    link.click()
    link.blur()
    link.remove()
  }

  return (
    <StyledSaveChartButton icon={<DownloadOutlined/>} onClick={saveChartAsImage} data-test={`${name?.toLowerCase()?.replace(/ /g, '-')}-save-button`}>
      {'Save (.png)'}
    </StyledSaveChartButton>
  )
}

const StyledSaveChartButton = styled(Button)({
}, ({ theme }) => ({
  color: `${theme.colors.lightGrayText} !important`,
  position: 'absolute',
  zIndex: 1,
  right: 24,
  top: 16,
  paddingTop: 4,
  '&:hover': {
    color: `${theme.colors.primary} !important`
  }
}))
