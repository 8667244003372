import { useState } from 'react'
import { MediaContext } from '@MediaContext'
import Card from '@Reusable/Card/Card'
import { Col, Row } from 'antd'

import CarouselDeck from '../Media/CarouselDeck'

import SponsorFormPreview from './SponsorForm/SponsorFormPreview'
import SponsorForm from './SponsorForm'

function VirtualBooth(): JSX.Element {
  const [mediaState, setMediaState] = useState({})
  const [sideBarPreviewVisible, setSideBarPreviewVisible] = useState<boolean>(false)
  const [patchItemId, setPatchItemId] = useState<string | number | null>(null)

  const MediaContextProviderValue = {
    mediaState,
    setMediaState,
    patchItemId,
    setPatchItemId,
    sideBarPreviewVisible,
    setSideBarPreviewVisible
  }

  return (
    <Card testId={'virtual-booth'} paddingTop={'24px'}>
      <Row gutter={16}>
        <Col
          className={'gutter-row'}
          lg={24} xl={11}
        >
          <SponsorForm />
        </Col>
        <Col
          className={'gutter-row'}
          lg={24} xl={13}
        >
          <SponsorFormPreview />
        </Col>
        <Col
          lg={24} xl={24} md={24}
          sm={24} xs={24}
        >
          <MediaContext.Provider value={MediaContextProviderValue}>
            <CarouselDeck/>
          </MediaContext.Provider>
        </Col>
      </Row>
    </Card>
  )
}

export const virtualBoothRouteConfig = {
  path: '/virtual-booth'
}

VirtualBooth.routeConfig = virtualBoothRouteConfig

export default VirtualBooth
