import './DragAndDropList.css'

import { PureComponent } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Empty } from 'antd'
import PropTypes from 'prop-types'
import { addIndex, isEmpty, map } from 'ramda'

const mapIndexed = addIndex(map)

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  userSelect: 'none',
  backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.1)' : '#FAFAFA',
  borderRadius: 8,
  margin: '8px 0px',
  outline: 0,
  border: '1px solid #FAFAFA'
})

class DragAndDropList extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      draft: props.list
    }
  }

  // Make sure list items query straight from store, as this component won't update them.
  // Only when the list size changes.
  static getDerivedStateFromProps(props, state) {
    if (props.list.length !== state.draft.length) {
      return {
        draft: props.list
      }
    }

    return null
  }

  /**
   * Create new indexes after drop
   *
   * @memberof DragAndDropList
   */
  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * Create new positions based on indexes
   *
   * @memberof DragAndDropList
   */
  _createNewPositions = (list) => {
    return (
      mapIndexed((item, index) => {
        return {
          ...item,
          position: index + 1
        }
      })(list)
    )
  }

  _onDragEnd = (result) => {
    if (!this.props.onChange) {
      console.error('Error: Please provide onChange callback')
      return false
    }

    if (!result.destination || this.props.draggingDisabled) {
      return false
    }

    if (result.source.index === result.destination.index) {
      return false
    }

    const reordered = this._reorder(
      this.props.list,
      result.source.index,
      result.destination.index
    )

    const newPostitions = this._createNewPositions(reordered)

    this.setState({
      draft: newPostitions
    })

    this.props.onChange({
      newPostitions,
      draggedId: result.draggableId,
      draggedPosition: result.destination.index + 1
    })
  }

  render() {
    const { listItemRenderer, draggingDisabled } = this.props

    if (isEmpty(this.state.draft)) {
      return (
        <Empty description={'No items'} />
      )
    }

    return (
      <div className={'br-drag-and-drop-list-container'}>
        <DragDropContext onDragEnd={this._onDragEnd}>
          <Droppable droppableId={'droppable'}>
            {(provided) => (
              <div ref={provided.innerRef}>
                {this.state.draft.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={draggingDisabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          this.state.draft.length === 1,
                          index === 0,
                          index === this.state.draft.length - 1
                        )}
                      >
                        {listItemRenderer(item)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

DragAndDropList.defaultProps = {
  list: []
}

DragAndDropList.propTypes = {
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  listItemRenderer: PropTypes.func.isRequired,
  draggingDisabled: PropTypes.bool
}

export default DragAndDropList
