import { DownOutlined, UserOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import useUser from '@Hooks/useUser'
import { Dropdown, Menu } from 'antd'

const UserDropdown = () => {
  const { user } = useUser()

  const _logOut = async() => {
    window.location.assign('/logging-out')
  }

  const _menu = (
    <Menu>
      <Menu.Item key={'0'} onClick={_logOut}>
        <span style={{ color: '#CF1322' }}>{'Log out'}</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      <StyledUserDropdown overlay={_menu} trigger={['click']} placement={'topLeft'}>
        <a onClick={(e) => e.preventDefault()}>
          <UserOutlined/>
          {' '}
          {user?.email}
          {' '}
          <DownOutlined />
        </a>
      </StyledUserDropdown>
    </div>
  )
}

export default UserDropdown

const StyledUserDropdown = styled(Dropdown)({
}, (props) => ({
  color: `${props.theme.colors.text} !important`,
  svg: {
    color: props.theme.colors.primary
  },
  p: {
    color: '#CF1322 !important'
  }
}))
