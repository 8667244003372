import { useState } from 'react'
import { queryCache } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { sponsorMediaQuery, sponsorQuery } from '@EntitySauce/Queries'
import { sortItemsByPosition } from '@Helpers/Util'
import useSponsor from '@Hooks/useSponsor'
import { useMediaContext } from '@MediaContext'
import CustomMessage from '@Reusable/CustomMessage'
import DragAndDropList from '@Reusable/DragAndDropList/DragAndDropList'
import Spinner from '@Reusable/Spinner'
import AlertToggle from '@SponsorCommon/AlertToggle'
import { useSponsorContext } from '@SponsorContext'
import { entitySauce } from '@State'
import { message } from 'antd'
import { find, propEq } from 'ramda'
import snakecaseKeys from 'snakecase-keys'
import { EventParamsTypes } from 'src/Types'

import DraggableItem from '../Common/DraggableItem'
import EmptyPlaceHolder from '../Common/EmptyPlaceHolder'
import Header from '../Common/Header'
import MediaModal, { ModalStatus, ModalTypes } from '../Common/MediaModal'
import { StyledColumnWrapper } from '../Common/Styled'
import { MediaDeckType } from '../MediaComponents'
import SideBarDrawer from '../SideBarDrawer'

const tooltipNode = (
  <span>
    {'Embed presentations, lead captures or engagement tools to your virtual booth. '}
    <a href={'https://help.brella.io/en/embedding-in-virtual-booth-side-bar'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
  </span>
)

const SideBarIntegrations = () => {
  const params = useParams<EventParamsTypes>()
  const { eventId } = params
  const { sponsor, isFetching: isFetchingSponsor } = useSponsor()
  const { patchItemId, setSideBarPreviewVisible } = useMediaContext()
  const { sponsorState } = useSponsorContext()

  const tabs = sponsor?.tabs ?? []

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalStatus, setModalStatus] = useState(ModalStatus.NEW)

  const [patch, { status: patchStatus }] = entitySauce.usePatch(
    sponsorMediaQuery()
  )

  const showModal = (status: ModalStatus) => {
    setIsModalVisible(true)
    setModalStatus(status)
  }

  const invalidateSponsorCache = () => queryCache.invalidateQueries('sponsor')

  const [patchSponsor, { isLoading: isPatchingSponsor }] = entitySauce.usePatch(
    sponsorQuery(eventId, sponsor?.id),
    {
      onSuccess: () => {
        message.success('Changes saved successfully.')
        invalidateSponsorCache()
      },
      onError: (res: { data: { errors: { title: string }[] } }) => {
        message.error(`${res?.data?.errors?.[0].title ?? 'Couldn\'t save the changes'}. Please try again.`)
      }
    }
  )

  const onPositionChange = async({ draggedId, draggedPosition }: { draggedId: number, draggedPosition: number }) => {
    const item = find(propEq('id', draggedId))(tabs)
    if (!item) {
      return null
    }
    const payload = {
      ...item,
      position: draggedPosition,
      mediableId: sponsor?.id,
      mediableType: 'sponsor',
      mediableSecondaryType: 'tabs'
    }

    const media = snakecaseKeys(payload)
    await patch({ mediaId: item.id, data: { media } })
    invalidateSponsorCache()
  }

  const showSideBarDrawer = () => {
    setSideBarPreviewVisible(true)
  }

  const onChatEnabledChange = (checked: boolean) => {
    patchSponsor({
      data: {
        uses_chat: checked
      }
    })
  }

  const renderListItem = (item: { id: string | number | null }) => {
    const isLoading = isFetchingSponsor && item.id === patchItemId
    const tabItem = find(propEq('id', item.id))(tabs)
    return (
      isLoading
        ? <Spinner height={72}/>
        : <DraggableItem
          showModal={showModal} mediaDeck={tabItem as MediaDeckType} refetchSponsor={invalidateSponsorCache}
          type={ModalTypes.SIDE_BAR_ITEM}
        />
    )
  }

  return (
    <StyledColumnWrapper data-test={'side-bar-integrations-container'}>
      <SideBarDrawer />
      <CustomMessage status={patchStatus}/>
      {isModalVisible &&
        <MediaModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          type={ModalTypes.SIDE_BAR_ITEM}
          modalStatus={modalStatus}
          refetchSponsor={invalidateSponsorCache}
        />
      }
      <Margin>
        <Header
          TooltipNode ={tooltipNode}
          title={'Side Bar Integrations (slido, etc.)'}
          showModal={showModal}
          type={ModalTypes.SIDE_BAR_ITEM}
          showSideBarDrawer={showSideBarDrawer}
          sideBarPreviewDisabled={!tabs.length}
          testId={'side-bar-integrations'}
        />
      </Margin>

      <AlertToggle
        title={'Booth chat'}
        description={'Attendees can chat with your booth'}
        enabled={sponsorState?.usesChat}
        onChange={onChatEnabledChange}
        loading={isPatchingSponsor}
        testId={'booth-chat'}
      />

      <br />
      <Margin>
        {
          !tabs.length
            ? <EmptyPlaceHolder />
            : <DragAndDropList
              list={sortItemsByPosition(tabs)}
              listItemRenderer={renderListItem}
              onChange={onPositionChange}
            />
        }
      </Margin>
    </StyledColumnWrapper>
  )
}

export default SideBarIntegrations

const Margin = styled('div')({
  margin: 24
})
