import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { entitySauce } from '@State'
import { Divider, Form, Input, message, Popconfirm, Select } from 'antd'
import { EventParamsTypes } from 'src/Types'

import { FormButton } from '../DownloadsTable/Utils'
import { DownloadItemType, DownloadsFormType, FileInsertMethods, FormValues } from '../Types'

import DragUpload from './DragUpload'

interface DownloadsFormProps {
  item?: DownloadItemType,
  onCancel: () => void,
  formType: DownloadsFormType
}

const DownloadsForm = ({ item, onCancel, formType }: DownloadsFormProps) => {
  const [form] = Form.useForm()

  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params
  const isFile = item?.attachmentType === 'file'

  const [fileInsertMethod, setFileInsertMethod] = useState<FileInsertMethods>(isFile ? FileInsertMethods.UPLOAD : FileInsertMethods.URL)
  const [file, setFile] = useState<string | undefined>(item?.fileUrl)

  const [createDownload, { isLoading: creating }] = entitySauce.usePost([
    'downloads',
    {
      eventId,
      sponsorId
    }
  ], {
    onSuccess: () => {
      message.success('Download created successfully')
      onCancel?.()
    },
    onError: (res: { data: { errors: { title: string }[] } }) => {
      message.error(res.data?.errors[0]?.title || 'Couldn\'t create the download, please try again')
    }
  })

  const [patchDownload, { isLoading: patching }] = entitySauce.usePatch([
    'downloads',
    {
      eventId,
      sponsorId,
      downloadId: item?.id
    }
  ], {
    onSuccess: () => {
      message.success('Download updated successfully')
    },
    onError: (res: { data: { errors: { title: string }[] } }) => {
      message.error(res.data?.errors[0]?.title || 'Couldn\'t update the download, please try again')
    }
  })

  const [deleteDownload, { isLoading: deleting }] = entitySauce.useRemove([
    'downloads',
    {
      eventId,
      sponsorId,
      downloadId: item?.id
    }
  ], {
    onSuccess: () => {
      message.success('Download deleted successfully')
    },
    onError: (res: { data: { errors: { title: string }[] } }) => {
      message.error(res.data?.errors[0]?.title || 'Couldn\'t delete the download, please try again')
    }
  })

  const onSubmit = (values: FormValues) => {
    // We need to drop `file` attribute from the payload when
    // we've already uploaded a file. Otherwise updates won't go
    // through.
    const fileInfo = file?.startsWith('https://') ? undefined : file
    const payload = { data: { download: { ...values, file: fileInfo } } }
    const mutation = isFormNew ? createDownload : patchDownload

    mutation(payload)
  }

  const isFormNew = formType === DownloadsFormType.NEW

  return (
    <Form
      form={form}
      layout={'vertical'}
      wrapperCol={!isFormNew
        ? {
          sm: { span: 20 },
          md: { span: 18 },
          lg: { span: 14 },
          xl: { span: 13 }
        }
        : undefined}
      initialValues={{
        title: item?.title,
        description: item?.description,
        attachment_url: item?.attachmentUrl,
        attachment_type: fileInsertMethod
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        data-test={'download-title'}
        name={'title'}
        label={'Title'}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Title is required'
          },
          {
            max: 25,
            message: 'Title can\'t be longer than 25 characters'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        data-test={'download-description'}
        name={'description'}
        label={'Description'}
        hasFeedback
        help={'Maximum 100 characters. The description will be adjusted dynamically to match the user screen sizes, which may result in truncation of text.'}
        rules={[
          {
            required: true,
            message: 'Description is required'
          },
          {
            max: 100,
            message: 'Description can\'t be longer than 100 characters'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={'Select a file'} name={'attachment_type'} data-test={'download-type'}>
        <Select
          onChange={(option: FileInsertMethods) => setFileInsertMethod(option)}
        >
          <Select.Option value={'url'}>{'Insert file URL'}</Select.Option>
          <Select.Option value={'file'}>{'Upload a file'}</Select.Option>
        </Select>
      </Form.Item>
      {fileInsertMethod === FileInsertMethods.UPLOAD &&
       <Form.Item
         data-test={'download-file'}
         name={'file'}
         hasFeedback
       >
         <DragUpload fileUrl={file} setFile={setFile} />
       </Form.Item>
      }
      {fileInsertMethod === FileInsertMethods.URL &&
       <Form.Item
         data-test={'download-url'}
         name={'attachment_url'}
         hasFeedback
       >
         <Input addonBefore={'https://'} />
       </Form.Item>
      }
      {isFormNew && <FormActionsDivider /> }
      <FormActions isFormNew={isFormNew} wrapperCol={{ span: 24 }}>
        <div>
          <FormButton
            data-test={'cancel-button'}
            type={'default'}
            onClick={onCancel}
            disabled={creating || patching || deleting}
          >
            {'Cancel'}
          </FormButton>
          <FormButton
            data-test={'save-button'}
            type={'primary'}
            htmlType={'submit'}
            disabled={deleting}
            loading={creating || patching}
          >
            {isFormNew ? 'Create' : 'Save changes'}
          </FormButton>
        </div>

        {!isFormNew && (
          <Popconfirm
            icon={<DeleteOutlined style={{ color: 'red', fontSize: 24 }} />}
            title={(
              <div style={{ marginLeft: 12 }}>
                <h3>
                  {'You are about to delete a Download'}
                </h3>
                <p>
                  {'Deleting a download will remove all its data, and this action cannot be undone. Confirm to delete this product.'}
                </p>
              </div>
            )}
            okText={'Confirm'}
            onConfirm={() => deleteDownload()}
            cancelText={'Cancel'}
            okButtonProps={{
              className: 'delete-button-confirm',
              type: 'primary',
              danger: true,
              size: 'middle',
              style: { borderRadius: 4 }
            }}
            cancelButtonProps={{
              size: 'middle',
              style: { borderRadius: 4 }
            }}
            placement={'topLeft'}
          >
            <FormButton
              danger
              loading={deleting}
              type={'primary'}
              data-test={'download-delete-button'}
            >
              {'Delete'}
            </FormButton>
          </Popconfirm>
        )}
      </FormActions>
    </Form>
  )
}

export default DownloadsForm

interface FormActionsProps {
  isFormNew: boolean
}

const FormActions = styled(Form.Item)<FormActionsProps>(
  ({ isFormNew }) => ({
    marginTop: 24,
    '.ant-form-item-control-input-content': {
      display: 'flex',
      justifyContent: isFormNew ? 'flex-end' : 'space-between'
    }
  })
)

const FormActionsDivider = styled(Divider)({
  margin: '48px 0 24px 0'
})
