import { useParams } from 'react-router-dom'
import { sponsorVisitorStatisticsQuery } from '@EntitySauce/Queries'
import { entitySauce } from '@State'

import { EventParamsTypes } from '../Types'

const useVisitorsStatistics = () => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { data, ...rest } = entitySauce.useGet(sponsorVisitorStatisticsQuery(
    eventId,
    sponsorId
  ), { staleTime: 50000, refetchOnWindowFocus: false, enabled: !!eventId && !!sponsorId })

  return { visitorsStatistics: data?.data?.[0] ?? [], ...rest }
}

export default useVisitorsStatistics
