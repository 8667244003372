import styled from '@emotion/styled'
import { faBookmark as faBookmarkRegular } from '@fortawesome/pro-regular-svg-icons'
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Attendee } from '@Hooks/Types'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import ProfileCardPreview from '@Reusable/ProfileCard/ProfileCardPreview'
import theme from '@Theme'
import { Button, Skeleton } from 'antd'
import { ProfileType } from 'src/Types'

interface AttendeeListItemProps {
  attendee: Attendee
}

const AttendeeListItem = ({ attendee }: AttendeeListItemProps) => {
  const { selectedEvent } = useSelectedEvent()
  const { usesOpenChat } = selectedEvent

  const SuggestMeetingButton = (
    <Button
      data-test={'attendee-card-meeting-button'}
      type={'primary'}
      block
      disabled
    >
      {usesOpenChat ? 'Chat / Suggest meeting' : 'Suggest meeting'}
    </Button>
  )

  const BookmarkButton = (
    <Button
      data-test={'bookmark-button'}
      disabled
      type={'ghost'}
      style={{ marginLeft: theme.spacing.horizontal.s }}
    >
      <BookmarkIcon
        icon={attendee.bookmarked ? faBookmarkSolid : faBookmarkRegular}
      />
    </Button>
  )

  return (
    <ProfileCardPreview
      dataTest={`profile-card-${attendee.id}`}
      profile={attendee as unknown as ProfileType}
    >
      <Wrapper>
        <Skeleton.Button shape={'round'} size={'small'}/>
        <Skeleton.Button shape={'round'} size={'small'}/>
        <Skeleton.Button shape={'round'} size={'small'}/>
        <Skeleton.Button shape={'round'} size={'small'}/>
      </Wrapper>
      <FooterContainer>
        {SuggestMeetingButton}
        {BookmarkButton}
      </FooterContainer>
    </ProfileCardPreview>
  )
}

export default AttendeeListItem

const Wrapper = styled('div')({
  marginTop: 12,
  display: 'flex',
  overflow: 'hidden',
  '> div': {
    marginRight: '3%'
  }

})
const FooterContainer = styled('div')({
  display: 'flex',
  marginTop: theme.spacing.vertical.xl
})

const BookmarkIcon = styled(FontAwesomeIcon)({
  color: theme.colors.secondaryText
})
