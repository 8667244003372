/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react'
import ReactECharts from 'echarts-for-react'

import { grid, legend, tooltip } from '../Reusable/Common'
import { SaveChartButton } from '../Reusable/SaveChartButton'

interface BarChartProps {
  xAxis: any,
  yAxis: any,
  series: any[],
  colors: string[],
  height?: string,
  width?: string,
  name: string
}

const HorizontalBarChart = ({ colors, height, width, name, xAxis, yAxis, series }: BarChartProps) => {
  const instance = useRef(null)
  const option = {
    backgroundColor: '#ffffff',
    tooltip,
    legend,
    color: colors,
    grid,
    xAxis,
    yAxis,
    series
  }

  return (
    <>
      <SaveChartButton instance={instance} name={name}/>
      <ReactECharts
        ref={instance}
        option={option}
        style={{ height: height || 450, marginTop: 12, width: width || '100%' }}
      />
    </>
  )
}

export default HorizontalBarChart
