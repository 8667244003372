import { useRef } from 'react'
import ReactECharts from 'echarts-for-react'

import { grid, legend, tooltip } from './Common'
import { SaveChartButton } from './SaveChartButton'
import { ChartProps, DataType } from './types'

interface DonutChartProps extends ChartProps {
  type: string,
  data: DataType[]
}

const PIE_SERIES_TOP = 0

const DonutChart = ({ data, colors, height, width, name, type, outerSeries = '' }: DonutChartProps) => {
  const totalReducer = (previousValue: number, currentValue:number) => previousValue + currentValue
  const total = (data || []).map((i) => i.value).reduce(totalReducer, 0)
  const instance = useRef(null)
  const series = [
    {
      top: PIE_SERIES_TOP,
      name,
      type: 'pie',
      color: colors,
      radius: ['50%', '70%'],
      avoidLabelOverlap: false,
      label: {
        normal: {
          show: false
        }
      },
      labelLine: {
        show: true
      },
      data: data.filter((i) => i.value)
    },
    {
      name,
      tooltip: {
        show: false
      },
      type: 'pie',
      color: '#fff',
      radius: ['0%', '1%'],
      avoidLabelOverlap: true,
      label: {
        show: true,
        position: 'inside',
        formatter: [
          '{a| Total}',
          `{b| ${total}}`,
          `{a| ${type}}`
        ].join('\n'),
        rich: {
          a: {
            fontSize: 14,
            lineHeight: 10,
            color: '#595959'
          },
          b: {
            fontSize: 30,
            height: 60,
            fontWeight: 'bold',
            textAlign: 'center'
          }
        },
        fontWeight: 'bolder'
      },
      labelLine: {
        show: true
      },
      data: [{ value: 100 }]
    }
  ]

  const formattedData = data.concat(outerSeries?.data ?? [])

  const formattedLegend = {
    ...legend,
    formatter: function(name: string) {
      const value = formattedData.filter((item) => item.name === name)?.[0]?.value ?? 0
      return `${(value * 100 / total).toFixed()}% ${name}`
    }
  }

  const option = {
    backgroundColor: '#ffffff',
    tooltip,
    legend: { ...formattedLegend, data: formattedData.filter((i) => (i.name && i.value)) },
    series: outerSeries?.data?.[0]?.value ? series.concat(({ ...outerSeries, top: PIE_SERIES_TOP })) : series,
    grid
  }

  return (
    <>
      <SaveChartButton instance={instance} name={name}/>
      <ReactECharts
        ref={instance}
        option={option}
        style={{ height: height || 450, marginTop: 12, width: width || '100%' }}
      />
    </>
  )
}

export default DonutChart
