import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { sortItemsByPosition } from '@Helpers/Util'
import useSponsorDownloads from '@Hooks/useSponsorDownloads'
import Card from '@Reusable/Card/Card'
import { useSponsorContext } from '@SponsorContext'
import { entitySauce } from '@State'
import { message } from 'antd'
import { prop, uniqBy } from 'ramda'
import { EventParamsTypes } from 'src/Types'

import DownloadsCreateNewModal from '../DownloadsCreateNewModal'
import DownloadsForm from '../DownloadsForm'
import { DownloadItemType, DownloadsFormType } from '../Types'

import {
  DownloadsAlert,
  DraggableDownloadItem,
  DroppableDownloadItem,
  FormButton
} from './Utils'

const reorder = (
  list: Iterable<string> | ArrayLike<string>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DownloadsTable = () => {
  const { downloads, isFetching: isLoadingDownloads } = useSponsorDownloads()
  const { sponsorState, setSponsorState } = useSponsorContext()
  const [downloadsState, setDownloadsState] = useState<DownloadItemType[]>(downloads)
  const [expandedItem, setExpandedItem] = useState<string | null>(null)
  const [createNewModalOpen, setCreateNewModalOpen] = useState<boolean>(false)
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const [toggleDownload] = entitySauce.usePost([
    'downloads',
    {
      eventId,
      sponsorId,
      toggle: 'toggle'
    }
  ], {
    onSuccess: (response: { data: DownloadItemType[] }) => {
      const [updatedDownload] = response.data

      message.success(`Download ${updatedDownload.enabled ? 'enabled' : 'disabled'}`)
    },
    onError: () => message.error('Couldn\'t toggle the download, please try again')
  })

  const [patchDownload, { isLoading: patchingDownloads }] = entitySauce.usePatch([
    'downloads',
    {
      eventId,
      sponsorId
    }
  ], {
    onSuccess: () => message.success('Download order updated'),
    onError: () => message.error('Couldn\'t update the order, please refresh the page and try again')
  })

  useEffect(
    function activateDownloadsTab() {
      setSponsorState({ ...sponsorState, downloadsTabDisabled: !downloads.length })
    },
    [downloads.length]
  )

  useEffect(function setInitialDownloads() {
    setDownloadsState(sortItemsByPosition(downloads) as DownloadItemType[])
  }, [downloads])

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.source.index === result.destination.index) {
      return
    }

    const newItems = reorder(
      downloadsState as never,
      result.source.index,
      result.destination.index
    )

    const orderedDownloads = Object.keys(newItems).map((key) => {
      return {
        ...newItems[parseInt(key)] as unknown as DownloadItemType,
        position: parseInt(key) + 1
      }
    })

    setDownloadsState(
      orderedDownloads
    )

    patchDownload({
      downloadId: result.draggableId,
      data: {
        download: {
          position: result.destination.index + 1
        }
      }
    })
  }

  const expandItem = (id: string) => {
    if (expandedItem === id) {
      setExpandedItem(null)
    } else {
      setExpandedItem(id)
    }
  }

  const onCancel = () => {
    setExpandedItem(null)
  }

  const onCreateModalOpen = () => {
    setCreateNewModalOpen(true)
  }

  const onCreateModalClose = () => {
    setCreateNewModalOpen(false)
  }

  function handleDownloadToggle(downloadId: string) {
    toggleDownload({ downloadId })
  }

  return (
    <Card
      title={'Downloads'}
      extra={(
        <FormButton
          data-test={'create-new-download-button'}
          type={'primary'}
          style={{ margin: 0 }}
          onClick={onCreateModalOpen}
        >
          {'Add new'}
        </FormButton>
      )}
      testId={'downloads'}
      loading={isLoadingDownloads || patchingDownloads}
    >
      <DownloadsCreateNewModal
        onClose={onCreateModalClose}
        isVisible={createNewModalOpen}
      />
      <DownloadsAlert/>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'droppable'}>
          {(provided) => (
            <DroppableDownloadItem
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {(downloadsState instanceof Array) &&
                (uniqBy(prop('id'), downloadsState)).map((item: DownloadItemType, index: number) => {
                  return (
                    <Draggable
                      data-test={`downloads-list-item-${item.id}`}
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={expandedItem === item.id}
                    >
                      {(provided, snapshot) => (
                        <DraggableDownloadItem
                          provided={provided}
                          snapshot={snapshot}
                          item={item}
                          expanded={expandedItem === item.id}
                          expandItem={expandItem}
                          toggleDownload={handleDownloadToggle}
                        >
                          <DownloadsForm
                            item={item}
                            onCancel={onCancel}
                            formType={DownloadsFormType.EDIT}
                          />
                        </DraggableDownloadItem>
                      )}
                    </Draggable>
                  )
                })}
              {provided.placeholder}
            </DroppableDownloadItem>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  )
}

export default DownloadsTable
