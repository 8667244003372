// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FileColors: any = {
  video: '#6b00a8',
  image: '#d18f00',
  zip: '#84BD5A',
  audio: '#b213d6',
  text: '#143c99',
  system: '#111',
  src: '#787878',
  vector: '#bf6a02',
  font: '#555',
  object: '#5FB9AD',
  sheet: '#D93831',
  doc: '#1a73e8'
}

export default FileColors
