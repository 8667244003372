import styled from '@emotion/styled'
import theme from '@Theme'
import { Select } from 'antd'

interface TableSizeChangerProps {
  size: number,
  setSize: (size: number) => void,
  defaultValue: number,
  sizeOptions: number[]
}

const TableSizeChanger = ({ size, setSize, defaultValue, sizeOptions }: TableSizeChangerProps) => {
  const handleChange = (value: number) => {
    setSize(value)
  }

  return (
    <Wrapper>
      {'Show'}
      <Select
        size={'small'}
        bordered={false}
        value={size}
        defaultValue={defaultValue}
        style={{ width: 120 }}
        onChange={handleChange}
      >
        {sizeOptions.map((option) => (
          <Select.Option key={option} value={option}>{option}</Select.Option>
        ))}
      </Select>
      {'products per page'}
    </Wrapper>
  )
}

export default TableSizeChanger

const Wrapper = styled('div')({
  marginTop: -44,
  marginLeft: 16,
  color: theme.colors.secondaryText,
  '.ant-select': {
    width: '55px !important',
    color: theme.colors.primary
  },
  '.ant-select-arrow': {
    color: theme.colors.primary
  }
})
