import './LinkButton.css'

import { CSSProperties, ReactNode } from 'react'
import { Spin } from 'antd'

interface LinkButtonProps {
  children: ReactNode,
  className?: string,
  style?: CSSProperties | undefined,
  loading?: boolean,
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

const LinkButton = ({ children, className, style, loading, onClick, ...rest }: LinkButtonProps) => {
  return (
    <div
      className={`br-link-button ${className}`}
      style={style}
      onClick={onClick}
      {...rest}
    >
      {loading && <Spin
        size={'small'}
        className={'br-link-button-loader'}
      />}
      {children}
    </div>
  )
}

export default LinkButton
