import React, { CSSProperties } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import styled from '@emotion/styled'
import { StyledFlex, StyledSubtitleText, StyledTitleText } from '@Reusable/Styled'
import { useSponsorContext } from '@SponsorContext'
import { Form, Input } from 'antd'
import { findIndex, prop, propEq, uniqBy } from 'ramda'
import { SponsorTabType } from 'src/Types'

import bars from '../../../../Images/SVG/bars.svg'

const reorder = (list: Iterable<string> | ArrayLike<string>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const SponsorTabCustomization = () => {
  const { sponsorState, setSponsorState, setSponsorTabsSortTouched } = useSponsorContext()
  const { boothTabs } = sponsorState

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      boothTabs as never,
      result.source.index,
      result.destination.index
    )

    const orderedTabs = Object.keys(newItems).map((key) => {
      return {
        ...newItems[parseInt(key)] as unknown as SponsorTabType,
        position: parseInt(key) + 1
      }
    })
    setSponsorState({
      ...sponsorState,
      boothTabs: orderedTabs
    })
    setSponsorTabsSortTouched(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: string) => {
    const { value } = e.target
    const boothTabsIndex = findIndex(propEq('id', item))(boothTabs as never)
    const newItems = [...boothTabs]

    newItems[boothTabsIndex].value = value
    newItems[boothTabsIndex].title = value
    setSponsorState({
      ...sponsorState,
      boothTabs: newItems
    })
  }

  if (!boothTabs?.length) return null

  return (
    <div data-test={'sponsor-booth-tabs-customization'}>
      <DragDropContext onDragEnd={onDragEnd}>
        <StyledTitleText margin={'32px 0 0'}>{'Tab Customization'}</StyledTitleText>
        <StyledSubtitleText margin={'8px 0 16px'}>{'Rename or reorder these tabs to suit your needs'}</StyledSubtitleText>
        <Droppable droppableId={'droppable'}>
          {(provided) => (
            <ListItem
              {...provided.droppableProps}
              ref={provided.innerRef}
              data-test={'sponsor-booth-tabs-customization-list-item'}
            >
              {(boothTabs instanceof Array) &&
                (uniqBy(prop('id'), boothTabs)).map((item: SponsorTabType, index: number) => {
                  if (!item?.key || item?.disabled) return null

                  return (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <DraggableItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                          draggableStyle={provided.draggableProps.style}
                        >
                          <FormItem
                            data-test={`sponsor-booth-${item.key}`}
                            hasFeedback
                            name={item.name}
                            style={{ marginBottom: 0 }}
                            rules={[
                              {
                                max: item.limit,
                                message: `${item.label} can't be longer than ${item.limit} characters`
                              }
                            ]}
                          >
                            <StyledFlex>
                              <ListItemLabel>
                                <img
                                  src={bars}
                                  alt={'drag handler'}
                                  style={{ marginRight: 12 }}
                                />
                                {item.label}
                                {' '}
                                {`(${item.value?.length} / ${item.limit})`}
                                {':'}
                              </ListItemLabel>
                              <Input
                                defaultValue={item.value}
                                onChange={(e) => handleInputChange(e, item.id)}
                                data-test={`sponsor-booth-${item.key}-input`}
                              />
                            </StyledFlex>
                          </FormItem>
                        </DraggableItem>
                      )}
                    </Draggable>
                  )
                })}
              {provided.placeholder}
            </ListItem>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default SponsorTabCustomization

const FormItem = styled(Form.Item)({
  '.ant-form-item-explain.ant-form-item-explain-error': {
    margin: '5px 0 0 172px'
  }
})

const ListItem = styled('div')({
  width: '100%',
  marginBottom: 24
})

const ListItemLabel = styled('div')({
  minWidth: 168
})

type DraggableItemProps = {
  isDragging: boolean,
  draggableStyle: CSSProperties | undefined
}

const DraggableItem = styled('div')<DraggableItemProps>({
}, ({ isDragging, draggableStyle }) => ({
  padding: '8px 0',
  backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
  ...draggableStyle
}))
