import { useReducer, useState } from 'react'

import { ProductsContext } from './ProductsContext'
import ProductsTable from './ProductsTable'
import { actionTypes, ProductActionType, ProductType } from './Types'

const reducer = (state: ProductType, action: ProductActionType): ProductType => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_VALUES:
      return {
        ...action.payload
      }
    case actionTypes.SET_TITLE:
      return {
        ...state,
        title: action.payload
      }
    case actionTypes.SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload
      }
    case actionTypes.SET_LINK:
      return {
        ...state,
        url: action.payload
      }
    case actionTypes.SET_IMAGE_URL:
      return {
        ...state,
        imageUrl: action.payload
      }
    case actionTypes.SET_FIELDS_TOUCHED:
      return {
        ...state,
        fieldsTouched: true
      }
    default:
      return { ...state }
  }
}

function Products(): JSX.Element {
  const [activeProductState, activeProductStateDispatch] = useReducer(reducer, {})
  const [newProductState, newProductStateDispatch] = useReducer(reducer, {})
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  return (
    <ProductsContext.Provider
      value={{
        activeProductState,
        activeProductStateDispatch,
        newProductState,
        newProductStateDispatch,
        tableLoading,
        setTableLoading
      }}
    >
      <ProductsTable/>
    </ProductsContext.Provider>
  )
}

Products.routeConfig = {
  path: '/products'
}

export default Products
