import { SponsorTabKeyType } from '../Types'

export const getTabLabel = (tab: SponsorTabKeyType) => {
  switch (tab) {
    case SponsorTabKeyType.DETAILS:
      return 'Details tab'
    case SponsorTabKeyType.REPS:
      return 'Profile tab'
    case SponsorTabKeyType.PRODUCTS:
      return 'Products'
    case SponsorTabKeyType.DOWNLOADS:
      return 'Downloads'
    default:
  }
}
