import { useState } from 'react'
import styled from '@emotion/styled'
import { useSponsorContext } from '@SponsorContext'
import { Tabs } from 'antd'
import { prop, sortBy } from 'ramda'

import { SponsorTabKeyType, SponsorTabType } from '../../../../../../Types'

import CompanyDetails from './CompanyDetails'
import { CompanyDetailsTabsNavContext } from './CompanyDetailsTabsNavContext'
import CompanyRepresentatives from './CompanyRepresentatives'
import SponsorProductsPreview from './SponsorProductsPreview'

const getTabComponent = (tab: SponsorTabKeyType) => {
  switch (tab) {
    case SponsorTabKeyType.DETAILS:
      return <CompanyDetails />
    case SponsorTabKeyType.REPS:
      return <CompanyRepresentatives />
    case SponsorTabKeyType.PRODUCTS:
      return <SponsorProductsPreview />
    default:
  }
}

const defaultBoothTabs = [
  {
    key: 'details',
    title: 'Company Details',
    position: 1
  },
  {
    key: 'representatives',
    title: 'Company Representatives',
    position: 2
  }
]

const { TabPane } = Tabs

const CompanyDetailsTabsNav = () => {
  const [attendeesLength, setAttendeesLength] = useState<number>(0)

  const { sponsorState } = useSponsorContext()
  const { boothTabs } = sponsorState

  const contextValue = {
    attendeesLength,
    setAttendeesLength
  }

  const orderedBoothTabs: SponsorTabType[] = sortBy(prop('position'))(boothTabs || defaultBoothTabs)

  return (
    <CompanyDetailsTabsNavContext.Provider value={contextValue}>
      <CompanyDetailsTabsNavContainer data-test={'preview-tabs-container'}>
        <Tabs defaultActiveKey={'representatives'}>
          {orderedBoothTabs.map((tab: SponsorTabType) => {
            if (!tab?.key || tab?.disabled) return null
            return (
              <TabPane
                key={tab.key}
                tab={`${tab.title} ${tab.key === 'representatives' ? `(${attendeesLength})` : ''}`}
              >
                {getTabComponent(tab.key)}
              </TabPane>
            )
          })}
        </Tabs>
      </CompanyDetailsTabsNavContainer>
    </CompanyDetailsTabsNavContext.Provider>
  )
}

export default CompanyDetailsTabsNav

const CompanyDetailsTabsNavContainer = styled('div')({
}, (props) => ({
  fontSize: 14,
  lineHeight: '22px',
  margin: '12px 0',
  '.ant-tabs-tab-active .ant-tabs-tab-btn': {
    color: props.theme.colors.title
  },
  '.ant-tabs-tab-btn': {
    color: props.theme.colors.lightGrayText
  },
  '.ant-tabs-tabpane': {
    marginTop: 12
  }
}))
