export enum ButtonTypes {
  Link = 'link',
  Text = 'text',
  Ghost = 'ghost',
  Primary = 'primary',
  Default = 'default',
  Dashed = 'dashed'
}

export enum ButtonTargets {
  Blank = '_blank',
  Self = '_self',
  Parent = '_parent',
  Top = '_top'
}

export enum ButtonSizes {
  Large = 'large',
  Middle = 'middle',
  Small = 'small'
}
