/* eslint-disable @typescript-eslint/no-explicit-any */
export interface EventParamsTypes {
  [key: string]: any
}

export type ProfileType = {
  id: number,
  userId: boolean,
  firstName: string,
  coverPhotoUrl: string,
  lastName: string,
  imageUrl: string,
  companyName: string,
  companyTitle: string,
  matchingAttendeeInterests: unknown[]
}

export enum SponsorTabKeyType {
  DETAILS = 'details',
  REPS = 'representatives',
  PRODUCTS = 'products',
  DOWNLOADS = 'downloads'
}

export type SponsorTabType = {
  key: SponsorTabKeyType,
  id: string,
  label: string,
  title: string,
  value: string,
  name: string,
  position: number,
  limit: number,
  disabled: boolean
}
