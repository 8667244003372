/* eslint-disable @typescript-eslint/no-explicit-any */
import { COMPANY_INDUSTRIES } from '@Constants/Industries'
import styled from '@emotion/styled'
import ImageUpload from '@Reusable/ImageUpload/ImageUpload'
import { StyledTitleText } from '@Reusable/Styled'
import { useSponsorContext } from '@SponsorContext'
import { Form, Input, Select } from 'antd'
import { EditorState } from 'draft-js'

import { UpdateSponsorAction, updateSponsorActionTypes } from '../../../types'

import ContentEditor from './FormItemsComponents/ContentEditor/ContentEditor'
import ActionButtons from './ActionButtons'
import SponsorTabCustomization from './SponsorTabCustomization'

interface IFormItemsProps {
  onSelectIndustryFilter: any,
  eventId: number,
  subtitleLength: number,
  ctaTextLength: number,
  logoUrl: string,
  coverImageUrl: string,
  isLarge: boolean
}

const FormItems: React.FunctionComponent<IFormItemsProps> = ({
  onSelectIndustryFilter,
  eventId,
  subtitleLength,
  ctaTextLength,
  logoUrl,
  coverImageUrl,
  isLarge
}) => {
  const {
    virtualBoothFieldsTouched,
    updateSponsorState,
    sponsorTabsSortTouched
  } = useSponsorContext()

  const onInputChange = (field: string, data: UpdateSponsorAction) => {
    updateSponsorState(field, data)
  }

  return (
    <>
      <StyledTitleText margin={'0 0 16px'}>{'Booth appearance'}</StyledTitleText>
      <Form.Item
        data-test={'partner-form-company-name'}
        className={'partner-form-company-name'}
        label={'Company name'}
        hasFeedback
        name={'name'}
        rules={[
          {
            required: true,
            message: 'Company name is required!'
          }
        ]}
      >
        <Input
          data-test={'company-name-input'}
          type={'text'}
          placeholder={'e.g. Brella'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange('name', { type: updateSponsorActionTypes.SET_NAME, payload: e.target.value })}
        />
      </Form.Item>

      <SubtitleFormItem
        data-test={'partner-form-subtitle'}
        label={`Subtitle (${subtitleLength} / 75)`}
        hasFeedback
        name={'subtitle'}
        rules={[
          {
            max: 75,
            message: 'Subtitle can\'t be longer than 75 characters'
          }
        ]}
      >
        <Input.TextArea
          data-test={'partner-form-subtitle-input'}
          placeholder={'Add a short description'}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onInputChange('subtitle', { type: updateSponsorActionTypes.SET_SUBTITLE, payload: e.target.value })}
        />
      </SubtitleFormItem>

      <Form.Item
        className={'partner-form-logo'}
        label={'Logo'}
        hasFeedback
        name={'logo'}
        required={true}
      >
        <ImageUpload
          onChange={(logoUrl: string) => onInputChange('logoUrl', { type: updateSponsorActionTypes.SET_LOGO_URL, payload: logoUrl })}
          preview={logoUrl}
          label={'Logo'}
          helperText = {[
            { id: 1, text: 'Recommended ratio 1:1 (200x200px)' },
            { id: 2, text: 'JPEG and PNG formats are preferred' }
          ]}
          required
        />
      </Form.Item>

      {isLarge && <Form.Item
        className={'partner-form-cover-image'}
        label={'Cover image'}
        hasFeedback
        name={'coverImage'}
        required={true}
      >
        <ImageUpload
          label={'Cover image'}
          cropperEnabled
          aspectRatio={3}
          width={'100%'}
          maxWidth={'500px'}
          onChange={(coverImageUrl: string) => onInputChange('coverImageUrl', { type: updateSponsorActionTypes.SET_COVER_IMAGE_URL, payload: coverImageUrl })}
          preview={coverImageUrl}
          helperText = {[
            { id: 1, text: 'Recommended ratio 3:1 (1200x400px)' },
            { id: 2, text: 'JPEG and PNG formats are preferred' }
          ]}
        />
      </Form.Item>}
      <Form.Item
        label={`CTA text (${ctaTextLength} / 30)`}
        data-test={'partner-form-cta'}
        hasFeedback
        name={'ctaText'}
        extra={'Customize Visit booth text.'}
        rules={[
          {
            max: 30,
            message: 'CTA text can\'t be longer than 30 characters'
          },
          {
            required: true,
            message: 'CTA text is required!'
          }
        ]}

      >
        <Input
          type={'text'}
          data-test={'partner-form-cta-input'}
          placeholder={'Visit booth'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange('ctaText', { type: updateSponsorActionTypes.SET_CTA_TEXT, payload: e.target.value })}
        />
      </Form.Item>
      <StyledTitleText margin={'24px 0 16px'}>{'Booth Details'}</StyledTitleText>
      <Form.Item
        data-test={'partner-form-industry'}
        className={'partner-form-industry'}
        label={'Industry'}
        hasFeedback
        name={'industry'}
      >

        <Select
          data-test={'partner-form-industry-select'}
          showSearch
          filterOption={onSelectIndustryFilter}
          placeholder={'Please select an industry'}
          onChange={(industry: number) => onInputChange('industry', { type: updateSponsorActionTypes.SET_INDUSTRY, payload: industry })}
        >
          {COMPANY_INDUSTRIES.map((industry, index) => {
            return (
              <Select.Option
                key={industry}
                value={index}
              >
                {industry}
              </Select.Option>
            )
          })}
        </Select>

      </Form.Item>

      <Form.Item
        data-test={'partner-form-website'}
        label={'Website'}
        hasFeedback
        name={'website'}
      >
        <Input
          data-test={'partner-form-website-input'}
          type={'text'}
          placeholder={'eg. https://brella.io'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange('website', { type: updateSponsorActionTypes.SET_WEBSITE, payload: e.target.value })}
        />

      </Form.Item>

      <Form.Item
        data-test={'partner-form-facebook'}
        label={'Facebook'}
        hasFeedback
        name={'facebook'}
      >
        <Input
          data-test={'partner-form-facebook-input'}
          type={'text'}
          placeholder={'eg. https://www.facebook.com/Brellanetwork'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange('facebook', { type: updateSponsorActionTypes.SET_FACEBOOK, payload: e.target.value })}
        />
      </Form.Item>

      <Form.Item
        data-test={'partner-form-linkedin'}
        label={'LinkedIn'}
        hasFeedback
        name={'linkedin'}
      >
        <Input
          data-test={'partner-form-linkedin-input'}
          type={'text'}
          placeholder={'eg. https://www.linkedin.com/company/mbrlla'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange('linkedin', { type: updateSponsorActionTypes.SET_LINKEDIN, payload: e.target.value })}
        />
      </Form.Item>

      <Form.Item
        data-test={'partner-form-twitter'}
        label={'Twitter handle'}
        hasFeedback
        name={'twitter'}
      >
        <Input
          data-test={'partner-form-twitter-input'}
          type={'text'}
          placeholder={'eg. @brellanetwork'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange('twitter', { type: updateSponsorActionTypes.SET_TWITTER, payload: e.target.value })}
        />
      </Form.Item>

      <Form.Item
        data-test={'partner-form-instagram'}
        label={'Instagram handle'}
        hasFeedback
        name={'instagram'}
      >
        <Input
          data-test={'partner-form-instagram-input'}
          type={'text'}
          placeholder={'eg. @brellanetwork'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange('instagram', { type: updateSponsorActionTypes.SET_INSTAGRAM, payload: e.target.value })}
        />
      </Form.Item>

      <Form.Item
        className={'partner-form-description'}
        label={'Description'}
        hasFeedback
        name={'content'}
        data-test={'content-editor'}
      >
        <ContentEditor
          eventId={eventId}
          onChange={(editorState: EditorState) => onInputChange('content', { type: updateSponsorActionTypes.SET_CONTENT, payload: editorState })}
        />
      </Form.Item>
      <SponsorTabCustomization />
      {
        (virtualBoothFieldsTouched || sponsorTabsSortTouched) &&
        <div>
          <ActionButtons padding={'24px 0'}/>
        </div>
      }
    </>
  )
}

export default FormItems

export const SubtitleFormItem = styled(Form.Item)({
  marginBottom: 10,
  '#subtitle-count': {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    position: 'absolute',
    top: 6
  },
  '.ant-form-item-children-icon': {
    marginTop: '-5px !important'
  }
})
