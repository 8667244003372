import { useEffect } from 'react'
import { Form, Input, Modal } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { LeadEditData } from './interfaces'

interface LeadEditFormProps {
  showEditModal: boolean,
  editLeadName: string,
  editLeadNotes: string,
  onEditCancel: () => void,
  onEditSubmit: (data: LeadEditData) => void
}

function LeadEditForm({ showEditModal, editLeadName, editLeadNotes, onEditCancel, onEditSubmit }: LeadEditFormProps): JSX.Element {
  const [form] = Form.useForm()

  useEffect(function openEditModal() {
    form.setFieldsValue({
      notes: editLeadNotes
    })
  }, [editLeadNotes])

  const handleSubmit = (formValues: Store) => {
    const patchData = {
      lead: {
        notes: formValues.notes
      }
    }

    onEditSubmit(patchData)
  }

  return (
    <Modal
      title={`Edit lead: ${editLeadName}`}
      visible={showEditModal}
      onOk={form.submit}
      onCancel={onEditCancel}
      wrapClassName={'edit-notes-modal'}
      destroyOnClose={true}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          label={'Notes'}
          name={'notes'}
          noStyle
        >
          <Input.TextArea rows={4} data-test={'edit-notes-field'} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default LeadEditForm
