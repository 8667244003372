/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, Dispatch, useContext } from 'react'

import { ProductActionType, ProductType } from '../Types'

export type ProductsContextType = {
  activeProductState: ProductType,
  activeProductStateDispatch: Dispatch<ProductActionType>,
  newProductState: ProductType,
  newProductStateDispatch: Dispatch<ProductActionType>,
  tableLoading: boolean,
  setTableLoading: (isLoading: boolean) => void
}

export const ProductsContext = createContext<ProductsContextType>({
  activeProductState: {},
  activeProductStateDispatch: () => {},
  newProductState: {},
  newProductStateDispatch: () => {},
  tableLoading: false,
  setTableLoading: () => {}
})

export const useProductsContext = ():ProductsContextType => useContext(ProductsContext)
