import React from 'react'
import useUser from '@Hooks/useUser'
import Intercom, { hide, show } from '@intercom/messenger-js-sdk'

function useSupportChat() {
  const { user } = useUser()

  React.useEffect(() => {
    if (user?.id) {
      Intercom({
        app_id: import.meta.env.VITE_APP_INTERCOM_ID,
        region: 'eu',
        user_id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        user_hash: user.intercomUserHash,
        hide_default_launcher: true,
        latestScreen: window.location.href
      })
    }
  }, [user])

  return { show, hide }
}

export default useSupportChat
