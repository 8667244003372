import { useState } from 'react'
import useSponsorStatistics from '@Hooks/useSponsorStatistics/useSponsorStatistics'

import RepresentativeModal from './RepresentativeModal'
import RepresentativeTable from './RepresentativeTable'

function Representatives(): JSX.Element {
  const [representativeModalVisible, setRepresentativeModalVisible] = useState(false)
  const toggleRepresentativeModal = () => {
    setRepresentativeModalVisible(!representativeModalVisible)
  }
  const { attendeeStats, isFetching, refetch: refetchSponsorStatistics } = useSponsorStatistics()

  const _refetchSponsorStatistics = () => {
    refetchSponsorStatistics()
    setRepresentativeModalVisible(false)
  }

  return (
    <>
      <RepresentativeTable
        attendeeStats={attendeeStats}
        loading={isFetching}
        refetchSponsorStatistics={_refetchSponsorStatistics}
      />

      <RepresentativeModal
        visible={representativeModalVisible}
        onCancel={toggleRepresentativeModal}
        sponsorAttendees={attendeeStats}
        refetchSponsorStatistics={_refetchSponsorStatistics}
      />
    </>

  )
}

Representatives.routeConfig = {
  path: '/representatives'
}

export default Representatives
