/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useState } from 'react'
import { CloudDownloadOutlined } from '@ant-design/icons'
import { Button, message, Spin } from 'antd'
import { ButtonType } from 'antd/lib/button'

import ExportData from './ExportData'

interface ExportCsvButtonProps {
  query: any,
  title?: string,
  buttonType?: ButtonType
}

const ExportCsvButtonGet = ({ query, title, buttonType }: ExportCsvButtonProps) => {
  const [exportStatus, setExportStatus] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  const _handleExportStatus = (status: string) => {
    setExportStatus(status)
  }
  const _handleFetching = (fetching: boolean) => {
    setIsFetching(fetching)
  }

  const _success = () => {
    message.success('Your CSV export will be delivered by email shortly.')
  }

  const _error = () => {
    message.error('We couldn\'t export that. Please try again.')
  }

  useEffect(() => {
    if (exportStatus === 'success') {
      _success()
      setIsClicked(false)
      setIsFetching(false)
      setExportStatus('')
    }
    if (exportStatus === 'error') {
      _error()
      setIsClicked(false)
      setIsFetching(false)
      setExportStatus('')
    }
  }, [exportStatus])

  return (
    <Button
      type={buttonType || 'default'}
      className={'export'}
      data-test={'export-data-button'}
      onClick={() => setIsClicked(true)}
      icon={isFetching ? <Spin size={'small'} style={{ marginRight: 10 }}/> : <CloudDownloadOutlined />}
    >
      {title || 'Export Data (.csv)'}
      {isClicked &&
        <ExportData
          query={query}
          handleExportStatus={_handleExportStatus}
          handleFetching={_handleFetching}
        />
      }

    </Button>
  )
}

export default memo(ExportCsvButtonGet)
