import { queryCache } from 'react-query'
import { useLocation } from 'react-router-dom'
import { entitySauce } from '@State'
import { message } from 'antd'

type useAdminInviteType = {
  storeToken: () => void,
  acceptInvite: () => void
}

const useAdminInvite = (): useAdminInviteType => {
  const searchUrlQuery = useLocation().search

  const [patch] = entitySauce.usePatch(['sponsorAdmins'], {
    onSuccess: () => {
      message.success('Invite accepted successfully')
      localStorage.removeItem('inviteToken')
      queryCache.invalidateQueries('sponsorBooth')
    }
  })

  // Store the token from the URL in the local storage.
  // This is needed because the user will be redirected to the login page.
  const storeToken = () => {
    const inviteToken = new URLSearchParams(searchUrlQuery)?.get('token') ?? ''

    if (inviteToken) {
      localStorage.setItem('inviteToken', inviteToken)
    }
  }

  // Accept the invite if the token is stored in the local storage.
  const acceptInvite = () => {
    const inviteToken = localStorage.getItem('inviteToken')

    if (inviteToken) {
      patch({
        token: inviteToken
      })
    }
  }

  return {
    storeToken,
    acceptInvite
  }
}

export default useAdminInvite
