import './ImageUpload.css'

import React, { useEffect, useState } from 'react'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import { getBase64Image } from '@Helpers/Util'
import ImageCropper from '@Reusable/ImageCropper'
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'

export const imageFormats = {
  basic: '.tif, .tiff, .bmp, .jpg, .jpeg, .gif, .png',
  extended: 'image/*'
}

const ImageUpload = (props) => {
  const [preview, setPreview] = useState('')
  const [previewHeight, setPreviewHeight] = useState(0)

  let _resizeTimeout = null // For resize debouncing
  let _imageCropper = null
  const _content = React.createRef()
  const _fileInput = React.createRef()

  useEffect(() => {
    window.addEventListener('resize', _onWindowResize)
    _onWindowResize()
  })

  useEffect(() => {
    setPreview(props.preview)
  }, [props.preview])

  const _onWindowResize = () => {
    // Debounce resize calls
    clearTimeout(_resizeTimeout)
    _resizeTimeout = setTimeout(_updatePreviewHeight, 200)
  }

  const _updatePreviewHeight = () => {
    if (_content.current) {
      const previewHeight = _content.current.clientWidth / props.aspectRatio - 4
      setPreviewHeight(previewHeight)
    }
  }

  const _handleImageUpload = (e) => {
    getBase64Image(e.target.files[0], (image) => {
      if (props.cropperEnabled) {
        _imageCropper.open(image)
      } else {
        _triggerChange(image)
      }
    })
  }

  const _triggerChange = (preview) => {
    if (props.onChange) {
      props.onChange(preview)
    }
  }

  const _removeImage = () => {
    _triggerChange(null)
    _fileInput.current.value = null
  }

  const _saveImageCropperRef = (ref) => {
    _imageCropper = ref
  }

  const _renderRemove = () => {
    if (!preview) {
      return null
    }

    return (
      <Popconfirm
        placement={'topRight'}
        title={'Are you sure you want to remove this image?'}
        okText={'Yes'}
        cancelText={'No'}
        onConfirm={_removeImage}
      >
        <CloseCircleFilled
          className={'upload-remove'}
        />
      </Popconfirm>
    )
  }

  const _renderContent = (label) => {
    const previewStyle = {
      height: previewHeight,
      backgroundImage: `url(${preview})`
    }

    return (
      <div
        ref={_content}
        className={'upload-content'}
        style={{ height: height || '128px' }}
      >
        {preview
          ? (
            <div
              key={'preview'}
              className={'upload-preview'}
              style={previewStyle}
            />
          )
          : (
            <div className={'upload-area'}>
              <PlusOutlined
                className={'upload-icon'}
              />
              {`Upload ${label}`}
            </div>
          )}
      </div>
    )
  }

  const {
    allowedFormats,
    id,
    cropperEnabled,
    aspectRatio,
    label,
    width,
    height,
    maxWidth
  } = props

  return (
    <>
      <div
        className={'upload-container'}
        style={{
          width: width || '128px',
          maxWidth: maxWidth || '100%',
          height: height || '128px'
        }}
      >
        <input
          name={id}
          type={'file'}
          id={id}
          accept={allowedFormats}
          style={{ display: 'none' }}
          onChange={_handleImageUpload}
          ref={_fileInput}
        />
        <label htmlFor={id}>
          {_renderContent(label)}
        </label>
        {cropperEnabled && (
          <ImageCropper
            ref={_saveImageCropperRef}
            aspectRatio={aspectRatio}
            circle={false}
            onAccept={_triggerChange}
          />
        )}
        {_renderRemove()}

      </div>
      {(props.helperText || []).map((item) => {
        return (
          <p key={item.id} className={'logo-helper-text'}>
            <CheckOutlined/>
            {item.text}
          </p>
        )
      })}
      {props.required && !props.preview &&
       <p className={'logo-helper-text-required'}>
         <CloseOutlined/>
         {`${label} is required`}
       </p>}
    </>
  )
}

ImageUpload.defaultProps = {
  allowedFormats: imageFormats.basic,
  cropperEnabled: false,
  aspectRatio: 16 / 9,
  label: 'Image'
}

ImageUpload.propTypes = {
  allowedFormats: PropTypes.string,
  aspectRatio: PropTypes.number,
  cropperEnabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  preview: PropTypes.string,
  helperText: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string
}

export default ImageUpload
