import { InboxOutlined } from '@ant-design/icons'
import { getBase64Image } from '@Helpers/Util'
import { Upload } from 'antd'
import { DraggerProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { F } from 'ramda'

const { Dragger } = Upload

interface DragUploadProps {
  fileUrl: string | undefined,
  setFile: (file: string) => void
}

// TODO fix Drag upload in separate PR, and mark as a generic component
const DragUpload = ({ fileUrl, setFile }: DragUploadProps) => {
  const props: DraggerProps = {
    name: 'file',
    accept: '.jpeg, .jpg, .gif, .png, .svg, .pdf',
    multiple: false,
    beforeUpload: F,
    maxCount: 1,
    defaultFileList: fileUrl ? [{ name: fileUrl, url: fileUrl }] as UploadFile<{ name: string, url: string }>[] : undefined,
    onChange(info) {
      getBase64Image(info.file as unknown as Blob, setFile as (arg0: string | ArrayBuffer | null) => void)
    }
  }

  return (
    <Dragger {...props}>
      <p className={'ant-upload-drag-icon'}>
        <InboxOutlined />
      </p>
      <p className={'ant-upload-text'}>{'Click or drag file to upload'}</p>
      <p className={'ant-upload-hint'}>
        {'Uploading a new file will replace the existing file'}
      </p>
    </Dragger>
  )
}

export default DragUpload
