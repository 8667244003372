import { useParams } from 'react-router-dom'
import { entitySauce } from '@State'

import { EventParamsTypes } from '../Types'

const useSponsorDownloads = () => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  const { data: { data: downloads }, isFetching } = entitySauce.useGet([
    'downloads',
    {
      eventId,
      sponsorId
    }
  ], { refetchOnWindowFocus: false, enabled: !!eventId && !!sponsorId })

  return {
    downloads,
    isFetching
  }
}

export default useSponsorDownloads
