import styled from '@emotion/styled'

interface DeletePopUpConfirmProps {
  bulk?: boolean
}

const DeletePopUpConfirm = ({ bulk }: DeletePopUpConfirmProps) => {
  return (
    <DeletePopUpConfirmContainer>
      <h3>
        {bulk
          ? 'You are about to delete one or more Products'
          : 'You are about to delete a product'}
      </h3>
      <p>
        {'Deleting a product will remove all its data and this action cannot be undone. Confirm to delete this product.'}
      </p>
    </DeletePopUpConfirmContainer>
  )
}

export default DeletePopUpConfirm

const DeletePopUpConfirmContainer = styled('div')({
  maxWidth: 400,
  margin: '4px 16px',
  h3: {
    fontWeight: 600,
    fontSize: 16,
    color: '#262626'
  },
  p: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.7,
    color: '#595959'
  }
})
