import React from 'react'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import styled from '@emotion/styled'
import { Spin } from 'antd'

export enum SpinnerSize {
  DEFAULT= 'default',
  SMALL= 'small',
  LARGE= 'large'
}

interface SpinnerProps {
  alignment?: string,
  height?: number,
  size?: number,
  text?: string,
  style?: React.CSSProperties
}

const Spinner = ({
  alignment = 'center', height = 175, size = 24, text = '', ...props
}: SpinnerProps) => (
  <SpinnerContainer alignment={alignment} height={height} {...props}>
    <Spin
      tip={text}
      style={{ color: '#000' }}
      indicator={(
        <LoadingOutlined
          style={{ fontSize: size, color: '#000' }}
        />
      )}
    />
  </SpinnerContainer>
)

const SpinnerContainer = styled.div<SpinnerProps>`
  justify-content: ${(props) => props.alignment};
  min-height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
  width: 100%;
`
export default Spinner
