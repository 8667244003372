import DownloadsTable from './DownloadsTable'

function Downloads(): JSX.Element {
  return (
    <DownloadsTable />
  )
}

Downloads.routeConfig = {
  path: '/downloads'
}

export default Downloads
