import { Fragment } from 'react'
import styled from '@emotion/styled'
import { slugify } from '@Lib/Util'
import { StyledFlex } from '@Reusable/Styled'
import { Divider } from 'antd'

type DataType = {
  key: number,
  label: string,
  value: number,
  nested?: number
}

interface ChartInfoProps {
  data: DataType[],
  width?: string,
  testId: string
}

const ChartInfo = ({ data, width, testId }: ChartInfoProps) => {
  return (
    <ChartInfoContainer width={width} data-test={`${testId}-info`}>
      {data.map((item: DataType, index: number) => {
        return (
          <Fragment key={item.key}>
            <StyledFlex justifyContent={'space-between'}>
              <div className={'label'}>
                {item.nested && <StyledArrow nested={item.nested}>{'↳ '}</StyledArrow>}
                {item.label}
              </div>
              <div className={'value'} data-test={`${slugify(item.label)}-value`}>
                {item.value}
              </div>
            </StyledFlex>
            {index !== data.length - 1 &&
                <Divider style={{ margin: '16px 0' }} />
            }
          </Fragment>
        )
      })}
    </ChartInfoContainer>
  )
}

export default ChartInfo

type ChartInfoContainerProps = {
  width?: string,
  margin?: string
}

const ChartInfoContainer = styled('div')<ChartInfoContainerProps>({
}, (props) => ({
  width: props.width || '100%',
  margin: props.margin || '24px 24px 0 0',
  fontSize: 16,
  color: props.theme.colors.sponsorBlack,
  '.label': {
    fontWeight: 400
  },
  '.value': {
    fontWeight: 600
  }
}))

type StyledArrowType = {
  nested?: number
}
const StyledArrow = styled('span')<StyledArrowType>({
}, (props) => ({
  paddingLeft: props.nested ? (props.nested - 1) * 20 : 0,
  fontFamily: 'IBM Plex Sans'
}))
