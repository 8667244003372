import { CSSProperties } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon'
import { DownOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import theme from '@Theme'
import { Alert, Button, Switch } from 'antd'

import { styleDefObj } from '../../../../Common/Styles/ReactFileIconCustomStyles'
import { ReactComponent as DNDIcon } from '../../../../Images/SVG/dnd.svg'
import { DownloadItemType } from '../Types'

interface DragHandleProps {
  expanded: boolean
}

export const DragHandle = ({ expanded }: DragHandleProps) => (
  <DNDIcon
    style={{
      cursor: expanded ? 'not-allowed' : 'grab'
    }}
  />
)

export const DownloadsAlert = () => (
  <StyledAlert
    message={'Attendees who visit your Virtual Booth can download files added below. You can drag to rearrange the order of the files.'}
    closable
  />
)

const getFileExtension = (filename: string) => {
  const fileNameWithoutDomain = filename.replace(/^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/gi, '')

  if (!fileNameWithoutDomain.includes('.')) {
    return
  }

  const [extension] = fileNameWithoutDomain.split('.').pop()?.toLocaleLowerCase().split('?') as DefaultExtensionType[]

  return extension
}

interface IconNameColumnProps {
  url: string
}

export const IconColumn = ({ url }: IconNameColumnProps) => {
  const extension = getFileExtension(url)

  const fileIconProps = extension
    ? {
      ...defaultStyles[extension],
      ...styleDefObj[extension]
    }
    : {}

  return (
    <FileIcon
      extension={extension}
      {...fileIconProps}
    />

  )
}

interface DraggableDownloadItemProps {
  provided: DraggableProvided,
  snapshot: DraggableStateSnapshot,
  item: DownloadItemType,
  expanded: boolean,
  expandItem: (itemId: string) => void,
  children: JSX.Element,
  toggleDownload: (downloadId: string) => void
}

export const DraggableDownloadItem = ({
  provided,
  snapshot,
  item,
  expanded,
  expandItem,
  children,
  toggleDownload
}: DraggableDownloadItemProps) => (
  <>
    <DraggableItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      isDragging={snapshot.isDragging}
      draggableStyle={provided.draggableProps.style}
    >
      <DraggableRow>
        <DragHandleWrapper {...provided.dragHandleProps}>
          <DragHandle expanded={expanded} />
        </DragHandleWrapper>
        <Switch
          data-test={'download-enable-switch'}
          onClick={() => toggleDownload(item.id)}
          checked={item.enabled}
          size={'small'}
        />

        <ClickToExpandArea onClick={() => expandItem(item.id)} data-test={`download-item-${item.id}-click-area`}>
          <IconColumn url={item.attachmentType === 'url' ? item.attachmentUrl : item.fileUrl}/>
          <TitleColumn>{item.title}</TitleColumn>
          <ExpandArrow expanded={expanded}/>
        </ClickToExpandArea>

      </DraggableRow>
    </DraggableItem>
    <ExpandedArea expanded={expanded} data-test={`download-item-${item.id}-expand-area`}>
      {expanded &&
      <DownloadInfo>
        {children}
      </DownloadInfo>}
    </ExpandedArea>
  </>
)

export const DroppableDownloadItem = styled('div')({
  width: '100%',
  marginBottom: 24
})

export const TitleColumn = styled('div')({
  fontWeight: 600,
  fontSize: 16
})

export const FormButton = styled(Button)({
  marginRight: 12,
  borderRadius: '2px !important'
})

export const CardTitle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 24
})

const ClickToExpandArea = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  cursor: 'pointer',
  gap: 24
})

type DraggableItemProps = {
  isDragging: boolean,
  draggableStyle: CSSProperties | undefined
}

const DraggableItem = styled('div')<DraggableItemProps>(
  ({ isDragging, draggableStyle }) => ({
    padding: '16px 0',
    boxShadow: ' inset 0px -1px 0px #E8E8E8',
    backgroundColor: isDragging ? '#E8E8E8' : 'transparent',
    ...draggableStyle
  })
)

const DraggableRow = styled('li')({
  display: 'flex',
  alignItems: 'center',
  '>*': {
    margin: '0 12px'
  },
  svg: {
    width: 32
  }
})

const DragHandleWrapper = styled('div')({
  padding: 6,
  ':hover': {
    path: {
      fill: theme.colors.sponsorBlack,
      transition: 'fill 0.2s ease-out'
    }
  }
})

interface ExpandedAreaProps {
  expanded: boolean
}

const ExpandedArea = styled('div')<ExpandedAreaProps>(
  ({ expanded }) => ({
    maxHeight: expanded ? 550 : 0,
    overflow: 'hidden',
    transition: 'max-height 0.2s ease-out',
    background: '#F9FAFB'
  })
)

const DownloadInfo = styled('div')({
  height: '100%',
  padding: 16
})

interface ExpandArrowProps {
  expanded: boolean
}

const ExpandArrow = styled(DownOutlined)<ExpandArrowProps>(
  ({ expanded }) => ({
    transition: 'all 0.5s ease',
    transform: `rotate(${!expanded ? 0 : '0.5turn'})`,
    padding: '12px 16px',
    position: 'absolute',
    right: 16
  })
)

const StyledAlert = styled(Alert)({
  marginBottom: 32
})
